import styled from "styled-components";

export const Buttons = styled.button<{ isHover?: boolean }>`
  width: 30px;
  height: 30px;
  border-radius: 5px;
  background: transparent;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${({ isHover }) =>
    isHover ? "rgba(255, 255, 255, 0.1)" : "transparent"};

  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }

  & > img {
    width: 24px;
    height: 24px;
  }
`;

export const DropdownKindFile = styled.div`
  width: 274px;
  height: auto;
  position: absolute;
  top: 37px;
  left: 30px;

  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 5px;
  background: var(--white, #fff);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
`;

export const ButtonFile = styled.div`
  width: 100%;
  height: 44px;
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  gap: 8px;
  padding: 10px 16px;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background: #f5f5f5;
    border-radius: 5px;
  }

  span {
    color: var(--preto-preto-75, #676b70);
    /* Dropdown, input e sidebar */
    font-family: Ubuntu;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 16.8px */
  }
`;

export const SelectBox = styled.div`
  width: 100%;
  
  label {
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    color: ${({ theme }) => theme.colors.black100};
  }
`


export const Item = styled.option<{ active: boolean }>`
  display: flex;
  justify-content: space-between;

  padding: 11px 16px !important;
  height: 44.5px !important;

  font-family: "Ubuntu";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;

  color: ${({ theme }) => theme.colors.black100};
  cursor: pointer;

  background-color: ${({ theme, active }) =>
    active ? theme.colors.black5 : theme.colors.white100};

  :hover {
    background-color: ${({ theme }) => theme.colors.black5};
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  align-self: stretch;
  padding-bottom: 10px
`

export const LeftSubHeader = styled.h5`
  display: flex;
  height: 30px;
  flex-direction: column;
  justify-content: center;
  flex: 1 0 0;
  font-family: Ubuntu;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
`

export const RightSubHeader = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`

export const ButtonBox = styled.div`
  display: flex;
  padding-top: 10px;
`

export const AlertSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

export const AlertMessage = styled.div`
  display: flex;
  margin: 8px 0px 0px 0px;
  padding: 8px 0px 8px 0px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-radius: 5px;
  background: #FFE0B2;
  

  p {
    font-family: Ubuntu;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    padding: 0px 8px;
  }
`

export const KnowMore = styled.button`
  display: flex;
  height: 30px;
  padding: 9px 16px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 5px;
  background: #FFF;
  color: #7547A3;
  text-align: center;
  font-family: Ubuntu;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; 
`

export const ShowMoreInformationsSection = styled.div`
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 5px;
  border: 1px solid #EBEBEC;
  background: #FFF;

  p {
    color:#343A40;
    font-family: Ubuntu;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
`