import React, { createContext, useCallback, useEffect, useState } from "react";

import { Dialog, DialogActions } from "@mui/material";
import { CancelButton, ContainerButton, SaveButton } from "./styles";
import { IconCloseWrapper } from "../../styles";
import { GrClose } from "react-icons/gr";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import { ApprovalProjectRequest } from "../../types/ApprovalProjectRequest";
import { v4 as uuidv4 } from "uuid";
import api from "../../../../../services/api";
import { ApprovalFlowRequest } from "../../types/ApprovalFlowRequest";
import { ApprovalFlow } from "../../types/ApprovalFlow";
import ConfirmDialog from "../ConfirmDialog";
import { toast } from "react-toastify";
import { PagesProps } from "../../../../../dtos/PagesProps";
import GeneratePdfDocument from "../GeneratePdfDocument";
import { PuffLoader } from "react-spinners";

const initialState: ApprovalProjectRequest = {
  id: "",
  name: "Novo projeto",
  status: "PENDING",
  current_step_id: "",
  planned_approval_date: ""
};

type ContextProps = {
  project: ApprovalProjectRequest;
  setProject: React.Dispatch<React.SetStateAction<ApprovalProjectRequest>>;
  uploadedFile: File;
  setUploadedFile: React.Dispatch<React.SetStateAction<File>>;
  selectedTemplateId: string;
  setSelectedTemplateId: React.Dispatch<React.SetStateAction<string>>;
  pagesRender: PagesProps[] | undefined;
  setPagesRender: React.Dispatch<
    React.SetStateAction<PagesProps[] | undefined>
  >;
  documentName: string;
  setDocumentName: React.Dispatch<React.SetStateAction<string>>;
  goToNextStep: () => void;
};

export const CreateProjectContext = createContext<ContextProps | null>(null);

type Props = {
  open: boolean;
  onClose: (refresh?: boolean) => void;
  resend?: boolean;
  editing_project_id?: string;
};

const CreateNewProject: React.FC<Props> = ({
  open,
  onClose,
  resend,
  editing_project_id
}: Props) => {
  const [activeStep, setActiveStep] = useState(1);
  const [project, setProject] = useState<ApprovalProjectRequest>(initialState);
  const [uploadedFile, setUploadedFile] = useState<File>();
  const [flows, setFlows] = useState<ApprovalFlowRequest[]>([]);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [generating, setGenerating] = useState(false);
  const [documentName, setDocumentName] = useState("");
  const [pagesRender, setPagesRender] = useState<PagesProps[]>();
  const [selectedTemplateId, setSelectedTemplateId] = useState<string>("");
  const [activeTab, setActiveTab] = useState(0);

  const onSubmit = async () => {
    try {
      setLoading(true);
      let formData = new FormData();
      formData.append("image", uploadedFile);

      const fileResponse = await api.post(
        "approval-projects/upload-document",
        formData
      );
      const project_id = uuidv4();
      project.approval_flow.steps.forEach(step => {
        step.requirements = step.requirements.filter(
          s => s.status === "PENDING"
        );
        step.approvers = step.approvers.filter(s => s.status === "PENDING");
      });
      const request = {
        ...project,
        id: project_id,
        current_step_id: project.approval_flow.steps[0].id,
        approval_flow: {
          ...project.approval_flow,
          document_file_name: fileResponse.data.filename,
          is_template: false,
          approval_project_id: undefined
        }
      } as ApprovalProjectRequest;
      await api.post("approval-projects", request);
      toast.success("Projeto de aprovação criado com sucesso!");
      onClose(true);
    } catch (error) {
      toast.error(
        "Ocorreu um erro ao criar o projeto. Contate o suporte técnico."
      );
    }
  };

  const resendDocument = async () => {
    try {
      if (!uploadedFile) return toast.warn("Arquivo não anexado");
      setLoading(true);
      let formData = new FormData();
      formData.append("image", uploadedFile);

      const fileResponse = await api.post(
        "approval-projects/upload-document",
        formData
      );
      const request = {
        document_file_name: fileResponse.data.filename
      };

      await api.put(
        `approval-projects/${editing_project_id}/resend-document`,
        request
      );
      toast.success("Documento reenviado com sucesso!");
      onClose(true);
    } catch (error) {
      toast.error(
        "Ocorreu um erro ao reenviar o documento. Contate o suporte técnico."
      );
    }
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  const onNext = () => {
    if (activeStep === 1) {
      if (activeTab === 1) {
        if (!selectedTemplateId) return toast.warn("Selecione um template");
        if (resend) {
          generatePdfFile();
          return;
        }
        if (!project.approval_flow)
          return toast.warn("Selecione um fluxo de aprovação");
        setShowConfirmDialog(true);
        return;
      } else if (activeTab === 0) {
        if (resend) {
          if (!uploadedFile) return toast.warn("Selecione um arquivo");
          resendDocument();

          return;
        } else {
          if (!uploadedFile) return toast.warn("Selecione um arquivo");
          if (!project.approval_flow)
            return toast.warn("Selecione um fluxo de aprovação");
          setShowConfirmDialog(true);
          return;
        }
      }
    }
    if (activeStep === 2) {
      if (project.name === "") {
        return toast.warn("Digite um nome para o projeto");
      }
      // all flow steps must have at least one requirement and one approver
      const isValid = project.approval_flow.steps.every(step => {
        return (
          step.requirements.filter(r => r.status === "PENDING").length > 0 &&
          step.approvers.filter(a => a.status === "PENDING").length > 0
        );
      });
      if (!isValid) {
        return toast.warn(
          "Todos os passos do fluxo devem ter pelo menos um requisito e um aprovador"
        );
      }

      const isDateSelected = project.planned_approval_date !== "";
      if (!isDateSelected) {
        return toast.warn("Selecione uma data de aprovação planejada");
      }

      onSubmit();
    } else {
      handleStepChange(activeStep + 1);
    }
  };

  const onBack = () => {
    if (activeStep === 1) {
      onClose();
    } else {
      handleStepChange(activeStep - 1);
    }
  };

  const goToNextStep = () => {
    //  handleStepChange(activeStep + 1);
    if (resend) {
      return;
    }

    setSelectedTemplateId("");
    handleStepChange(activeStep + 1);
    setActiveTab(0);
    setLoading(false);
    setProject({
      ...project,
      name: documentName
    });
  };

  const onCloseConfirmDialog = (next: boolean) => {
    if (next) {
      if (selectedTemplateId) {
        generatePdfFile();
      } else {
        handleStepChange(activeStep + 1);
      }
    }
    setShowConfirmDialog(false);
  };

  const fetchFlows = useCallback(async () => {
    const response = await api.get(`/approval-flows?pagination=${0}`);
    const { data } = response;
    const mapped = data.map(f => mapToRequest(f));
    setFlows(mapped);
  }, []);

  useEffect(() => {
    fetchFlows();
  }, [fetchFlows]);

  useEffect(() => {
    if (resend && uploadedFile) {
      resendDocument();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resend, uploadedFile]);

  const mapToRequest = (flow: ApprovalFlow): ApprovalFlowRequest => {
    const newFlowId = uuidv4();
    const mappedMembers = flow.members.map(member => {
      const { first_name, last_name, created_at, updated_at, email, ...rest } =
        member;
      return { ...rest, id: uuidv4(), approval_flow_id: newFlowId };
    });

    const mappedSteps = flow.steps.map(step => {
      const { created_at, updated_at, requirements, approvers, ...rest } = step;
      const newStepId = uuidv4();
      const mappedRequirements = requirements.map(requirement => {
        const { created_at, updated_at, ...rest } = requirement;
        return {
          ...rest,
          id: uuidv4(),
          step_id: newStepId,
          approval_flow_id: newFlowId
        };
      });
      const mappedApprovers = approvers.map(approver => {
        const { created_at, updated_at, ...rest } = approver;
        return {
          ...rest,
          id: uuidv4(),
          step_id: newStepId,
          approval_flow_id: newFlowId
        };
      });
      return {
        ...rest,
        requirements: mappedRequirements,
        approvers: mappedApprovers,
        id: newStepId,
        approval_flow_id: newFlowId,
        status: Number(step.order) === 1 ? "STARTED" : "PENDING"
      };
    });

    return {
      id: newFlowId,
      name: flow.name,
      steps: mappedSteps,
      members: mappedMembers,
      is_template: false,
      status: flow.status
    };
  };

  const generatePdfFile = async () => {
    try {
      setLoading(true);
      toast.info("Gerando arquivo PDF, aguarde um momento...", {
        autoClose: 1000
      });

      const responseTemplateInfo = await api.get(
        `user-templates/template/${selectedTemplateId}`
      );
      setPagesRender(responseTemplateInfo.data.response.arrayOfPages);

      setGenerating(true);
    } catch (err) {
      console.error(err, "ERROR when get template!!");
      toast.error("Algo deu errado, tente novamente mais tarde.");
    }
  };

  return (
    <Dialog onClose={onClose} open={open} maxWidth={"md"}>
      <IconCloseWrapper
        onClick={() => {
          if (!loading) onClose();
        }}
      >
        <GrClose />
      </IconCloseWrapper>

      <CreateProjectContext.Provider
        value={{
          project,
          setProject,
          uploadedFile,
          setUploadedFile,
          selectedTemplateId,
          setSelectedTemplateId,
          pagesRender,
          setPagesRender,
          documentName,
          goToNextStep,
          setDocumentName
        }}
      >
        <GeneratePdfDocument
          documentNameTemplate={documentName}
          downloading={generating}
          pagesToBeRendered={pagesRender}
          setDownloading={setGenerating}
        />
        <>
          {activeStep === 1 && (
            <StepOne
              flows={flows}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              resend={resend}
            />
          )}
          {activeStep === 2 && <StepTwo />}
          <ConfirmDialog
            onClose={onCloseConfirmDialog}
            open={showConfirmDialog}
          />
        </>
      </CreateProjectContext.Provider>

      <DialogActions>
        <ContainerButton>
          <CancelButton onClick={onBack} disabled={loading}>
            {" "}
            {activeStep === 2 ? "Voltar" : "Cancelar"}
          </CancelButton>
          <SaveButton onClick={onNext} disabled={loading}>
            {loading && <PuffLoader color="#FFF" size="20px" />}
            {!loading && (
              <span>
                {" "}
                {resend ? "Enviar" : activeStep === 2 ? "Enviar" : "Próximo"}
              </span>
            )}
          </SaveButton>
        </ContainerButton>
      </DialogActions>
    </Dialog>
  );
};

export default CreateNewProject;
