import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useAuth } from "../../../../../../hooks/auth";
import { useMainHook } from "../../../../../../hooks/main";
import { useHeaderEditor } from "../../../../hooks/headerEditor";
import { useSelection } from "../../../../hooks/selection";
import { useSharedDocument } from "../../../../hooks/sharedDocument";
import { ButtonShare } from "../../styles";
import { AlertMessage, AlertSection, ButtonBox, Buttons, DropdownKindFile, Header, KnowMore, LeftSubHeader, RightSubHeader, SelectBox, ShowMoreInformationsSection } from "./styles";
import Tooltip from "../../../Tooltip";
import signIcon from "../../assets/sign-icon.svg";
import { useSign } from "../../../../../../hooks/sign";
import { useWorkspaceEditor } from "../../../../hooks/workspaceEditor";
import SharedIcon from "../../assets/toShared-icon.svg";
import WordICon from "../../assets/word-icon.svg";
import PdfICon from "../../assets/pdf-icon.svg";
import PptxIcon from "../../assets/pptx-icon.svg";
import CloseIcon from '@mui/icons-material/Close';
import ButtonMaxWidth from "../../../../../../shared/components/ButtonMaxWidth";
import SelectWithIconsComponent from "../../../SelectWithIcons";

// import { Container } from './styles';

const OptionsDownloadFile = [
  {
    label: 'PDF',
    value: 'pdf',
    icon: PdfICon,
  },
  {
    label: 'Word',
    value: 'word',
    icon: WordICon,
  },
  {
    label: 'Power Point',
    value: 'pptx',
    icon: PptxIcon
  }
];

interface RightButtonsProps {
  handleMouseEnterSaveTemplate: () => void;
  saveIcon: string;
  downloadIcon: string;
  isOwnerDocument: boolean;
  templateid: string;
}

const RightButtons: React.FC<RightButtonsProps> = ({
  handleMouseEnterSaveTemplate,
  saveIcon,
  downloadIcon,
  isOwnerDocument,
  templateid,
}) => {
  const { data, permissionType } = useAuth();
  const { containerRef } = useWorkspaceEditor();
  const [typeUserAccount, setTypeUserAccount] = useState<
    "trial" | "premium" | "loading"
  >("loading");
  const [typeDocument, setTypeDocument] = useState<'pdf' | 'word' | 'pptx'>('pdf');
  const [showMoreInformations, setShowMoreInformations] = useState<boolean>(false);
  const MenuFormatsRef = useRef<HTMLButtonElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const selectRef = useRef<HTMLDivElement>(null);
  const [isFormatsFileVisible, setIsFormatsFileVisible] = useState(false);
  const { generatePDF, generateNewPDF } = useHeaderEditor();
  const { setSelectedObject, setSelectedObjects, currentMultipleSelection } =
    useSelection();
  const { setIsIntentBuyModal, isTeamTemplate } =
    useMainHook();

  const { setIsSignEditor } = useSign();

  const { handleOpenModalShared } = useSharedDocument();

  const onCloseColorsMenu = useCallback(() => {
    setIsFormatsFileVisible(false);
    setTypeDocument('pdf');
  }, [setIsFormatsFileVisible, setTypeDocument]);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        isFormatsFileVisible &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node) &&
        !MenuFormatsRef.current?.contains(event.target as Node) &&
        !selectRef.current?.contains(event.target as Node) // Verifica se clicou fora do select
      ) {
        onCloseColorsMenu();
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isFormatsFileVisible, onCloseColorsMenu]);

  // const handleWordDownload = useCallback(() => {
  //   setIsIntentBuyModal(true);
  // }, [setIsIntentBuyModal]);

  const handleDownloadNewPDF = useCallback(() => {
    generateNewPDF("pdf", true);
    setIsFormatsFileVisible(false);
  }, [generateNewPDF, setIsFormatsFileVisible]);

  const handleDownloadNewWord = useCallback(() => {
    generateNewPDF("word", true);
    setIsFormatsFileVisible(false);
  }, [generateNewPDF, setIsFormatsFileVisible]);

  const handleDownloadPPTX = useCallback(() => {
    generateNewPDF("pptx", true);
    setIsFormatsFileVisible(false);
  }, [generateNewPDF, setIsFormatsFileVisible]);

  // const handleDownloadPDF = useCallback(() => {
  //   generatePDF("pdf", true);
  //   setIsFormatsFileVisible(false);
  // }, [generatePDF, setIsFormatsFileVisible]);

  // const handleDownloadWord = useCallback(() => {
  //   generatePDF("word", true);
  // }, [generatePDF, handleWordDownload, typeUserAccount]);

  // const isBitsAcademyEmail = useMemo(() => {
  //   return !!data?.user?.email.match("@bitsacademy.com.br");
  // }, [data]);

  useEffect(() => {
    if (
      data.payment_method?.user_type === "user_free" &&
      !data.payment_method.subscription_id &&
      data.payment_method.cancellation_date &&
      data.payment_method.is_active
    ) {
      setTypeUserAccount("trial");
    } else {
      setTypeUserAccount("premium");
    }
  }, [data]);

  return (
    <>
      <Tooltip
        title="Assinatura"
        description="Assinatura digital de documentos"
        placement="bottom-end"
      >
        <Buttons
          style={
            permissionType === "editor"
              ? { opacity: 0.2, cursor: "not-allowed" }
              : {}
          }
          onClick={() => {
            setIsSignEditor((prev) => !prev);
            containerRef.current.scrollTop = 0;
          }}
        >
          <img
            draggable={false}
            className="download"
            src={signIcon}
            alt="Ícone de download"
          />
        </Buttons>
      </Tooltip>

      <Tooltip
        title="Baixar"
        description="Exporte o seu projeto em diferentes formatos."
        placement="bottom-end"
      >
        <Buttons
          draggable={false}
          ref={MenuFormatsRef}
          style={
            permissionType === "editor"
              ? {
                opacity: 0.2,
                cursor: "not-allowed",
              }
              : {}
          }
          onClick={() => {
            if (permissionType === "editor") return;
            setSelectedObject(null);
            setSelectedObjects([]);
            currentMultipleSelection.current?.setNodes([]);
            setIsFormatsFileVisible((prev) => !prev);
          }}
          isHover={isFormatsFileVisible}
        >
          <img
            draggable={false}
            className="download"
            src={downloadIcon}
            alt="Ícone de download"
          />
        </Buttons>
      </Tooltip>

      {isFormatsFileVisible && (
        <DropdownKindFile ref={dropdownRef}>
          <Header>
            <LeftSubHeader>
              Baixar
            </LeftSubHeader>
            <RightSubHeader onClick={onCloseColorsMenu}>
              <CloseIcon />
            </RightSubHeader>
          </Header>
          <SelectBox ref={selectRef}>
            <SelectWithIconsComponent
              options={OptionsDownloadFile}
              value={typeDocument}
              onChange={(e) => setTypeDocument(e as 'pdf' | 'word' | 'pptx')}
            />
          </SelectBox>
          {typeDocument === 'word' && (
            <AlertSection>
              <AlertMessage>
                <p>Algumas fontes podem não ser exibidas corretamente nesse formato.</p>
              </AlertMessage>
              {showMoreInformations && (
                <ShowMoreInformationsSection>
                  <p>Para baixar em Word, evite as fontes: Montserrat, Faustina, Roboto Serif, Lora, Bitter, Open Sans, Raleway, Nunito sans, Archivo.</p>
                </ShowMoreInformationsSection>
              )}
              <KnowMore onClick={() => setShowMoreInformations(prevState => !prevState)}>
                {showMoreInformations ? 'Ocultar' : 'Saiba quais'}
              </KnowMore>
            </AlertSection>
          )}
          <ButtonBox>
            <ButtonMaxWidth
              text="Baixar"
              typeStyle="purple"
              onClick={() => {
                if (typeDocument === 'pdf') {
                  return handleDownloadNewPDF();
                }
                if (typeDocument === 'word') {
                  return handleDownloadNewWord();
                }
                return handleDownloadPPTX();
              }}
            />
          </ButtonBox>
        </DropdownKindFile>
      )}

      {isOwnerDocument && permissionType === "normal" ? (
        <Tooltip
          title="Compartilhar"
          description="Compartilhe seu projeto com outras pessoas."
          placement="bottom-end"
          maxWidth={200}
        >
          <ButtonShare
            onClick={() => {
              setSelectedObject(null);
              handleOpenModalShared(templateid);
            }}
          >
            <img draggable={false} src={SharedIcon} alt="Compartilhar" />{" "}
            Compartilhar
          </ButtonShare>
        </Tooltip>
      ) : (
        <ButtonShare
          target="_blank"
          className="disable-hover"
          disabled={true}
          style={
            permissionType === "editor" || isTeamTemplate
              ? { opacity: 0.2, cursor: "not-allowed" }
              : {}
          }
        >
          <img draggable={false} src={SharedIcon} alt="Compartilhar" />{" "}
          Compartilhar
        </ButtonShare>
      )}
    </>
  );
};

export default RightButtons;

