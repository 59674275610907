import { useNavigate } from "react-router-dom";
import UxDocLogoSvg from "../../../shared/assets/logo-ux-doc-dash.svg";

import { LogoBox } from "./styles";

const UxDocLogoComponent: React.FC = () => {
  const navigate = useNavigate();

  const handleLogoClick = () => {
    navigate("/");
  };
  return (
    <LogoBox onClick={handleLogoClick} style={{ cursor: "pointer" }}>
      <img src={UxDocLogoSvg} alt="" />
    </LogoBox>
  );
};

export default UxDocLogoComponent;
