import mixpanel from 'mixpanel-browser';

// Garantimos que a variável de ambiente seja uma string
const mixpanelEnv: string = process.env.REACT_APP_MIXPANEL_TOKEN || 'dev';

// Tipos de configuração de inicialização do Mixpanel
interface MixpanelConfig {
  debug: boolean;
  track_pageview: boolean | string;
  persistence: "localStorage" | "cookie";
}

if (mixpanelEnv === 'prod') {
  const config: MixpanelConfig = {
    debug: false,
    track_pageview: "url-with-path",
    persistence: "localStorage",
  };

  mixpanel.init(process.env.REACT_APP_MIXPANEL_PUBLIC_KEY_PROD, config);
}

export default mixpanel;