import React, { useCallback, useEffect, useState, useRef } from "react";
import Modal from "react-modal";

import {
  AvatarCustom,
  ContainerModal,
  FirstAreaItem,
  Header,
  ItemUserInfo,
  Table,
  Text,
  VerticalStack,
  Wrapper,
} from "./styles";
import { AnimatePresence, motion } from "framer-motion";
import api from "../../../../services/api";

import { useSelection } from "../../hooks/selection";

import { useMainHook } from "../../../../hooks/main";
import { useSharedDocument } from "../../hooks/sharedDocument";
import { useNavigate, useParams } from "react-router-dom";

import { StageContainer } from "../ModalVersioning/styles";
import { Layer, Stage } from "react-konva";
import Konva from "konva";
import Rendering from "../Canvas/Rendering";

import { ContainerLoading } from "../LoadingEditor/styles";
import { ContractAnimation } from "../../../../shared/components/ContractAnimation";
import { useTextsEdition } from "../../hooks/textsEdition";
import { useDiff } from "../../hooks/diffDocuments";

import ButtonExit from "../../../../shared/components/ButtonExit";
import TitleH5 from "../../../../shared/components/TitleH5";
import PlainText from "../../../../shared/components/PlainText";
import Iconalert from "../../assets/icon-alert.svg";
import ButtonMaxWidth from "../../../../shared/components/ButtonMaxWidth";
import PreviewTemplateModal from "../../../dashboard/components/PreviewTemplateModal";
import { useWorkspaceEditor } from "../../hooks/workspaceEditor";
import Tooltip from "../Tooltip";

interface UsersSharedProps {
  id: string;
  name: string;
  email: string;
  avatar_url: string;
  permissions: "Owner" | "Editor" | string;
  is_anonymous: boolean;
  current_user: boolean;
}

interface UserAccessControlProps {
  access_control: boolean;
  user_list: UsersSharedProps[];
}

interface ModalActiveUserProps {
  isOpen: boolean;
  onRequestClose?: () => void;
  dataUsers?: UserAccessControlProps;
}

export const ModalActiveUser: React.FC<ModalActiveUserProps> = ({
  isOpen,
  onRequestClose,
  dataUsers,
}) => {
  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        delayChildren: 0.5,
        type: "spring",
        duration: 1,
        stiffness: 100,
        staggerDirection: -1,
      },
    },
  };

  const item = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        delayChildren: 1.9,
        type: "spring",
        stiffness: 100,
      },
    },
  };

  const oneRef = useRef(null);
  const oldPos = useRef(null);
  const selectionRectRef = useRef(null);
  const layerRef = useRef(null);
  const selection = useRef({
    visible: false,
    x1: 0,
    y1: 0,
    x2: 0,
    y2: 0,
  });

  const [previewMode, setPreviewMode] = useState(false);
  const {
    objectScreen,
    addToRefs,
    addToHistory,
    setObjectScreen,
    currentTemplateInfo,
    myProfileId,
    nameOfCurrentUser,
    hasGroupShared,
    documentName,
  } = useMainHook();
  const { templateid, typetemplate } = useParams();
  const [loading, setLoading] = useState(true);
  const {
    setSelectedObject,
    addToMultipleSelectionRef,
    multipleSelectionRefs,
    currentMultipleSelection,

    selectedObject,
    transformerSelectedObject,

    setSelectedObjects,
  } = useSelection();

  const {
    quillRef,
    requestTextUpdate,
    textRich,
    isEditing,
    setIsEditing,
    blockingKeyboard,
    setBlockingKeyboard,
    quillRefComponent,
  } = useTextsEdition();

  const {
    templateStartedShared,
    setTemplateStartedShared,
    oldContentsState,
    setOldContentsState,
    newContentsState,
    setNewContentsState,
    selectionCursor,
    setSelectionCursor,
    findDiff,
    buttonRef,
    newDelta,
    setNewDelta,
    isEditingQuill,
    setIsEditingQuill,
    newChanges,
    setNewChanges,
    newContentsRef,
    oldContentsRef,
    getQuillCursorRef,
    isNewTextRef,
    isTypingQuillRef,
    whichUserEdited,
    interceptModifications,
    textIsChanged,
    blockRenderImage,
    blockBackspace,

    whichKeyPress,
    selectionFormatText,
  } = useDiff();

  const { loadingTemplate } = useWorkspaceEditor();

  const { blockCommandKeyboard, setBlockCommandKeyboard } = useSelection();
  const [sharedUsers, setSharedUsers] = useState<UsersSharedProps[]>([]);
  const {
    refreshUsersShared,
    setRefreshUsersShared,
    isModalActiveUser,
    setIsModalActiveUser,
  } = useSharedDocument();
  const [isOwnerDocument, setIsOwnerDocument] = useState(false);
  const [forceUpdate, setForceUpdate] = useState(false);
  const navigate = useNavigate();

  const widthCanvas: number = 596;
  const heigthCanvas: number = 842;

  useEffect(() => {
    if (dataUsers.access_control === true) {
      setIsModalActiveUser(false);
    } else {
      return;
    }
  }, [dataUsers]);

  useEffect(() => {
    const autoLoadSharedUsers = async () => {
      try {
        const responseTemplatesUsers = await api.get(
          `user-share-template/all-shared-template-users/${templateid}`
        );
        setSharedUsers(responseTemplatesUsers.data.users);
      } catch (err) {
        console.error(err, "ERROR when fetch users shared template");
      }
    };

    if (typetemplate === 'team-template') return;
    autoLoadSharedUsers();
  }, [refreshUsersShared]);

  const handleDeleteUserShare = useCallback(
    async (idUser) => {
      let userId = idUser;
      let documentId = templateid;

      try {
        const responseDeleteUserShare = await api.delete(
          `user-share-template/delete-user-share/${userId}/${documentId}`
        );

        setRefreshUsersShared(!refreshUsersShared);
      } catch (err) {
        console.error(err, "ERROR when delete user shared");
      }
    },
    [refreshUsersShared]
  );

  // useEffect(() => {
  //   setTimeout(() => {
  //     setForceUpdate(!forceUpdate);
  //   }, 2000);
  // }, []);

  useEffect(() => {
    const timeOut = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => {
      clearTimeout(timeOut);
    };
  }, []);

  useEffect(() => {
    if (myProfileId) {
      const getOwnerId = sharedUsers.find(
        (user) => user.permissions === "Owner"
      );

      if (getOwnerId?.id === myProfileId) {
        setIsOwnerDocument(true);
      } else {
        setIsOwnerDocument(false);
      }
    }
  }, [forceUpdate]);

  if (loading) {
    return (
      <div className="loading-application">
        <ContainerLoading>
          {/* <LoadingEditor /> */}
          <ContractAnimation />
        </ContainerLoading>
      </div>
    );
  }

  const windows = window as any;
  const Konvas = windows.Konva;

  const updateSelectionRect = () => {
    const node = selectionRectRef.current;
    node?.setAttrs({
      visible: selection.current.visible,
      x: Math.min(selection.current.x1, selection.current.x2),
      y: Math.min(selection.current.y1, selection.current.y2),
      width: Math.abs(selection.current.x1 - selection.current.x2),
      height: Math.abs(selection.current.y1 - selection.current.y2),
      fill: "rgba(110, 12, 61, 0.3)",
    });
    node.getLayer().batchDraw();
  };

  const onMouseDown = (e) => {
    if (e.target instanceof Konva.Stage) {
      setSelectedObject(null);

      // cleaning currentMutipleSelection
      if (currentMultipleSelection.current) {
        currentMultipleSelection.current.setNodes([]);
        setSelectedObjects([]);
      }

      const pos = e.target.getStage().getPointerPosition();
      selection.current.visible = true;
      selection.current.x1 = pos.x;
      selection.current.y1 = pos.y;
      selection.current.x2 = pos.x;
      selection.current.y2 = pos.y;
      updateSelectionRect();
      return;
    }
  };

  const onMouseMove = (e) => {
    if (!selection.current.visible) {
      return;
    }

    const pos = e.target.getStage().getPointerPosition();
    selection.current.x2 = pos.x;
    selection.current.y2 = pos.y;
    updateSelectionRect();
  };

  const onMouseUp = (event) => {
    //assigning currentMutipleSelection to the multpleSelection in the stage with it's index

    const currentIndex = event.currentTarget.attrs.id - 1;
    currentMultipleSelection.current =
      multipleSelectionRefs.current[currentIndex];

    oldPos.current = null;
    if (!selection.current.visible) {
      return;
    }
    // getting properties of selectionRect
    const selBox = selectionRectRef.current.getClientRect();

    const elements = [];

    layerRef.current.children.forEach((elementNode, index) => {
      if (
        elementNode !== currentMultipleSelection.current &&
        elementNode !== selectionRectRef.current
      ) {
        const elBox = elementNode.getClientRect();

        if (Konva.Util.haveIntersection(selBox, elBox)) {
          elements.push(elementNode);
        }
      }
    });

    currentMultipleSelection.current.setNodes(elements);
    setSelectedObjects(elements);

    selection.current.visible = false;
    updateSelectionRect();
  };

  return (
    <>
      {dataUsers && (
        <Modal
          className="react-modal-content2"
          overlayClassName="react-modal-overlay-without-preview"
          isOpen={isOpen}
          onRequestClose={onRequestClose}
          ariaHideApp={false}
        >
          <AnimatePresence>
            <ContainerModal
              as={motion.form}
              initial="hidden"
              animate="show"
              variants={container}
              exit={{
                opacity: 0,
                scale: 0.8,
                x: -300,
              }}
              transition={{ duration: 1 }}
            >
              <Header>
                <ButtonExit onClick={onRequestClose} className="btn-exit" />

                <TitleH5
                  title={`Compartilhar ${currentTemplateInfo?.current?.title}`}
                />
              </Header>

              <Table>
                <div>
                  <p>Nome</p> <p>E-mail</p> <p>Função na equipe</p>
                </div>

                <ul>
                  {dataUsers.user_list?.map((user, index) => (
                    <ItemUserInfo key={user.name}>
                      <FirstAreaItem>
                        <figure>
                          <AvatarCustom indexOfUser={index + 1}>
                            <PlainText>
                              {`${user.name.charAt(0).toUpperCase()}`}
                            </PlainText>
                          </AvatarCustom>
                        </figure>

                        <Tooltip title={`${user.name}`} placement="top">
                          <p>{user.name}</p>
                        </Tooltip>
                      </FirstAreaItem>
                      <Tooltip title={`${user.email}`} placement="top">
                        <span className="email">{user.email}</span>
                      </Tooltip>
                      <PlainText>
                        {user.permissions === "Owner"
                          ? "Proprietário"
                          : "Membro"}{" "}
                      </PlainText>
                      {user.current_user && (
                        <div className="active-user">ativo</div>
                      )}
                    </ItemUserInfo>
                  ))}
                </ul>
              </Table>

              <Text>
                <img src={Iconalert} alt="" />
                <p>
                  O documento está sendo editado no momento. Clique no botão
                  “visualizar” para ver o documento.
                </p>
              </Text>

              <Wrapper>
                <ButtonMaxWidth
                  text="Fechar"
                  typeStyle="white"
                  onClick={() => navigate("/")}
                />
                <ButtonMaxWidth
                  text="Visualizar"
                  typeStyle="purple"
                  onClick={() => setPreviewMode((prevState) => !prevState)}
                />
              </Wrapper>
            </ContainerModal>
          </AnimatePresence>
        </Modal>
      )}

      {previewMode && (
        <PreviewTemplateModal
          open={previewMode}
          handleCloseModal={() => setPreviewMode((prevState) => !prevState)}
          pagesToBeRendered={objectScreen}
          title={documentName.current}
          loadingPreview={loadingTemplate}
          local="Editor"
          texButton="Usar Template"
        />
      )}
    </>
  );
};

