import React, { useEffect, useState } from "react";
import {
  ButtonText,
  ConfirmButton,
  FlowContainer,
  Header,
  LinkButton,
  LoadingContainer,
  StepBottomText,
  StepNormalText,
  StepTextWrapper,
  StepWrapper,
  StepsFlexWrapper,
  Title
} from "./styles";
import StepIcon from "../StepIcon";
import AddIcon from "../AddIcon";
import { ApprovalFlow, Step } from "../../types/ApprovalFlow";
import AddStep from "../AddStep";
import { v4 as uuidv4 } from "uuid";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../../../../services/api";
import Tooltip from "../../../../editor/components/Tooltip";
import {
  ApprovalFlowRequest,
  StepRequest
} from "../../types/ApprovalFlowRequest";
import { ContractAnimation } from "../../../../../shared/components/ContractAnimation";
import { Stack } from "@mui/material";
import { BiChevronLeft } from "react-icons/bi";
import { Box } from "@material-ui/core";
import { Container, FullContent } from "../../styles";
import { ToastContainer, toast } from "react-toastify";

const FlowSteps: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [approvalFlow, setApprovalFlow] = useState<ApprovalFlowRequest>();
  const [steps, setSteps] = useState<StepRequest[]>([]);
  const [newStep, setNewStep] = useState<StepRequest>();
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(true);

  const createNewStep = () => {
    const stepId = uuidv4();
    setNewStep({
      id: stepId,
      name: "",
      order: steps.length + 1,
      approval_flow_id: approvalFlow.id,
      status: "PENDING",
      deadline: 1,
      requirements: [
        {
          id: uuidv4(),
          name: "",
          description: "",
          step_id: stepId,
          status: "PENDING",
          approval_flow_id: approvalFlow.id
        }
      ],
      approvers: [
        {
          id: uuidv4(),
          name: "",
          email: "",
          step_id: stepId,
          status: "PENDING",
          approval_flow_id: approvalFlow.id
        }
      ]
    });
    setOpen(true);
  };

  const onSubmit = async () => {
    if (steps.length === 0) {
      back();
      return;
    }
    try {
      if (loading) return;
      setLoading(true);
      const finalFlow = {
        ...approvalFlow,
        steps: steps,
        creator_id: undefined
      };
      await api.post("/approval-flows", finalFlow);

      navigate("/approvalflow/my-flows");
    } catch (error) {
      console.error(error);
      toast.error("Erro ao criar fluxo de aprovação");
    }
  };

  const elipsis = (text: string) => {
    const length = 25;
    return text.length > length ? text.slice(0, length) + "..." : text;
  };

  const editStep = (step: StepRequest) => {
    setNewStep(step);
    setIsEditing(true);
    setOpen(true);
  };

  const back = () => {
    navigate("/approvalflow/my-flows", {
      replace: true,
      state: { flow: approvalFlow }
    });
  };

  useEffect(() => {
    const fetchSteps = async () => {
      setLoading(true);
      const response = await api.get(`/approval-flows/${id}`);
      const responseData = response.data as ApprovalFlow;
      setApprovalFlow(mapToRequest(responseData));
      setTimeout(() => {
        setLoading(false);
      }, 300);
    };
    fetchSteps();
  }, [id]);

  const mapToRequest = (flow: ApprovalFlow): ApprovalFlowRequest => {
    const mappedMembers = flow.members.map(member => {
      const { first_name, last_name, created_at, updated_at, email, ...rest } =
        member;
      return rest;
    });

    const mappedSteps = flow.steps.map(step => {
      const { created_at, updated_at, requirements, approvers, ...rest } = step;
      const mappedRequirements = requirements.map(requirement => {
        const { created_at, updated_at, ...rest } = requirement;
        return rest;
      });
      const mappedApprovers = approvers.map(approver => {
        const { created_at, updated_at, ...rest } = approver;
        return rest;
      });
      return {
        ...rest,
        requirements: mappedRequirements,
        approvers: mappedApprovers
      };
    });

    return {
      id: flow.id,
      name: flow.name,
      steps: mappedSteps,
      members: mappedMembers,
      is_template: flow.is_template,
      status: flow.status
    };
  };

  return (
    <FullContent>
      <ToastContainer />
      <Container>
        {loading && (
          <LoadingContainer>
            <ContractAnimation />
          </LoadingContainer>
        )}
        {!loading && (
          <FlowContainer>
            <Header>
              <Stack direction="row" alignItems={"center"}>
                <Box style={{ cursor: "pointer" }} onClick={back}>
                  <BiChevronLeft fontSize={"24px"} />
                </Box>
                <Title>{approvalFlow?.name}</Title>
              </Stack>
            </Header>

            <StepsFlexWrapper>
              {steps.map(step => (
                <StepWrapper key={step.id} onClick={() => editStep(step)}>
                  <StepIcon color="#7547A3" />
                  <StepTextWrapper>
                    <Tooltip title={step.name} placement="top">
                      <StepNormalText>{elipsis(step.name)}</StepNormalText>
                    </Tooltip>
                  </StepTextWrapper>
                  <StepBottomText>ETAPA {step.order}</StepBottomText>
                </StepWrapper>
              ))}

              <StepWrapper onClick={createNewStep}>
                <StepIcon color="white" />
                <StepTextWrapper>
                  <LinkButton>
                    <AddIcon color={"#7547A3"} />
                    <ButtonText>Adicionar etapa</ButtonText>
                  </LinkButton>
                </StepTextWrapper>
                <StepBottomText>ETAPA {steps.length + 1}</StepBottomText>
              </StepWrapper>
            </StepsFlexWrapper>

            <ConfirmButton onClick={onSubmit}>
              {steps.length === 0 ? "Voltar" : "Concluir"}
            </ConfirmButton>

            {open && (
              <AddStep
                open={open}
                step={newStep}
                setStep={setNewStep}
                onClose={(step?: Step) => {
                  if (step && !isEditing) {
                    setSteps([...steps, step]);
                  } else if (step && isEditing) {
                    const index = steps.findIndex(s => s.id === step.id);
                    const newSteps = [...steps];
                    newSteps[index] = step;
                    setSteps(newSteps);
                  }
                  setOpen(false);
                  setIsEditing(false);
                }}
                stepNumber={steps.length + 1}
                flow_id={approvalFlow.id}
              />
            )}
          </FlowContainer>
        )}
      </Container>
    </FullContent>
  );
};

export default FlowSteps;
