import React, { useEffect, useState } from "react";
import ButtonToolbar from "../ButtonToolbar";
import { motion } from "framer-motion";
import Slider from "rc-slider";
import useComponentVisible from "../../../hooks/useComponentVisible";
import {
  ContainerSlider,
  ContainerTextValue,
  DropDownContainer,
  DropDownHeader,
  DropDownList,
  DropDownListContainer,
  Flex,
  HStack,
  Text,
  Input
} from "./styles";
import { TooltipProps } from "@mui/material";
import { useSelection } from "../../../hooks/selection";

interface DropdownOpacityProps {
  isActive?: boolean;
  className?: string;
  type?: "button" | "submit" | "reset";
  tooltipTitle: string;
  tooltipDescription?: string;
  tooltipPlacement?: TooltipProps["placement"];
  onClick?: any;
  component?: string;
  valueOpacity: number;
  setValueOpacity: React.Dispatch<React.SetStateAction<number>>;
  callback: (value: number) => void;
}

const DropdownOpacity: React.FC<DropdownOpacityProps> = ({
  isActive,
  className,
  type,
  tooltipTitle,
  tooltipDescription,
  tooltipPlacement,
  onClick,
  component,
  callback,
  valueOpacity,
  setValueOpacity
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState(valueOpacity.toString()); // Novo estado para armazenar o valor do input como string
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(true);
    const {
      setForceBlockKey,
    } = useSelection();

  const handleClick = () => setIsOpen(true);

  useEffect(() => {
    if (!isComponentVisible) {
      setIsOpen(false);
      setIsComponentVisible(true);
    }
  }, [isComponentVisible, setIsComponentVisible]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    
    setInputValue(newValue);

    const numericValue = parseFloat(newValue);

    if (!isNaN(numericValue)) {
      const clampedValue = Math.min(Math.max(numericValue, 0), 100);
      setValueOpacity(clampedValue);
      setInputValue(clampedValue.toString());
      callback(clampedValue);
    }
  };

  const handleSliderChange = (value: number) => {
    setValueOpacity(value);
    setInputValue(value.toString());
    callback(value);
  };

  useEffect(() => {
    if (isOpen) {
      setForceBlockKey(true);
    } else {
      setForceBlockKey(false);
    }
  }, [isOpen]);

  return (
    <Flex>
      <DropDownContainer ref={ref}>
        <DropDownHeader Open={isOpen} onClick={handleClick}>
          <ButtonToolbar
            isActive={isOpen}
            className="btn-toolbar"
            type="button"
            component="isOpacity"
            tooltipTitle={tooltipTitle}
            tooltipDescription={tooltipDescription}
            tooltipPlacement={tooltipPlacement}
          />
          {isOpen && (
            <DropDownListContainer>
              <HStack>
                <DropDownList
                  as={motion.ul}
                  initial="hidden"
                  animate="visible"
                >
                  <ContainerTextValue>
                    <Text>Transparência</Text>
                    <Input
                      type="number"
                      value={inputValue}
                      onChange={handleInputChange}
                      min="0"
                      max="100"
                    />
                  </ContainerTextValue>

                  <ContainerSlider>
                    <Slider
                      onChange={handleSliderChange}
                      value={valueOpacity}
                      min={0}
                      max={100}
                    />
                  </ContainerSlider>
                </DropDownList>
              </HStack>
            </DropDownListContainer>
          )}
        </DropDownHeader>
      </DropDownContainer>
    </Flex>
  );
};

export default DropdownOpacity;
