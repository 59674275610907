import React, { useCallback, useEffect, useState } from "react";
import {
  LoadingContainer,
  MenuItemWrapper,
  THead,
  Table,
  TableContainer,
  TableData,
  TableHead,
  TableLabel,
  TableRow
} from "./styles";
import NavigationBar from "../NavigationBar";
import {
  Title,
  Header,
  SearchContainer,
  SearchLeftSide,
  SearchButton,
  FullContent,
  Container
} from "../../styles";
import FilterIcon from "../FilterIcon";
import ThreeDotsIcon from "../ThreeDotsIcon";
import { Box, Divider, Menu, MenuItem } from "@material-ui/core";
import DownloadIcon from "../DownloadIcon";
import TrashIcon from "../TrashIcon";
import { useNavigate } from "react-router-dom";
import Tooltip from "../../../../editor/components/Tooltip";
import { useAuth } from "../../../../../hooks/auth";
import { ContractAnimation } from "../../../../../shared/components/ContractAnimation";
import { BiInfoCircle } from "react-icons/bi";
import { ApprovalProject } from "../../types/ApprovalProject";
import api from "../../../../../services/api";
import { ToastContainer, toast } from "react-toastify";
import { MyApproval } from "../../types/MyApproval";
import StickIcon from "../StickIcon";
import ReviewForm from "../ReviewForm";
import DeleteProject from "../DeleteProject";
import DropdownFilter, { DropdownOption } from "../DropdownFilter";
import FilterOffIcon from "../FilterOffIcon";
import SearchInputBase from "../SearchInputBase";
import { projectStatusOptions } from "../MyProjects";
import { Stack } from "@mui/material";

const myReviewsStatus = [
  { value: "PENDING", label: "Pendente" },
  { value: "APPROVED", label: "Aprovado" },
  { value: "REFUSED", label: "Rejeitado" }
];
const MyApprovals: React.FC = () => {
  const [activeRow, setActiveRow] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [isReviewOpen, setIsReviewOpen] = useState(false);
  const [deleteProjectOpen, setDeleteProjectOpen] = useState(false);
  const [myApproval, setMyApproval] = useState<MyApproval>();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [approvals, setApprovals] = useState<MyApproval[]>([]);
  const [stepOptions, setStepOptions] = useState<DropdownOption[]>([]);
  const [showFilters, setShowFilters] = useState(false);
  const [search, setSearch] = useState("");
  const [selectedCreatorsName, setSelectedCreatorsName] = useState<string[]>(
    []
  );
  const [myReviewsStatusSelected, setMyReviewsStatusSelected] = useState<
    string[]
  >([]);
  const [selectedStepNames, setSelectedStepNames] = useState<string[]>([]);
  const [selectedStatuses, setSelectedStatuses] = useState<string[]>([]);
  const [creation_date, setCreationDate] = useState<String>();
  const [createdByOptions, setCreatedByOptions] = useState<DropdownOption[]>(
    []
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const fetchProjects = useCallback(async () => {
    setLoading(true);

    const params = [];

    if (search) {
      params.push(`search=${search}`);
    }

    if (selectedCreatorsName.length > 0) {
      selectedCreatorsName.forEach(creator => {
        params.push(`creators[]=${creator}`);
      });
    }

    if (myReviewsStatusSelected.length > 0) {
      myReviewsStatusSelected.forEach(status => {
        params.push(`statuses[]=${status}`);
      });
    }

    if (selectedStepNames.length > 0) {
      selectedStepNames.forEach(step => {
        params.push(`steps[]=${step}`);
      });
    }

    if (selectedStatuses.length > 0) {
      selectedStatuses.forEach(status => {
        params.push(`statuses[]=${status}`);
      });
    }

    if (creation_date) {
      params.push(`creation_date=${creation_date}`);
    }

    if (myReviewsStatusSelected.length > 0) {
      myReviewsStatusSelected.forEach(status => {
        params.push(`my_reviews_statuses[]=${status}`);
      });
    }

    const query = params.join("&");

    const baseUrl = `approval-projects/user/reviews`;

    const finalUrl = query ? `${baseUrl}?${query}` : baseUrl;

    const response = await api.get(finalUrl);
    setApprovals(response.data as MyApproval[]);
    setLoading(false);
  }, [
    creation_date,
    myReviewsStatusSelected,
    search,
    selectedCreatorsName,
    selectedStatuses,
    selectedStepNames
  ]);

  useEffect(() => {
    fetchProjects();
  }, [fetchProjects]);

  const deleteProject = (project: MyApproval) => {
    setMyApproval(project);
    setDeleteProjectOpen(true);
  };

  const statuses = {
    PENDING: "Pendente",
    APPROVED: "Aprovado",
    REFUSED: "Rejeitado",
    CANCELED: "Cancelado"
  };

  const goToReview = (project: MyApproval) => {
    setMyApproval(project);
    setIsReviewOpen(true);
  };

  const handleDownload = async (id: string) => {
    try {
      handleClose();
      toast.info("Baixando documento...");
      const response = await api.get(`/approval-projects/${id}`);
      const project = response.data as ApprovalProject;
      const flows = project.approval_flows;
      const lastFlow = flows[flows.length - 1];
      const url = lastFlow.document_file_url;
      if (!url) {
        toast.error(
          "Documento não encontrado. Verifique se o documento foi enviado para o fluxo de aprovação."
        );
        return;
      }
      const responseFile = await fetch(url);
      if (responseFile.status === 200) {
        const blob = await responseFile.blob();
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.download = `${project.name}.pdf`;
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(downloadUrl); // Clean up the URL object
      } else {
        toast.error(
          "Ocorreu um erro ao baixar o documento. Contate o suporte técnico."
        );
      }
    } catch (error) {
      console.error(error);
      toast.error(
        "Ocorreu um erro ao baixar o documento. Contate o suporte técnico."
      );
    }
  };

  const pendingApprovals = approvals.filter(
    approval =>
      approval.status === "PENDING" && approval.project_status !== "CANCELED"
  );

  const othersApprovals = approvals.filter(
    approval =>
      approval.status !== "PENDING" || approval.project_status === "CANCELED"
  );

  const getProjectStatus = (project: MyApproval) => {
    if (project.current_step_status === "REFUSED") {
      return "Recusado";
    } else {
      return statuses[project.project_status];
    }
  };

  const getCurrentStep = (project: MyApproval) => {
    if (project.project_status === "CANCELED") {
      return "Cancelado";
    } else if (project.project_status === "APPROVED") {
      return "Finalizado";
    } else if (project.current_step_status === "REFUSED") {
      return "Aguardando ajustes";
    } else {
      return project.current_step;
    }
  };

  useEffect(() => {
    const listMembersAndCreators = async () => {
      const response = await api.get(`approval-flows/members/creators`);
      const { data } = response;
      const creators = data.creators.map(creator => ({
        value: creator.id,
        label: `${creator.first_name} ${creator.last_name}`
      }));
      const members = data.members.map(member => ({
        value: member.id,
        label: `${member.first_name} ${member.last_name}`
      }));

      const distincts = [...creators, ...members].reduce((acc, current) => {
        const x = acc.find(item => item.value === current.value);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);

      setCreatedByOptions(distincts);
    };

    listMembersAndCreators();
  }, []);

  useEffect(() => {
    const listOptions = async () => {
      const response = await api.get(`approval-flows/flows/steps`);
      const { data } = response;

      const steps = data.stepOptions.map(step => ({
        value: step.name,
        label: step.name
      }));
      setStepOptions(steps);
    };

    listOptions();
  }, []);

  return (
    <>
      <FullContent>
        <ToastContainer />
        <Container>
          <Header>
            <Title>Fluxo de Aprovação</Title>
            <Tooltip
              description={"Este é o local onde você pode criar os seus fluxos"}
              placement="bottom-start"
            >
              <figure>
                <BiInfoCircle />
              </figure>
            </Tooltip>
          </Header>
          <NavigationBar activeTab="Minhas Aprovações" />
          <SearchContainer>
            <SearchLeftSide>
              <SearchInputBase
                placeholder="Pesquisar"
                value={search}
                onChange={e => setSearch(e.target.value)}
                setSearch={setSearch}
              />
              <SearchButton
                onClick={() => {
                  setShowFilters(!showFilters);
                }}
              >
                {!showFilters && <FilterIcon color="#7547A3" />}

                {showFilters && <FilterOffIcon />}
              </SearchButton>
              {showFilters && (
                <Stack direction={"row"} spacing={1}>
                  <DropdownFilter
                    options={[]}
                    onChange={options => setCreationDate(options[0])}
                    label={"Enviado em"}
                    isDateInput
                  />
                  <DropdownFilter
                    options={createdByOptions}
                    onChange={options => setSelectedCreatorsName(options)}
                    label={"Enviado por"}
                    showSearch
                  />
                  <DropdownFilter
                    options={myReviewsStatus}
                    onChange={options => setMyReviewsStatusSelected(options)}
                    label={"Minha avaliação"}
                    showSearch
                  />
                  <DropdownFilter
                    options={projectStatusOptions}
                    onChange={options => setSelectedStatuses(options)}
                    label={"Status do projeto"}
                  />
                  <DropdownFilter
                    options={stepOptions}
                    onChange={options => setSelectedStepNames(options)}
                    label={"Etapa"}
                  />
                </Stack>
              )}
            </SearchLeftSide>
          </SearchContainer>
          <Stack position={"relative"} spacing={2}>
            {loading && (
              <LoadingContainer>
                <ContractAnimation />
              </LoadingContainer>
            )}

            <TableLabel>Pendentes</TableLabel>
            <TableContainer>
              <Table style={{ tableLayout: 'fixed', width: '100%' }}>
                <THead>
                  <tr>
                    <TableHead style={{ width: '500px' }}>Nome do projeto</TableHead>
                    <TableHead>Enviado por</TableHead>
                    <TableHead>Enviado em</TableHead>
                    <TableHead>Minha avaliação</TableHead>
                    <TableHead>Status do Projeto</TableHead>
                    <TableHead>Etapa Atual</TableHead>
                    <TableHead style={{ textAlign: 'right' }}></TableHead>
                  </tr>
                </THead>
                {!loading && (
                  <tbody>
                    {pendingApprovals.map((approval, index) => (
                      <TableRow
                        key={approval.project_id}
                        onMouseEnter={() => setActiveRow(approval.id)}
                        onMouseLeave={() => {
                          setActiveRow(undefined);
                          handleClose();
                        }}
                      >
                        <TableData>{approval.project_name}</TableData>
                        <TableData>{approval.sent_by}</TableData>
                        <TableData>
                          {new Date(approval.sent_at).toLocaleDateString()}
                        </TableData>
                        <TableData>{statuses[approval.status]}</TableData>
                        <TableData>{getProjectStatus(approval)}</TableData>
                        <TableData>{getCurrentStep(approval)}</TableData>
                        <TableData style={{ textAlign: 'right', padding: 0, cursor: "pointer" }}>
                          {activeRow === approval.id && (
                            <>
                              <Box
                                id={`action-button_${index}`}
                                aria-controls={open ? `action-menu_${index}` : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? "true" : undefined}
                                onClick={handleClick}
                                style={{
                                  marginRight: '25px'
                                }}
                              >
                                <ThreeDotsIcon />
                              </Box>

                              <Menu
                                id={`action-menu_${index}`}
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                MenuListProps={{
                                  "aria-labelledby": `action-button_${index}`
                                }}
                                style={{
                                  marginLeft: '60px'
                                }}
                              >
                                <MenuItem onClick={() => goToReview(approval)}>
                                  <MenuItemWrapper>
                                    <StickIcon />
                                    Avaliar
                                  </MenuItemWrapper>
                                </MenuItem>
                              </Menu>
                            </>
                          )}
                        </TableData>
                      </TableRow>
                    ))}
                  </tbody>
                )}
              </Table>
              {!loading && pendingApprovals.length === 0 && (
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  fontSize={"13px"}
                  color={"#676B70"}
                  p={2}
                >
                  Nenhuma pendência encontrada.
                </Box>
              )}
            </TableContainer>

            <TableLabel>Outros</TableLabel>
            <TableContainer>
              <Table style={{ tableLayout: 'fixed', width: '100%' }}>
                <THead>
                  <tr>
                    <TableHead style={{ width: '500px' }}>Nome do projeto</TableHead>
                    <TableHead>Enviado por</TableHead>
                    <TableHead>Enviado em</TableHead>
                    <TableHead>Minha avaliação</TableHead>
                    <TableHead>Status do Projeto</TableHead>
                    <TableHead>Etapa Atual</TableHead>
                    <TableHead></TableHead>
                  </tr>
                </THead>

                {!loading && (
                  <tbody>
                    {othersApprovals.map((approval, index) => (
                      <TableRow
                        key={approval.project_id}
                        onMouseEnter={() => setActiveRow(approval.id)}
                        onMouseLeave={() => {
                          setActiveRow(undefined);
                          handleClose();
                        }}
                      >
                        <TableData>{approval.project_name}</TableData>
                        <TableData>{approval.sent_by}</TableData>
                        <TableData>
                          {new Date(approval.sent_at).toLocaleDateString()}
                        </TableData>
                        <TableData>{statuses[approval.status]}</TableData>
                        <TableData>{getProjectStatus(approval)}</TableData>
                        <TableData>{getCurrentStep(approval)}</TableData>
                        <TableData style={{ textAlign: 'right', padding: 0, cursor: "pointer" }}>
                          {activeRow === approval.id && (
                            <>
                              <Box
                                id={`action-button_${index}`}
                                aria-controls={open ? `action-menu_${index}` : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? "true" : undefined}
                                onClick={handleClick}
                                style={{
                                  marginRight: '25px'
                                }}
                              >
                                <ThreeDotsIcon />
                              </Box>

                              <Menu
                                id={`action-menu_${index}`}
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                MenuListProps={{
                                  "aria-labelledby": `action-button_${index}`
                                }}
                                style={{
                                  marginLeft: '60px'
                                }}
                              >
                                <MenuItem
                                  onClick={() => handleDownload(approval.project_id)}
                                >
                                  <MenuItemWrapper>
                                    <DownloadIcon color="#676B70" /> Baixar
                                  </MenuItemWrapper>
                                </MenuItem>

                                <Divider style={{ margin: '10px 0px'}} />

                                <MenuItem onClick={() => deleteProject(approval)}>
                                  <MenuItemWrapper>
                                    <TrashIcon color="#676B70" />
                                    Excluir
                                  </MenuItemWrapper>
                                </MenuItem>
                              </Menu>
                            </>
                          )}
                        </TableData>
                      </TableRow>
                    ))}
                  </tbody>
                )}
              </Table>

              {!loading && othersApprovals.length === 0 && (
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  fontSize={"13px"}
                  color={"#676B70"}
                  p={2}
                >
                  Nenhum projeto encontrado.
                </Box>
              )}
            </TableContainer>
          </Stack>

          {isReviewOpen && myApproval && (
            <ReviewForm
              open={isReviewOpen}
              onClose={(refresh: boolean) => {
                if (refresh) {
                  fetchProjects();
                }
                setIsReviewOpen(false);
              }}
              approver_id={myApproval.id}
            />
          )}
          {deleteProjectOpen && (
            <DeleteProject
              open={deleteProjectOpen}
              project_id={myApproval.project_id}
              onClose={refresh => {
                setDeleteProjectOpen(false);
                if (refresh) fetchProjects();
              }}
            />
          )}
        </Container>
      </FullContent>
    </>
  );
};

export default MyApprovals;
