/*
this hook have it responsability in 
save our user values and authenticated that user
also we work at that time refresh token
and payment details brings by api and stripe method payment kind user
*/

import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useEffect,
} from "react";

import api from "../services/api";
import { AxiosError } from "axios";
import secureLocalStorage from "react-secure-storage";
// import analytics from "../services/segment";
import { useNavigate } from "react-router-dom";
import mixpanel from "mixpanel-browser";
import moment from "moment";

//set up interfaces

interface IUser {
  id: string;
  people_id: string;
  email: string;
  avatar: "avatar_default.png" | string;
  is_admin: boolean;
  is_blocked: boolean;
  created_at: string;
  updated_at: string;
  avatar_url: string;
  user_type: string;
  otp_enabled: boolean;
  otp_verified: boolean;
  otp_validate: boolean;
}

interface IData {
  user: IUser;
  people: {
    id: string;
    address_id: string;
    contact_id: string;
    first_name: string;
    last_name: string;
    born_at: string;
    gender: string;
    document: string;
    is_finished: boolean;
    created_at: string;
    updated_at: string;
  };
  address: {
    id: string;
    zipcode: string;
    street: string;
    number: number;
    district: string;
    city: string;
    state: string;
    country: string;
    is_finished: boolean;
    created_at: string;
    updated_at: string;
  };
  contact: {
    id: string;
    phone: string;
    is_finished: boolean;
    created_at: string;
    updated_at: string;
  };
  organization: {
    id?: string;
    creator_id?: string;
    last_editor_id?: string;
    name?: string;
    logo?: string;
    url?: string;
    created_at?: string;
    updated_at?: string;
    logo_url?: string;
  };
  organization_theme: {
    id?: string;
    organization_id?: string;
    primary_color?: string;
    menu_background_color?: string;
    menu_text_color?: string;
    created_at?: string;
    updated_at?: string;
  };
  token: string;
  is_block_editor: boolean;
  tokenFirebase: string;
  email_sending_authorization?: boolean;
  payment_method: {
    user_type?: string;
    is_active: boolean;
    customer_id?: string;
    subscription_id?: string;
    cancellation_date?: string;
  };
  account_questions: {
    where_do_you_work?: string;
    how_did_you_meet_us?: string;
  };
}

interface AuthContextData {
  data: IData;
  loading: boolean;
  typeUser: "Paid" | "Unpaid" | "Suspended" | "Loading";
  signIn(
    email: string,
    password: string,
    organizationMode?: boolean
  ): Promise<void>;
  signOut(): Promise<void>;
  createdAccount?: boolean;
  setCreatedAccount?: React.Dispatch<React.SetStateAction<boolean>>;
  loadingTypeUser?: boolean;
  userStripe: any;
  modifyProfile: boolean;
  setModifyProfile: React.Dispatch<React.SetStateAction<boolean>>;
  permissionType: "loading" | "editor" | "normal";
  setPermissionType: React.Dispatch<
    React.SetStateAction<"loading" | "editor" | "normal">
  >;
  setData: React.Dispatch<React.SetStateAction<IData>>;
  organization: string;
  setOrganization: React.Dispatch<React.SetStateAction<string>>;
  notificationTwoFactor: boolean;
  setNotificationsTwoFactor: React.Dispatch<React.SetStateAction<boolean>>;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  //set up states
  const [data, setData] = useState<IData | null>(null);
  const [loading, setLoading] = useState(false);
  const [loadingTypeUser, setLoadingTypeUser] = useState(true);
  const [modifyProfile, setModifyProfile] = useState(false);
  const [notificationTwoFactor, setNotificationsTwoFactor] = useState(true);
  const [permissionType, setPermissionType] = useState<
    "editor" | "normal" | "loading"
  >("loading");
  const [userStripe, setUserStripe] = useState("");
  const [typeUser, setTypeUser] = useState<
    "Paid" | "Unpaid" | "Suspended" | "Loading"
  >("Loading");
  const [createdAccount, setCreatedAccount] = useState(false);
  const navigate = useNavigate();
  const [organization, setOrganization] = useState<string>("");
  const findEmailBits = data?.user?.email.match("@bitsacademy.com.br");

  //function responsible for track login action
  const handleLoggedUserAction = useCallback((data: IData) => {
    if (!findEmailBits) {
      setTimeout(() => {
        mixpanel.track("Logged User", {
          $name: `${data.people.first_name} ${data.people.last_name}`,
          $date: moment().format('YYYY-MM-DD'),
          $user_id: data.user.id,
        });
      }, 200);
    }
  }, [findEmailBits]);

  //function responsible for register refresh token action
  const handleRefreshTokenAction = useCallback(() => {
    if (!findEmailBits) {
      mixpanel.track("Updated Access Session", {
        date: moment().format('YYYY-MM-DD'),
      });
    }
  }, [findEmailBits]);

  //Needed to control the execution of our refresh token
  useEffect(() => {
    let isRefreshing = false;
    let failedRequestQueue: Array<{
      onSuccess: (token: string) => void;
      onFailure: (err: AxiosError) => void;
    }> = [];
    //intercepting when token error was trigger
    api.interceptors.response.use(
      (response) => {
        return response;
      },
      (error: AxiosError) => {
        if (error.response?.status === 401) {
          if (error.response.data.message === "Subscription canceled") {
            const dataLocalStorage: any =
              secureLocalStorage.getItem("@UXDOCNEW2:data");
            const formattedData = JSON.parse(String(dataLocalStorage));
            if (!formattedData?.payment_method?.subscription_id) {
              setTypeUser("Unpaid");
            } else if (
              formattedData?.payment_method?.subscription_id &&
              formattedData?.payment_method.customer_id
            ) {
              setTypeUser("Suspended");
            }
          }

          if (
            error?.response?.data?.message ===
            "Incorrect email/password combination."
          ) {
            // if incorrect email we throw error to get catch on sign function
            throw error;
          } else if (error.response.data?.message === "Invalid JWT token") {
            //if error of refresh token
            //then get old token between in browser
            const originalConfig = error.config;
            const oldToken = secureLocalStorage.getItem("@UXDOCNEW2:token");

            if (!isRefreshing) {
              isRefreshing = true;
              //renew that old token and change it in local storage of browser
              setLoading(true);
              api
                .put("/sessions/refresh-token", { token: oldToken })
                .then((response) => {
                  //receive refresh token to the api
                  const { refresh_token } = response.data;
                  //set item on local storage and headers of request
                  secureLocalStorage.setItem("@UXDOCNEW2:token", refresh_token);
                  api.defaults.headers.common[
                    "Authorization"
                  ] = `Bearer ${refresh_token}`;
                  //then execute others request from this queue
                  failedRequestQueue.forEach((request) =>
                    request.onSuccess(refresh_token)
                  );
                  failedRequestQueue = [];

                  handleRefreshTokenAction();
                  setLoading(false);
                })
                .catch((err) => {
                  setLoading(false);
                  failedRequestQueue.forEach((request) =>
                    request.onFailure(err)
                  );
                  failedRequestQueue = [];
                })
                .finally(() => {
                  isRefreshing = false;
                });
            }

            return new Promise((resolve, reject) => {
              failedRequestQueue.push({
                onSuccess: (token: string) => {
                  originalConfig.headers["Authorization"] = `Bearer ${token}`;

                  resolve(api(originalConfig));
                },
                onFailure: (err: AxiosError) => {
                  reject(err);
                  signOut();
                },
              });
            });
          } else {
            if (
              error.response.data?.message === "access authorization denied"
            ) {
              signOut();
            } else {
              throw error;
            }
          }
        } else {
          //if there is any other kind of error throw it
          throw error;
        }
      }
    );
  }, []);

  //when this code execute then get that token in local storage
  //browser to set first time in headers of request the token
  //for authenticated that user and make data a
  //represents the all user information received by the api
  //and update we main state user
  useEffect(() => {
    const autoLoad = async () => {
      const token = secureLocalStorage.getItem("@UXDOCNEW2:token");
      const data = secureLocalStorage.getItem("@UXDOCNEW2:data");

      if (token && data) {
        api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        setData(JSON.parse(String(data)));
      }

      if (token) {
        try {
          await api.get("profile");
          setTypeUser("Paid");
        } catch (error) {
          signOut();
        }
      }
    };

    autoLoad();
  }, []);

  //when we need to define if we have an admin user of the ux doc team
  //and before we could block some funcionalities
  useEffect(() => {
    if (data) {
      setUserStripe(data?.payment_method?.subscription_id);
      if (data?.is_block_editor) {
        setPermissionType("editor");
      } else {
        setPermissionType("normal");
      }
    }

    secureLocalStorage.setItem("@UXDOCNEW2:data", JSON.stringify(data));
  }, [data]);

  //sign in user callback
  //post to new session api for we api and pass words key
  //as email and password and update we main state user
  const signIn = useCallback(
    async (email, password, organizationMode) => {
      if (organizationMode) {
        try {
          setLoading(true);
          const response = await api.post("sessions/organization", {
            email,
            password,
            organization_name: "lbca",
          });

          const {
            token,
            user,
            address,
            contact,
            people,
            tokenFirebase,
            payment_method,
            is_block_editor,
            organization,
            organization_theme,
            account_questions,
          } = response.data as IData;

          navigate("/");

          //set new token to local storage
          secureLocalStorage.setItem("@UXDOCNEW2:token", token);
          secureLocalStorage.setItem(
            "@UXDOCNEW2:data",
            JSON.stringify(response.data)
          );
          //set in headers of request authorization bearer token
          api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
          // update with data of that user
          setData({
            user,
            address,
            contact,
            people,
            token,
            tokenFirebase,
            payment_method,
            is_block_editor,
            organization,
            organization_theme,
            account_questions,
          });

          //but now which kind user is it?
          //if is active then is premium user
          if (payment_method.is_active) {
            setTypeUser("Paid");
          } else if (!payment_method.subscription_id) {
            //but if dont have anyone subscription
            //then user new and define as user not paid
            setTypeUser("Unpaid");
          }
          //when user was track one subscription and user not paid
          //we define this user as suspended user
          if (!payment_method.is_active && payment_method.subscription_id) {
            setTypeUser("Suspended");
          }

          setLoadingTypeUser(false);
          setLoading(false);

          //Login action record function
          if (!response.data?.user?.email.match("@bitsacademy.com.br")) {
            handleLoggedUserAction(response.data);
          }
        } catch (error) {
          setLoadingTypeUser(false);
          setLoading(false);
          throw error;
        }
      } else {
        try {
          const response = await api.post("sessions", {
            email,
            password,
          });

          const {
            token,
            user,
            address,
            contact,
            people,
            tokenFirebase,
            payment_method,
            is_block_editor,
            organization,
            organization_theme,
            account_questions,
          } = response.data as IData;

          /* navigate("/"); */

          //set new token to local storage
          secureLocalStorage.setItem("@UXDOCNEW2:token", token);
          secureLocalStorage.setItem(
            "@UXDOCNEW2:data",
            JSON.stringify(response.data)
          );
          //set in headers of request authorization bearer token
          api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
          // update with data of that user
          setData({
            user,
            address,
            contact,
            people,
            token,
            tokenFirebase,
            payment_method,
            is_block_editor,
            organization,
            organization_theme,
            account_questions,
          });

          //but now which kind user is it?
          //if is active then is premium user
          if (payment_method.is_active) {
            setTypeUser("Paid");
          } else if (!payment_method.subscription_id) {
            //but if dont have anyone subscription
            //then user new and define as user not paid
            setTypeUser("Unpaid");
          }
          //when user was track one subscription and user not paid
          //we define this user as suspended user
          if (!payment_method.is_active && payment_method.subscription_id) {
            setTypeUser("Suspended");
          }

          setLoadingTypeUser(false);

          //Login action record function
          if (!response.data?.user?.email.match("@bitsacademy.com.br")) {
            handleLoggedUserAction(response.data);
          }

          localStorage.setItem(
            "UXDOC:2FA",
            JSON.stringify({ notification: true })
          );

          setNotificationsTwoFactor(true);
        } catch (error) {
          throw error;
        }
      }
    },
    [navigate, handleLoggedUserAction]
  );

  //sign out callback
  //only remove it token and values of localstorage login token or data
  //we put these fields empty in localstorage
  //and nullify main state user
  const signOut = useCallback(async () => {
    secureLocalStorage.removeItem("@UXDOCNEW2:token");
    secureLocalStorage.removeItem("@UXDOCNEW2:data");

    if (data?.organization?.name) {
      navigate(`/${data?.organization?.name}`);
    } else {
      navigate("/");
    }

    setData(null);
  }, [data]);

  return (
    <AuthContext.Provider
      value={{
        data,
        loading,
        typeUser,
        signIn,
        signOut,
        createdAccount,
        setCreatedAccount,
        loadingTypeUser,
        userStripe,
        modifyProfile,
        setModifyProfile,
        permissionType,
        setPermissionType,
        setData,
        organization,
        setOrganization,
        notificationTwoFactor,
        setNotificationsTwoFactor,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

// creating hook

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth must be used with an AuthProvider");
  }

  return context;
}

export { AuthProvider, useAuth };
