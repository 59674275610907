import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../../../../services/api";
import { ApprovalProject } from "../../types/ApprovalProject";
import {
  CancelProjectButton,
  ConfirmButton,
  FlowContainer,
  Header,
  LoadingContainer,
  THead,
  Table,
  TableContainer,
  TableData,
  TableHead,
  TableRow,
  Title
} from "./styles";
import { Box, Stack } from "@mui/material";
import { BiCheckCircle, BiChevronLeft, BiInfoCircle } from "react-icons/bi";
import { ContractAnimation } from "../../../../../shared/components/ContractAnimation";
import { Grid } from "@material-ui/core";
import Timeline from "./Timeline";
import StepsDetails from "./StepDetails";
import { RiCloseCircleLine } from "react-icons/ri";
import { MdBlockFlipped, MdOutlineRefresh } from "react-icons/md";
import { ToastContainer, toast } from "react-toastify";
import { Container, FullContent } from "../../styles";
import CancelProject from "../CancelProject";

const statuses = {
  PENDING: "Pendente",
  APPROVED: "Aprovado",
  REFUSED: "Rejeitado",
  CANCELED: "Cancelado"
};

const getIcon = status => {
  switch (status) {
    case "APPROVED":
      return <BiCheckCircle color={"green"} fontSize={"24px"} />;
    case "PENDING":
      return <BiInfoCircle color={"orange"} fontSize={"24px"} />;
    case "REFUSED":
      return <RiCloseCircleLine color={"#DE2D2D"} fontSize={"24px"} />;
    case "CANCELED":
      return <MdBlockFlipped color={"#999C9F"} fontSize={"24px"} />;
    default:
      return;
  }
};

const ProjectDetails: React.FC = () => {
  const params = useParams<{ id: string }>();
  const [loading, setLoading] = useState(true);
  const [project, setProject] = useState<ApprovalProject>();
  const [cancelProjectOpen, setCancelProjectOpen] = useState(false);
  const navigate = useNavigate();

  const fetchSteps = useCallback(async () => {
    setLoading(true);
    const response = await api.get(`/approval-projects/${params.id}`);
    const project = response.data as ApprovalProject;
    // sort by creation date
    project.approval_flows.sort(
      (a, b) =>
        new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
    );
    project.approval_flows.forEach(flow => {
      flow.steps.sort((a, b) => a.order - b.order);
    });
    setProject(project);
    setTimeout(() => {
      setLoading(false);
    }, 300);
  }, [params.id]);

  useEffect(() => {
    fetchSteps();
  }, [fetchSteps]);

  const back = () => {
    navigate("/approvalflow/my-projects");
  };

  const cancelProject = async () => {
    try {
      setCancelProjectOpen(false);
      setLoading(true);
      const response = await api.patch(
        `/approval-projects/${params.id}/cancel`
      );
      if (response.status === 200) {
        toast.success("Projeto cancelado com sucesso");
        setProject({ ...project, status: "CANCELED" });
      }
    } catch (error) {
      console.error(error);
      toast.error("Erro ao cancelar projeto");
    } finally {
      setLoading(false);
    }
  };

  const getProjectStatus = (project: ApprovalProject) => {
    if (project.current_step_status === "REFUSED") {
      return "Recusado";
    } else {
      return statuses[project.status];
    }
  };

  return (
    <FullContent>
      <ToastContainer />
      <Container>
        <FlowContainer>
          {!loading && (
            <Header>
              <Stack direction="row" alignItems={"center"}>
                <Box style={{ cursor: "pointer" }} onClick={back}>
                  <BiChevronLeft fontSize={"24px"} />
                </Box>
                <Title>Fluxo de aprovação</Title>
              </Stack>
              <Box onClick={() => fetchSteps()} style={{ cursor: "pointer" }}>
                <MdOutlineRefresh
                  fontSize={"24px"}
                  style={{ color: "#999c9f" }}
                />
              </Box>
            </Header>
          )}

          {loading && (
            <LoadingContainer>
              <ContractAnimation />
            </LoadingContainer>
          )}

          {!loading && (
            <TableContainer>
              <Table>
                <THead>
                  <tr>
                    <TableHead style={{ width: "48.8%" }}>
                      Nome do projeto
                    </TableHead>
                    <TableHead>Início</TableHead>
                    <TableHead>Fluxo</TableHead>
                    <TableHead>Aprovação planejada</TableHead>
                    <TableHead>Status</TableHead>
                  </tr>
                </THead>
                <tbody>
                  <TableRow>
                    <TableData>{project.name}</TableData>
                    <TableData>
                      {new Date(project.started_at).toLocaleDateString()}
                    </TableData>
                    <TableData>{project.flow_name}</TableData>
                    <TableData>
                      {new Date(
                        project.planned_approval_date
                      ).toLocaleDateString()}
                    </TableData>
                    <TableData>
                      <Stack direction={"row"} alignItems={"center"} gap={1}>
                        {getIcon(
                          project.current_step_status === "REFUSED"
                            ? project.current_step_status
                            : project.status
                        )}
                        {getProjectStatus(project)}
                      </Stack>
                    </TableData>
                  </TableRow>
                </tbody>
              </Table>
            </TableContainer>
          )}

          {!loading && (
            <Grid
              container
              spacing={2}
              style={{ maxHeight: "calc(100vh - 300px)", position: "relative" }}
            >
              <Grid item xs={6} style={{ overflow: "auto", maxHeight: "100%" }}>
                <StepsDetails project={project} />
              </Grid>
              <Grid item xs={6} style={{ overflow: "auto", maxHeight: "120%" }}>
                {project.approval_flows.map((flow, index) => (
                  <Timeline
                    key={flow.id}
                    flow={flow}
                    currentStepId={project.current_step_id}
                    isCurrentFlow={index + 1 === project.approval_flows.length}
                    isFirstFlow={index === 0}
                    project={project}
                    refreshTimeline={fetchSteps}
                  />
                ))}
              </Grid>
            </Grid>
          )}

          {!loading && (
            <Stack direction={"row"} alignItems={"center"} spacing={1}>
              <ConfirmButton onClick={back}>Voltar</ConfirmButton>
              {project.status === "PENDING" && (
                <CancelProjectButton onClick={() => setCancelProjectOpen(true)}>
                  Cancelar projeto
                </CancelProjectButton>
              )}
            </Stack>
          )}

          {cancelProjectOpen && (
            <CancelProject
              open={cancelProjectOpen}
              onClose={() => setCancelProjectOpen(false)}
              onConfirm={cancelProject}
            />
          )}
        </FlowContainer>
      </Container>
    </FullContent>
  );
};
export default ProjectDetails;
