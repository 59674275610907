import { Dialog, DialogTitle } from "@mui/material";
import {
  CancelButton,
  Container,
  ContainerButton,
  LoadingContainer,
  SaveButton,
  Subtitle
} from "./styles";
import { IconCloseWrapper } from "../../styles";
import { GrClose } from "react-icons/gr";
import api from "../../../../../services/api";
import { toast } from "react-toastify";
import { useState } from "react";
import { ContractAnimation } from "../../../../../shared/components/ContractAnimation";
import { PuffLoader } from "react-spinners";
type Props = {
  open: boolean;
  onClose: (refresh?: boolean) => void;
  flow_id: string;
};

const DeleteFlow: React.FC<Props> = ({ open, onClose, flow_id }: Props) => {
  const [loading, setLoading] = useState(false);
  const onSubmit = async () => {
    try {
      setLoading(true);
      const response = await api.delete(`/approval-flows/${flow_id}`);
      onClose(true);
      toast.success("Fluxo excluído com sucesso");
    } catch (error) {
      toast.error("Erro ao excluir fluxo");
      console.error(error);
    }
  };
  return (
    <>
      <Dialog onClose={onClose} open={open} maxWidth={"md"}>
        <DialogTitle style={{ fontWeight: 700, fontSize: "16px" }}>
          Excluir fluxo
        </DialogTitle>

        <IconCloseWrapper onClick={() => onClose()}>
          <GrClose />
        </IconCloseWrapper>

        <Container>
          <Subtitle>Tem certeza que você quer excluir o fluxo?</Subtitle>
          <ContainerButton>
            <CancelButton onClick={() => onClose()}>Não</CancelButton>
            <SaveButton onClick={onSubmit}>
              {(loading && <PuffLoader color="#FFF" size="20px" />) || "Sim"}
            </SaveButton>
          </ContainerButton>
        </Container>
      </Dialog>
    </>
  );
};

export default DeleteFlow;
