// in this case we have one component for simulate complex formats for text
// and with work it canvas together then we created one rich text with quill js
// to do job of complex formatted styles and finally when box of text closed
// and we transform the box with rich text to image
// we use a rectangle from Konva as text box and your transformer for resize

//the text box opens after a callback on double clicking on the konva rectangle
import html2canvas from "html2canvas";
import Konva from "konva";
import { cloneDeep } from "lodash";
import * as QuillJs from "quill";

import React, {
  Fragment,
  MutableRefObject,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Image as ImageKonva, Rect, Transformer } from "react-konva";
import { Html } from "react-konva-utils";
import ReactQuill, { Quill } from "react-quill";
import useImage from "use-image";
import * as QuillText from "quill";
import { ButtonAccept, ButtonCustom } from "./styles";
import { quillSizeTypes } from "./constants/quillSizeTypes";
import { KonvaEventObject } from "konva/lib/Node";
import { IRenderedObject } from "../../../../../dtos/RenderedObject";
import ButtonIA from "../../ButtonIA";
import api from "../../../../../services/api";
import ReactLoading from "react-loading";
import { toast } from "react-toastify";
import { Skeleton } from "@mui/material";
// import Delta from "quill-delta/lib/delta";

//set up interfaces
interface SelectionProps {
  index: number;
  length: number;
}

interface RichTextImageProps {
  shapeProps?: any;
  selectedObject?: Konva.Shape;
  setSelectedObject?: any;
  addToHistory?: any;
  currentMultipleSelection?: any;
  setObjectScreen?: any;
  transformerSelectedObject?: any;
  quillRef?: MutableRefObject<ReactQuill>;
  requestUpdateText?: () => void;
  textRich?: MutableRefObject<string>;
  isEditing?: "closed" | "blocked" | "open";
  setIsEditing: React.Dispatch<
    React.SetStateAction<"closed" | "blocked" | "open">
  >;
  blockingKeyboard: boolean;
  setBlockingKeyboard: React.Dispatch<React.SetStateAction<boolean>>;
  templateStartedShared: boolean;
  setTemplateStartedShared: React.Dispatch<React.SetStateAction<boolean>>;
  oldContentsState: null | QuillText.DeltaStatic;
  setOldContentsState: null | React.Dispatch<
    React.SetStateAction<QuillText.DeltaStatic>
  >;
  newContentsState: null | QuillText.DeltaStatic;
  setNewContentsState: null | React.Dispatch<
    React.SetStateAction<QuillText.DeltaStatic>
  >;
  selectionCursor: SelectionProps;
  setSelectionCursor: React.Dispatch<React.SetStateAction<SelectionProps>>;
  findDiff: () => void;
  buttonRef: React.MutableRefObject<HTMLButtonElement>;
  isEditingQuill: boolean;
  setIsEditingQuill: React.Dispatch<React.SetStateAction<boolean>>;
  newDelta: null | QuillText.DeltaStatic;
  setNewDelta: React.Dispatch<React.SetStateAction<QuillText.DeltaStatic>>;
  newChanges: string;
  setNewChanges: React.Dispatch<React.SetStateAction<string>>;
  oldContentsRef?: null | React.MutableRefObject<QuillText.DeltaStatic>;
  newContentsRef?: null | React.MutableRefObject<QuillText.DeltaStatic>;
  getQuillCursorRef?: null | React.MutableRefObject<SelectionProps>;
  isNewTextRef?: React.MutableRefObject<boolean>;
  isTypingQuillRef?: React.MutableRefObject<boolean>;
  quillRefComponent?: React.MutableRefObject<ReactQuill>;
  whichUserEdited?: React.MutableRefObject<string>;
  nameOfCurrentUser?: React.MutableRefObject<string>;
  interceptModifications?: React.MutableRefObject<boolean>;
  textIsChanged: React.MutableRefObject<boolean>;
  blockRenderImage?: React.MutableRefObject<boolean>;
  blockBackspace: React.MutableRefObject<boolean>;
  hasGroupShared?: React.MutableRefObject<boolean>;
  whichKeyPress?: React.MutableRefObject<string>;
  selectionFormatText?: React.MutableRefObject<QuillText.StringMap>;
  disableFocusQuill?: boolean;
  setDisableFocusQuill?: React.Dispatch<React.SetStateAction<boolean>>;
  hasColorBlock?: React.MutableRefObject<boolean>;
  rectangule?: React.MutableRefObject<Konva.Rect>;
  blockDblClick?: boolean;
  idPage?: number;
  setBlockDblClick?: React.Dispatch<React.SetStateAction<boolean>>;
  handleRemoveElementGroup: (id: string) => void;
  currentQuillFormats: QuillText.StringMap;
  setCurrentQuillFormats: React.Dispatch<
    React.SetStateAction<QuillText.StringMap>
  >;
  selectionQuill: QuillText.RangeStatic;
  setSelectionQuill: React.Dispatch<
    React.SetStateAction<QuillText.RangeStatic>
  >;
  handleOpenAcceptDiff?: () => void;
  handleOpenDeclineDiff?: () => void;
  pendingSave?: React.MutableRefObject<boolean>;
  onChange: (newAttrs: IRenderedObject) => void;
  setPageObject?: React.Dispatch<React.SetStateAction<number>>;
  isDraggingObject?: boolean;
  setIsDraggingObject?: React.Dispatch<React.SetStateAction<boolean>>;
}

const RichTextImage = ({
  shapeProps,
  selectedObject,
  setSelectedObject,
  addToHistory,
  currentMultipleSelection,
  // setObjectScreen,
  // quillRef,
  isEditing,
  setIsEditing,
  setBlockingKeyboard,
  newContentsRef,
  oldContentsRef,
  isNewTextRef,
  isTypingQuillRef,
  quillRefComponent,
  whichUserEdited,
  nameOfCurrentUser,
  interceptModifications,
  textIsChanged,
  hasGroupShared,
  whichKeyPress,
  selectionFormatText,
  disableFocusQuill,
  hasColorBlock,
  rectangule,
  setBlockDblClick,
  idPage,
  handleRemoveElementGroup,
  currentQuillFormats,
  setCurrentQuillFormats,
  selectionQuill,
  setSelectionQuill,
  handleOpenAcceptDiff,
  handleOpenDeclineDiff,
  pendingSave,
  onChange,
  setPageObject,
  isDraggingObject,
  setIsDraggingObject,
}: RichTextImageProps) => {
  let fontFamilyArr = [
    "Open Sans",
    "Roboto",
    "Lato",
    "Montserrat",
    "Verdana",
    "Calibri",
    "Arial",
    "Tahoma",
    "Segoe UI",
    "IBM Plex Serif",
    "Merriweather",
    "Source Serif Pro",
    "Roboto Serif",
    "Lora",
    "Bitter",
    "Faustina",
    "Source Sans Pro",
    "Raleway",
    "Nunito Sans",
    "Poppins",
    "Fira Sans",
    "Archivo",
    "Asap",
    "Times New Roman",
  ];

  const [wasEditing, setWasEditing] = useState(false);
  const [isHideButton, setIsHideButton] = useState(false);
  const [isIA, setIsIA] = useState(false);
  const [generatingIA, setGeneratingIA] = useState(false);
  // const refHTMLDiv = useRef<HTMLDivElement>(null);

  // const [currentPositionObject, setCurrentPositionObject] = useState({
  //   x: 0,
  //   y: 0,
  // });

  let Size = Quill.import("attributors/style/size");
  let fonts = Quill.import("attributors/style/font");
  fonts.whitelist = fontFamilyArr;

  Quill.register(fonts, true);

  Quill.register(Size, true);

  //config which size we show to the users
  Size.whitelist = quillSizeTypes;
  const modules = React.useMemo(
    () => ({
      toolbar: false,

      //when use paste anything into quill js
      clipboard: {
        matchers: [
          [
            Node.ELEMENT_NODE,
            function (_, delta) {
              return delta.compose(
                new Delta().retain(delta.length(), {
                  background: false,
                })
              );
            },
          ],
        ],
      },
      //to watch delete key of quill js are avaiable on editor
      keyboard: {
        bindings: {
          list: {
            key: "Delete",
            context: {
              format: ["bold"],
            },
            handler: function (range, context) {
              if (hasGroupShared.current) {
                return false;
              } else {
                return true;
              }
            },
          },
        },
      },
    }),
    [hasGroupShared]
  );
  //define delta of quill js
  let Delta = Quill.import("delta");
  // formats of rich text register
  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "color",
    "align",
    "lineheight",
  ];

  //set up states
  const [isSelected, setIsSelected] = useState(false);
  const [isEditingText, setIsEditingText] = useState(false);
  const [image] = useImage(shapeProps.src, "anonymous");
  const shapeRef = useRef<Konva.Image>();
  const trRef = useRef<Konva.Transformer>();
  const [hoverSelectedObject, setHoverSelectedObject] = useState("");
  // const myWidth = useRef(shapeProps.width);
  const myHeight = useRef(shapeProps.height);
  const myPositionX = useRef(shapeProps.x);
  const myPositionY = useRef(shapeProps.y);
  const wasSelection = useRef(false);
  const myOwnText = useRef(null);
  const rectModificationsRef = useRef<Konva.Rect>(null);
  const [hasModificationsRect, setHasModificationsRect] = useState(false);
  const [positionX, setPositionX] = useState(0);
  const [widthText, setWidthText] = useState(shapeProps.width);
  const [heightText, setheightText] = useState(shapeProps.height);

  const TextRefIA = useRef({ text: "", selection: { index: 0, length: 0 } });

  const [currentFont, setCurrentFont] = useState("");
  const [blockAnchor, setBlockAnchor] = useState(false);
  const [hiddenAnchor, setHiddenAnchor] = useState(false);

  const [shiftPressed, setShiftPressed] = useState(false);

  const setLineHeight = useCallback(
    (value: string) => {
      const quill = quillRefComponent.current.getEditor();
      const length = quill.getLength();
      setTimeout(() => {
        quill.formatText(0, length, "lineheight", value);
      }, 0);
    },
    [quillRefComponent]
  );

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Shift") {
        setShiftPressed(true);
      }
    };

    const handleKeyUp = (event) => {
      if (event.key === "Shift") {
        setShiftPressed(false);
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyUp);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  useEffect(() => {
    if (isEditing === "open") {
      if (currentMultipleSelection?.current) {
        currentMultipleSelection?.current?.setNodes([]);
      }
    }
  }, [isEditing]);

  //some registers for quill enable formats
  //specific object into canvas registers
  let Parchment = Quill.import("parchment");
  let lineHeightConfig = {
    scope: Parchment.Scope.BLOCK,
    whitelist: ["1.0", "1.2", "1.5", "2.0", "2.5", "3.0"],
  };
  let lineHeightClass = new Parchment.Attributor.Class(
    "lineheight",
    "ql-line-height",
    lineHeightConfig
  );
  let lineHeightStyle = new Parchment.Attributor.Style(
    "lineheight",
    "line-height",
    lineHeightConfig
  );

  Parchment.register(lineHeightClass);
  Parchment.register(lineHeightStyle);

  const handleIA = async (prompt: "removeHardWords" | "fixGrammar") => {
    quillRefComponent.current.focus();
    const body = {
      type_prompt: prompt === "fixGrammar" ? "grammar" : "ux-writing",
      prompt: TextRefIA.current.text,
    };

    const positionCoordenate = cloneDeep(TextRefIA.current);

    if (TextRefIA.current.selection.length === 0) {
      toast.error("Selecione o texto que deseja modificar.");
      setIsIA(false);
      return;
    }

    try {
      setIsIA(false);
      // disable mouse events and change some styles while quill are is loading
      quillRefComponent.current.getEditor().root.style.pointerEvents = "none";
      quillRefComponent.current.getEditor().root.style.opacity = "0.05";
      quillRefComponent.current.getEditor().root.style.cursor = "not-allowed";

      setGeneratingIA(true);
      const response = await api.post("prompt-ai", body);
      //then replace selection text to new text by ia generating
      const quill = quillRefComponent.current.getEditor();
      //storage formats of quill text selection
      const formats = quillRefComponent.current
        .getEditor()
        .getFormat(positionCoordenate.selection);
      //get text of ai generator to replace inside quill js
      const newText = response.data.response;
      // removing selected text
      quill.deleteText(
        positionCoordenate.selection.index,
        positionCoordenate.selection.length
      );
      //insert new text into position of selection removed
      quill.insertText(positionCoordenate.selection.index, newText);
      // update selection for new text
      quill.setSelection(positionCoordenate.selection.index, newText.length);
      setGeneratingIA(false);
      //We added the formats to ensure that text formats won't be lost.
      quill.formatText(
        positionCoordenate.selection.index,
        newText.length,
        formats
      );
      // reset quill settings
      quillRefComponent.current.getEditor().root.style.pointerEvents = "";
      quillRefComponent.current.getEditor().root.style.opacity = "1";
      quillRefComponent.current.getEditor().root.style.cursor = "text";
      toast.success("Texto modificado com sucesso.");
    } catch (err) {
      setGeneratingIA(false);
      setIsIA(false);
      quillRefComponent.current.getEditor().root.style.pointerEvents = "";
      quillRefComponent.current.getEditor().root.style.opacity = "1";
      quillRefComponent.current.getEditor().root.style.cursor = "text";
      toast.error("Tente novamente mais tarde");
    } finally {
      quillRefComponent.current.getEditor().root.style.pointerEvents = ""; // Em caso de erro, garanta que os eventos de mouse sejam reativados
    }
  };

  useEffect(() => {
    //if not have editing text or selected object difference
    //between the richtext disabled onblur function
    if (!isEditingText || !selectedObject?.attrs?.text || !selectedObject) {
      isTypingQuillRef.current = false;
    }
  }, [isEditingText, selectedObject]);

  //catch the initial delta for each object selected
  useEffect(() => {
    if (isEditingText) {
      //make sure that object have this something to compare
      //if have save to ref for findDiff function
      if (selectedObject?.attrs?.initialDelta) {
        isNewTextRef.current = false;
        let newDeltaOps = new Delta(selectedObject?.attrs?.initialDelta);
        oldContentsRef.current = newDeltaOps;
      } else {
        //if he don`t have, create empty object
        selectedObject?.setAttrs({
          initialDelta: "",
        });
        //ensuring this is new rich text
        isNewTextRef.current = true;
        // console.error(selectedObject.attrs, "eai criou?");
      }
    }
  }, [isEditingText, selectedObject]);

  // this useEffect closes the editor
  useEffect(() => {
    (async () => {
      if (wasEditing) {
        setSelectedObject(null);
        return;
      }

      //IF we are selecting a richtext
      if (selectedObject?.attrs?.object === "richTextImage") {
        currentMultipleSelection.current?.setAttrs({
          borderStroke: "#663399",
        });
        //Apllying selection to specific richText
        if (selectedObject === shapeRef?.current) {
          setIsSelected(true);
          setIsEditing("closed");
          currentMultipleSelection.current?.setNodes([]);
        } else {
          setIsSelected(false);
        }
        // We are using isEditingText to only get into
        // The RichText wich is the previous
        if (isEditingText) {
          await handleRenderImage();
          //closing previous quillText
          setIsEditingText(false);
          currentMultipleSelection?.current?.setNodes([]);
          return;
        }
        currentMultipleSelection?.current?.setNodes([]);
        return;
      }

      if (selectedObject === null) {
        setIsSelected(false);
        // Transform the text in an image
        if (isEditingText) {
          await handleRenderImage();
        }
        setIsEditing("closed");
        setIsEditingText(false);
        setIsSelected(false);
        return;
      }
      // From here we know that the selectedObject is not null neither a RichText
      // So we just render if there is any RichtText opened and close everything

      if (isSelected) {
        setIsSelected(false);
      }

      if (isEditingText) {
        await handleRenderImage();
        setIsEditing("closed");
        setIsEditingText(false);
        setIsSelected(false);
      }
    })();
  }, [selectedObject, shapeRef, wasEditing]);

  let selectionArea = useRef({
    length: 0,
    index: 0,
  });

  //all this refs for algorithm of compare texts
  // const mySelectionText = useRef("");
  // const enableKeyWhenDiff = useRef(false);
  //all this refs for algorithm of compare texts
  const mySelectionText = useRef("");
  const enableKeyWhenDiff = useRef(false);
  const beforeFormatsFontSize = useRef(null);
  const backspaceSelect = useRef(false);
  const numberKeyboardCode = useRef("");
  const specificPrevFormat = useRef<QuillText.StringMap>(null);

  let rangeQuill: QuillText.RangeStatic;

  //reset selection of quill js
  useEffect(() => {
    if (!selectedObject) {
      selectionArea.current = {
        index: 0,
        length: 0,
      };
    }
  }, [selectedObject]);

  //listener for detect which letter
  //was pressed on keyboard event
  useEffect(() => {
    document.addEventListener("keydown", detectKeyDown, true);

    return () => document.removeEventListener("keydown", detectKeyDown);
  }, []);

  //this code below prevent drag and drop
  //text on quill when shared document only
  //prevent bug of review text
  useEffect(() => {
    if (isEditingText) {
      if (hasGroupShared.current) {
        document.addEventListener("drop", (event) => {
          event?.preventDefault();
          return false;
        });
      }
    }
  }, [isEditingText, hasGroupShared.current]);

  //add these key for used on quilljs
  const detectKeyDown = useCallback(
    (event: KeyboardEvent) => {
      whichKeyPress.current = event.key;
      numberKeyboardCode.current = event.code;
    },
    [whichKeyPress, numberKeyboardCode]
  );

  let formatsVar: any = {};

  useEffect(() => {
    if (quillRefComponent.current) {
      quillRefComponent.current
        .getEditor()
        .on("selection-change", (range: QuillText.RangeStatic) => {
          // setIsIA(false);
          rangeQuill = range;

          if (range) {
            const getText = quillRefComponent.current
              ?.getEditor()
              ?.getText(range?.index, range?.length);

            if (!generatingIA) {
              TextRefIA.current = {
                text: getText,
                selection: {
                  index: range.index,
                  length: range.length,
                },
              };
            }
          }

          if (range) {
            setSelectionQuill(range);

            const getFormat = quillRefComponent.current
              ?.getEditor()
              ?.getFormat(range);

            if (getFormat) {
              setCurrentQuillFormats(getFormat);
            }
          }

          if (range?.length > 0) {
            const myformats = quillRefComponent.current
              ?.getEditor()
              ?.getFormat();
            if (myformats.font) {
              setCurrentFont(myformats.font);
            }
          }

          if (hasGroupShared.current) {
            if (range?.length > 0) {
              const formatsSelection = quillRefComponent.current
                .getEditor()
                .getContents(range.index, range.length);

              let breakFunction = false;

              formatsSelection.ops.forEach((op) => {
                if (op?.attributes?.color == "#9945ee") {
                  if (!breakFunction) {
                    hasColorBlock.current = true;
                    breakFunction = true;
                  }
                } else if (op?.attributes?.color == "#de2d2d") {
                  hasColorBlock.current = true;
                  breakFunction = true;
                } else {
                  hasColorBlock.current = false;
                  breakFunction = false;
                }
              });
            }
          }

          if (hasGroupShared.current) {
            //get selection of selection word by user
            selectionArea.current.length = range?.length;
            selectionArea.current.index = range?.index;
            //verify if don`t empty because if length === 0 then
            // selection this transform to empty
            let whichLetters = quillRefComponent.current
              .getEditor()
              .getText(range?.index, range?.length);
            //if exists words in variable so save it
            if (whichLetters && !enableKeyWhenDiff.current) {
              mySelectionText.current = quillRefComponent.current
                .getEditor()
                .getText(range?.index, range?.length);
            }
            //ensuring that the delete key is disabled
            quillRefComponent.current
              .getEditor()
              .keyboard.addBinding({ key: "delete" }, function () {
                //nothing to do
              });
            quillRefComponent.current
              .getEditor()
              .keyboard.addBinding({ key: "Backspace" }, function () {
                whichKeyPress.current = "Backspace";
              });
            const formatsVar = quillRefComponent.current
              ?.getEditor()
              ?.getFormat();
            if (formatsVar.color !== "#9945ee") {
              enableKeyWhenDiff.current = false;
            }
            specificPrevFormat.current = quillRefComponent.current
              ?.getEditor()
              ?.getFormat(selectionArea.current.index - 1, 1);
            if (range?.length > 1) {
              const formatSelection = quillRefComponent.current
                ?.getEditor()
                ?.getFormat(range.index, range.length);
              selectionFormatText.current = formatSelection;
              wasSelection.current = true;
            } else {
              wasSelection.current = false;
            }
          }
        });

      //now verify changes of editor quill
      quillRefComponent.current.getEditor().on("editor-change", () => {
        if (rangeQuill) {
          const getFormats = quillRefComponent.current
            ?.getEditor()
            ?.getFormat(rangeQuill);
          setCurrentQuillFormats(getFormats);
        }

        const totalHeight =
          quillRefComponent.current?.getEditor()?.root?.scrollHeight;

        trRef?.current?.height(totalHeight);
        if (hasGroupShared.current) {
          const selectionCurrentCursor = quillRefComponent.current
            .getEditor()
            .getSelection();

          //verify previous formatting
          let currentFormat: QuillText.StringMap = quillRefComponent.current
            ?.getEditor()
            ?.getFormat(selectionCurrentCursor?.index - 1, 1);

          //if this new text then enable keyboard
          // for let him remove it
          if (currentFormat?.color === "#9945ee") {
            enableKeyWhenDiff.current = true;
          } else {
            //case not then prevent default
            // key backspace again
            enableKeyWhenDiff.current = false;
            quillRefComponent.current.getEditor().update();
          }

          let textContentsAfterAnyAction = quillRefComponent.current
            ?.getEditor()
            ?.getFormat();

          if (textContentsAfterAnyAction.size) {
            beforeFormatsFontSize.current = textContentsAfterAnyAction.size;
          }

          if (selectionArea.current.length === 0) {
            //if selection area of cursor is > 0
            //so user use selection then prevent behavior of key
            quillRefComponent.current
              .getEditor()
              .keyboard.addBinding(
                { key: "backspace" },
                function (range: QuillText.RangeStatic) {
                  //verify if have is keyboard enable or not
                  if (enableKeyWhenDiff.current) {
                    let selection = quillRefComponent.current
                      .getEditor()
                      .getSelection();

                    quillRefComponent.current
                      .getEditor()
                      .deleteText(selection.index - 1, 1);

                    //then enable keyboard
                    return true;
                  } else {
                    backspaceSelect.current = true;
                    //so disable keyboard and
                    //if have selection of cursor
                    if (range?.length > 0) {
                      // console.error("caiu no length maior q zero");
                      //just transform that text by deleted text format
                      quillRefComponent.current
                        .getEditor()
                        .formatText(
                          range.index,
                          range.length,
                          "color",
                          "#DE2D2D"
                        );

                      quillRefComponent.current
                        .getEditor()
                        .formatText(range.index, range.length, "strike", true);
                    } else {
                      //if is tap default backspace on just one time
                      //so bring up cursor to new position and keep the text
                      if (range.index <= 1) {
                        let keyboard = quillRefComponent.current
                          ?.getEditor()
                          ?.getModule("keyboard");
                        if (keyboard) {
                          keyboard.bindings["backspace"] = null;
                          keyboard.bindings["8"] = null;
                          keyboard.bindings["delete"] = null;
                          keyboard.bindings["46"] = null;
                        }
                        quillRefComponent.current
                          .getEditor()
                          .formatText(0, 1, "color", "#DE2D2D");

                        quillRefComponent.current
                          .getEditor()
                          .formatText(0, 1, "strike", true);
                        quillRefComponent.current
                          .getEditor()
                          .setSelection(0, 0);
                      } else {
                        let keyboard = quillRefComponent.current
                          ?.getEditor()
                          ?.getModule("keyboard");
                        if (keyboard) {
                          keyboard.bindings["backspace"] = null;
                          keyboard.bindings["8"] = null;
                          keyboard.bindings["delete"] = null;
                          keyboard.bindings["46"] = null;
                        }

                        quillRefComponent.current
                          .getEditor()
                          .setSelection(range.index - 1, range.length);
                        // and lets go formating to red
                        quillRefComponent.current
                          .getEditor()
                          .formatText(
                            range.index === 1 ? 0 : range.index - 1,
                            range?.length > 0 ? range.length : 1,
                            "color",
                            "#DE2D2D"
                          );

                        quillRefComponent.current
                          .getEditor()
                          .formatText(
                            range.index === 1 ? 0 : range.index - 1,
                            range?.length > 0 ? range.length : 1,
                            "strike",
                            true
                          );

                        return null;
                      }
                    }
                  }
                }
              );
          } else {
            if (formatsVar.color === "#9945ee") {
              enableKeyWhenDiff.current = true;
            } else {
              quillRefComponent.current
                .getEditor()
                .keyboard.addBinding({ key: "backspace" }, (range) => {
                  quillRefComponent.current
                    .getEditor()
                    .setSelection(range.index - 1, range.length);
                  // and lets go formating to red
                  quillRefComponent.current
                    .getEditor()
                    .formatText(
                      range.index === 1 ? 0 : range.index - 1,
                      range?.length > 0 ? range.length : 1,
                      "color",
                      "#DE2D2D"
                    );

                  quillRefComponent.current
                    .getEditor()
                    .formatText(
                      range.index === 1 ? 0 : range.index - 1,
                      range?.length > 0 ? range.length : 1,
                      "strike",
                      "true"
                    );
                });
            }
          }
        }

        //if  already was shared this project
        //then enable functions of reviews of texts
      });

      quillRefComponent.current.getEditor()!.root!.style!.padding = "0px";
      quillRefComponent.current.getEditor()!.root!.style!.margin = "0px";
      quillRefComponent.current.getEditor()!.root!.style!.overflow = "hidden";

      isTypingQuillRef.current = true;
      interceptModifications.current = false;

      quillRefComponent.current
        ?.getEditor()
        ?.on("text-change", (delta, oldDelta) => {
          let currentFormat = quillRefComponent.current.getEditor().getFormat();
          const isEmpty = Object.keys(currentFormat).length === 0;
          // verify if next time was text
          let newFormat = selectedObject?.attrs.format.ops[0].attributes;

          if (isEmpty && delta.ops[0]?.insert && !hasGroupShared.current) {
            if (!newFormat) {
              newFormat = {
                size: "12px",
                font: "Roboto",
                color: "#000",
              };
            }

            const lenghText = quillRefComponent.current.getEditor().getLength();

            //disable here empty format, then get old format
            quillRefComponent.current
              .getEditor()
              .formatText(0, lenghText, newFormat);
          }

          if (!disableFocusQuill) {
            quillRefComponent.current.focus();
          }

          wasSelection.current = false;
          let myText = quillRefComponent.current.getEditor().getText();
          let textLength = myText.length;

          // prevent history undo or redo when
          // shared document to prevent bug of mark text changed
          const history = quillRefComponent.current
            .getEditor()
            .getModule("history");
          if (hasGroupShared.current) {
            history.options.maxStack = 0;
          }

          if (hasGroupShared.current && !enableKeyWhenDiff.current) {
            //by having ensure if cursor selections in use
            if (selectionArea?.current?.length > 0) {
              if (selectionArea.current.length + 1 === textLength) {
                //keep formats when delete all text
                if (whichKeyPress.current === "Backspace") {
                }
              }

              //before action, disable this selection
              quillRefComponent.current.getEditor().setSelection({
                index: selectionArea.current.index,
                length: 0,
              });
            }
            //get selection of cursor
            const selectionType = quillRefComponent.current
              .getEditor()
              .getSelection();
            //move to the left cursor variable
            let count = selectionType.index - 1;

            // if the change was an insertion
            delta.forEach((op) => {
              if (op.insert && whichKeyPress.current !== "Backspace") {
                //add new formats to insert text
                if (selectionType.length === 0) {
                  quillRefComponent.current
                    .getEditor()
                    .formatText(count, 1, "color", "#9945EE");

                  quillRefComponent.current
                    .getEditor()
                    .formatText(count, 1, "strike", false);
                }
              }

              // when user deleted something without
              //the backspace detect this text for save it
              if (op.hasOwnProperty("delete")) {
                // textIsChanged.current = true;
                //ensure new letter was a pressed format of insert
                if (
                  mySelectionText.current &&
                  whichKeyPress.current !== "Backspace"
                ) {
                  quillRefComponent.current
                    .getEditor()
                    .formatText(count + 1, 1, "color", "#9945EE");

                  quillRefComponent.current
                    .getEditor()
                    .formatText(count + 1, 1, "strike", false);

                  if (specificPrevFormat.current.color) {
                    quillRefComponent.current
                      .getEditor()
                      .formatText(
                        count,
                        1,
                        "color",
                        specificPrevFormat.current.color
                      );
                  } else {
                    quillRefComponent.current
                      .getEditor()
                      .formatText(count, 1, "color", "#000");
                  }
                }

                if (whichKeyPress.current !== "Backspace") {
                  //brings the selection user
                  quillRefComponent.current.getEditor().setSelection({
                    index: selectionType.index + 1,
                    length: 0,
                  });
                }

                if (whichKeyPress.current === "Backspace") {
                  //to do if backspace
                }

                let currentSelection = quillRefComponent.current
                  .getEditor()
                  .getSelection();

                //length of text was a capture by event of selection
                let lengthText = mySelectionText.current.length;
                //and then apply this text and formats deleted
                //text for keep it
                quillRefComponent.current
                  .getEditor()
                  .insertText(currentSelection.index, mySelectionText.current);

                quillRefComponent.current
                  .getEditor()
                  .formatText(
                    currentSelection.index,
                    lengthText,
                    "color",
                    "#DE2D2D"
                  );
                quillRefComponent.current
                  .getEditor()
                  .formatText(
                    currentSelection.index,
                    lengthText,
                    "strike",
                    true
                  );
                //generate new selection
                quillRefComponent.current
                  .getEditor()
                  .setSelection({ index: currentSelection.index, length: 0 });
                //reset text save in memory variable
                mySelectionText.current = "";
              }
            });
          }

          //save it into new variable to use it others modules this code
          myOwnText.current = cloneDeep(
            quillRefComponent.current?.getEditor().getContents()
          );
          // getting height from quill and aplying to rectangule
          myHeight.current =
            quillRefComponent?.current?.getEditor()?.root?.offsetHeight;

          rectangule?.current?.height(
            quillRefComponent?.current?.getEditor()?.root?.offsetHeight
          );

          setheightText(
            quillRefComponent?.current?.getEditor()?.root?.offsetHeight
          );
          //apply to newContents this actually delta
          newContentsRef.current = myOwnText.current;
        });
    }
  }, [isEditingText]);

  //useEffect used for blocking the keyboard when editor is open
  useEffect(() => {
    if (isEditing === "open") {
      setBlockingKeyboard(true);
    } else if (isEditing === "closed" || isEditing === "blocked") {
      setBlockingKeyboard(false);
    }
  }, [isEditing]);

  // applying transformer to rectangule
  useEffect(() => {
    trRef.current?.setNodes([rectangule.current]);
    trRef.current?.setZIndex(trRef.current.getParent().children.length - 1);
    trRef.current?.getLayer().batchDraw();
  }, [isSelected, rectangule]);

  const handleEdit = useCallback(
    (event: KonvaEventObject<MouseEvent>) => {
      if (wasEditing) return;
      if (selectedObject?.attrs?.id !== hoverSelectedObject) return;

      // getting absolute postion from rectangule to apply on quillEditor.
      let absPostion = event.currentTarget?.getAbsolutePosition();

      if (absPostion) {
        setTimeout(() => {
          if (selectedObject?.attrs?.id !== hoverSelectedObject) return;
          myPositionX.current = absPostion?.x;
          myPositionY.current = absPostion?.y;
          setPositionX(0);
          setIsEditing("open");
          setIsEditingText(true);

          let newFormat = selectedObject?.attrs?.format;

          if (!selectedObject?.attrs?.format?.ops[0]?.attributes?.size) {
            newFormat.ops[0].attributes = {
              ...newFormat.ops[0].attributes,
              size: "12px",
            };
          }

          if (!selectedObject?.attrs?.format?.ops[0]?.attributes?.font) {
            newFormat.ops[0].attributes = {
              ...newFormat.ops[0].attributes,
              font: "Roboto",
            };
          }
          quillRefComponent.current?.getEditor().setContents(newFormat);
          const length = quillRefComponent.current.getEditor().getLength();
          quillRefComponent.current?.getEditor().focus();
          const formats = quillRefComponent.current.getEditor().getFormat();

          setCurrentQuillFormats(formats);

          if (length > 0) {
            quillRefComponent.current
              ?.getEditor()
              .setSelection({ index: length, length: 0 });
          }

          // quillRefComponent.current?.getEditor().focus();
          if (selectedObject?.attrs?.lineHeight) {
            setLineHeight(selectedObject.attrs.lineHeight);
          }
        }, 0);
      }
    },
    [
      selectedObject,
      hoverSelectedObject,
      setIsEditing,
      quillRefComponent,
      setCurrentQuillFormats,
      wasEditing,
      setLineHeight,
    ]
  );

  //this callback to use it when we have generate image to richtext
  const handleRenderImage = useCallback(async () => {
    if (wasEditing) return;

    try {
      setWasEditing(true);
      // if there was any modification in the text editor save it to the history
      if (shapeProps.text !== quillRefComponent.current.getEditor().getText()) {
        addToHistory();
      }
      let myFormatText: QuillText.DeltaStatic = quillRefComponent.current
        ?.getEditor()
        ?.getContents();

      window.devicePixelRatio = 5;
      rectangule.current?.scaleX(1);
      rectangule.current?.scaleY(1);

      if (hasGroupShared.current) {
        const imageContents = quillRefComponent.current
          ?.getEditor()
          ?.getContents();
        textIsChanged.current =
          imageContents?.ops?.filter(
            (op) =>
              op?.attributes?.color === "#9945ee" ||
              op?.attributes?.color === "#de2d2d"
          ).length > 0;
      }

      const canvas = await html2canvas(
        quillRefComponent.current.getEditor().root,
        {
          backgroundColor: null,
          scale: 5,
          height: heightText,
          width: widthText,
          allowTaint: true,
        }
      );

      const webpDataUrl = canvas.toDataURL("image/webp", 1);
      let myText = quillRefComponent.current?.getEditor()?.getText();

      //add to state new modifications as src image, formats,
      // width, height and more...
      if (myFormatText && webpDataUrl) {
        if (!myFormatText?.ops[0]?.attributes?.size) {
          myFormatText.ops[0].attributes = {
            ...myFormatText.ops[0].attributes,
            size: "12px",
          };
        }

        if (!interceptModifications.current && hasGroupShared.current) {
          onChange({
            ...shapeProps,
            scaleX: 1,
            scaleY: 1,
            width: widthText,
            height: heightText,
            x: myPositionX.current,
            y: myPositionY.current,
            src: webpDataUrl,
            text: myText,
            format: myFormatText,
            rotation: shapeProps?.rotation,
            draggable: shapeProps?.draggable,
            idOfUserEdited:
              textIsChanged.current &&
              hasGroupShared.current &&
              !shapeProps.isModify
                ? whichUserEdited.current
                : shapeProps?.idOfUserEdited,
            nameOfUserEdited:
              textIsChanged.current &&
              hasGroupShared.current &&
              !shapeProps.isModify
                ? nameOfCurrentUser.current
                : shapeProps?.nameOfUserEdited,
            isModify:
              textIsChanged.current && hasGroupShared.current ? true : false,
          });
        } else {
          onChange({
            ...shapeProps,
            scaleX: 1,
            scaleY: 1,
            width: widthText,
            height: heightText,
            x: myPositionX.current,
            y: myPositionY.current,
            src: webpDataUrl,
            format: myFormatText,
            rotation: shapeProps?.rotation,
            draggable: shapeProps?.draggable,
            text: myText,
          });
        }

        textIsChanged.current = false;
        pendingSave.current = true;
      }
      setPositionX(0);
      setIsEditing("closed");
      setIsEditingText(false);
      setWasEditing(false);
    } catch (error) {
      setPositionX(0);
      setIsEditing("closed");
      setIsEditingText(false);
      setWasEditing(false);
      
    }
  }, [
    wasEditing,
    shapeProps,
    quillRefComponent,
    rectangule,
    hasGroupShared,
    heightText,
    widthText,
    setIsEditing,
    addToHistory,
    textIsChanged,
    interceptModifications,
    pendingSave,
    onChange,
    whichUserEdited,
    nameOfCurrentUser,
  ]);

  useEffect(() => {
    if (!selectedObject || selectedObject.attrs.object !== "imageRichText") {
      setIsEditing("closed");
    }
  }, [selectedObject]);

  //this one callback will be called when end mouse drag end event
  //to ensure that we are modifying position of object rich text image
  const handleDragEnd = useCallback(
    (event: Konva.KonvaEventObject<MouseEvent>) => {
      setIsDraggingObject(false);
      setHasModificationsRect(false);

      rectangule?.current?.setPosition(event.target.absolutePosition());
      myPositionX.current = event.target.absolutePosition().x;
      myPositionY.current = event.target.absolutePosition().y;

      onChange({
        ...shapeProps,
        x: event.target.absolutePosition().x,
        y: event.target.absolutePosition().y,
      });
    },
    [rectangule, onChange, shapeProps, setIsDraggingObject]
  );

  //here will be the image representing the text
  return (
    <Fragment key={shapeProps.id}>
      <>
        {/* {isSelected && (
          <Html>
            <div
              ref={refHTMLDiv}
              style={{
                width: `${selectedObject?.width()}px`,
                height: `${selectedObject?.height()}px`,
                background: "transparent",
                border: "2px solid red",
                pointerEvents: "none",
                position: "absolute",
                zIndex: "111111111111111111111111",
                top: `${currentPositionObject.y}px`,
                left: `${currentPositionObject.x}px`,
              }}
            ></div>
          </Html>
        )} */}

        {!isEditingText && (
          <ImageKonva
            key={shapeProps.id}
            image={image}
            ref={shapeRef}
            {...shapeProps}
            x={shapeProps.x}
            y={shapeProps.y}
            padding={1}
            onMouseEnter={(event) => {
              if (event.currentTarget.attrs.id) {
                setHoverSelectedObject(event.currentTarget.attrs.id);
              }
              setBlockDblClick(true);
            }}
            // scale={2}
            onMouseLeave={() => {
              setBlockDblClick(false);
            }}
            name="richTextImage"
            onDblClick={(event) => {
              if (wasEditing) {
                setIsEditingText(false);
                setIsEditing("closed");
                setSelectedObject(null);
              } else {
                handleEdit(event);
              }
            }}
            onClick={(event: Konva.KonvaEventObject<MouseEvent>) => {
              setPageObject(event?.currentTarget?.getStage()?.attrs?.id);
              if (wasEditing) {
                setSelectedObject(null);
                return;
              }

              if (isEditingText) {
                setSelectedObject(null);
                setIsEditingText(false);
                return;
              }

              setWidthText(shapeProps.width);
              setheightText(shapeProps.height);

              //checking if the object has multiple active objects
              const isTargetInsideMultipleSelection =
                currentMultipleSelection.current
                  ?.nodes()
                  .includes(event.target);

              if (isTargetInsideMultipleSelection) {
                //then remove selected object
                setSelectedObject(null);

                if (shiftPressed && isEditing !== "open") {
                  handleRemoveElementGroup(event.target.attrs.id);
                  return;
                }
              } else {
                if (wasEditing) {
                  setSelectedObject(null);
                  return;
                }
                //if only one object let empty multiple objects
                //and update with width and height of shapes
                //to transformer for example:
                if (isEditing !== "open" || isEditingText) {
                  setSelectedObject(event.target);
                } else {
                  setSelectedObject(null);
                }
                currentMultipleSelection.current?.setNodes([]);
              }
            }}
            onDragStart={(event) => {
              setPageObject(event?.currentTarget?.getStage()?.attrs?.id);
              // setIsDraggingObject(true);

              if (isEditing === "open" || isEditingText) {
                // handleRenderImage();
                setSelectedObject(null);
                return;
              }

              //checking if the object has multiple active objects
              //always add to history for undo and redo function
              if (
                currentMultipleSelection.current?.nodes().length === 0 ||
                !currentMultipleSelection.current
              ) {
                addToHistory();
              }

              const isTargetInsideMultipleSelection =
                currentMultipleSelection.current
                  ?.nodes()
                  .includes(event.target);

              //if mutipleSelection includes element being dragged
              if (isTargetInsideMultipleSelection) {
                setSelectedObject(null);
              } else {
                setSelectedObject(event.target);
                currentMultipleSelection.current?.setNodes([]);
              }
            }}
            onDragMove={(event: Konva.KonvaEventObject<MouseEvent>) => {
              //set a new position for the rectangle to follow the image
              //checking if the object has multiple active objects

              setPageObject(event?.currentTarget?.getStage()?.attrs?.id);
              setIsDraggingObject(true);

              if (
                currentMultipleSelection.current?.nodes().length === 0 ||
                !currentMultipleSelection.current
              ) {
                rectangule.current?.setAbsolutePosition({
                  ...event.target.getAbsolutePosition(),
                });
              }
            }}
            onDragEnd={handleDragEnd}
          />
        )}
        {/*used only when text has been shared and modified */}
        {shapeProps.isModify && !hasModificationsRect && (
          <Rect
            key={shapeProps.id}
            ref={rectModificationsRef}
            width={2.5}
            height={shapeProps?.height}
            x={shapeProps?.x - 8}
            y={shapeProps?.y}
            fill={"red"}
            onDragMove={(event: Konva.KonvaEventObject<MouseEvent>) => {
              //linking image moviment with transformer
              if (isSelected) {
                shapeRef.current?.setAbsolutePosition({
                  ...event.target?.getAbsolutePosition(),
                });
              }
            }}
            onDragStart={(event) => {
              if (
                currentMultipleSelection.current?.nodes().length === 0 ||
                !currentMultipleSelection.current
              ) {
                addToHistory();
              }
              // setPageObject(event.currentTarget.getStage().attrs.id);

              // setIsDraggingObject(true);
              const isTargetInsideMultipleSelection =
                currentMultipleSelection.current
                  ?.nodes()
                  .includes(event.target);

              //if mutipleSelection includes element being dragged
              if (isTargetInsideMultipleSelection) {
                setSelectedObject(null);
              } else {
                setSelectedObject(shapeRef.current);
                currentMultipleSelection.current?.setNodes([]);
              }
            }}
            onDragEnd={handleDragEnd}
          />
        )}
      </>

      {isSelected && (
        <Transformer
          ref={trRef}
          padding={1}
          borderStroke="#35acf5"
          anchorStroke="#35acf5"
          anchorFill="#35acf5"
          anchorCornerRadius={2}
          rotateEnabled={false}
          enabledAnchors={
            hiddenAnchor
              ? ["middle-left"]
              : isEditingText
              ? ["middle-right", "middle-left"]
              : []
          }
          onTransformStart={(event: Konva.KonvaEventObject<MouseEvent>) => {
            if (
              currentMultipleSelection.current?.nodes().length === 0 ||
              !currentMultipleSelection.current
            ) {
              setIsHideButton(true);
              //addToHistory();
              setIsEditing("open");
              setIsEditingText(true);
            }
          }}
          onTransform={(event: Konva.KonvaEventObject<MouseEvent>) => {
            //Preventing ALT behavior with the transformer
            if (event.evt.altKey) {
              trRef.current?.stopTransform();
              return;
            }

            setBlockAnchor(true);

            if (isEditing === "open") {
              if (currentMultipleSelection?.current) {
                currentMultipleSelection?.current?.setNodes([]);
              }
            }

            //when transform we want to resize quill rich text
            let whichTransform = trRef.current?.getActiveAnchor();

            //manipulate specific anchor left for text
            if (whichTransform === "middle-left") {
              trRef.current?.borderStroke("transparent");
              trRef.current?.enabledAnchors(["middle-left"]);
              quillRefComponent.current.getEditor().root.style.outline =
                "2px solid #35acf5";
              //get width of transformer of rich text inside canvas
              let width = trRef.current.getWidth();
              //get height of rich text inside canvas when changing
              if (event.target.height) {
                event.target.height(
                  quillRefComponent.current.getEditor()?.root?.scrollHeight
                );
              }
              //get position x and y
              let position = event?.target?.getAbsolutePosition();
              myPositionX.current = position.x;
              setPositionX(position.x);

              let myHeight = trRef.current?.getHeight();

              setheightText((oldState) => myHeight);
              // trRef.current.width(width);
              //add to rich text new width
              quillRefComponent.current.getEditor().root.style.width = `${width}px`;
            } else {
              trRef.current?.borderStroke("transparent");
              quillRefComponent.current.getEditor().root.style.outline =
                "2px solid #35acf5";
              //if you are modifying right anchor
              //pass height of rich text to transformer

              let width = trRef.current?.getWidth();

              setheightText(trRef?.current?.height());
              if (event.target.height) {
                event.target.height(
                  quillRefComponent.current?.getEditor()?.root?.scrollHeight
                );
              }
              //update width of rich text with of transformer konva
              quillRefComponent.current.getEditor().root.style.width = `${width}px`;
            }
          }}
          onTransformEnd={(event: Konva.KonvaEventObject<MouseEvent>) => {
            setHiddenAnchor(true);
            setIsHideButton(false);
            setTimeout(() => {
              setHiddenAnchor(false);
            }, 600);
            setBlockAnchor(false);
            let rotation = trRef?.current?.rotation();
            let myWidth = trRef.current?.getWidth();
            let myHeight = trRef.current?.getHeight();

            setWidthText((oldState) => myWidth);
            setheightText((oldState) => myHeight);

            window.devicePixelRatio = 2;
            let myFormats = quillRefComponent?.current
              ?.getEditor()
              ?.getContents();
            // Transforming QuilText to Image and applying
            // modifications to state
            html2canvas(quillRefComponent.current?.getEditor()?.root, {
              backgroundColor: null,
              scale: 5,
              height: heightText,
              width: widthText,
              allowTaint: true,
            })
              .then((canvas) => {
                if (myFormats) {
                  let myImageSrcText = canvas.toDataURL("image/png", 2);
                  rectangule.current?.scaleX(1);
                  rectangule.current?.scaleY(1);

                  onChange({
                    ...shapeProps,
                    scaleX: event.target?.scaleX(),
                    scaleY: event.target?.scaleY(),
                    width: widthText,
                    height: heightText,
                    x: event.target?.absolutePosition()?.x,
                    y: event.target?.absolutePosition()?.y,
                    src: myImageSrcText,
                    format: myFormats,
                    rotation: rotation,
                    // draggable: event.target?.draggable(),
                  });

                  pendingSave.current = true;
                }
              })
              .catch((error) => {
                
              });
          }}
          boundBoxFunc={(oldBox, newBox) => {
            // limit resize
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox;
            }
            return newBox;
          }}
        />
      )}

      {/*this is the quill that we display inside
       the canvas simulating the text */}
      {isEditingText && (
        <Html>
          <div className="quillRefMirrorClass">
            {generatingIA && (
              <div
                style={{
                  position: "absolute",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  // background: "red",
                  width: `${widthText}px`,
                  height: `${heightText}px`,
                  top: `${myPositionY.current}px`,
                  left:
                    positionX === 0
                      ? `${myPositionX.current}px`
                      : `${positionX}px`,
                }}
              >
                <ReactLoading
                  type="spin"
                  color="#663399"
                  height={16}
                  width={16}
                />

                <Skeleton
                  animation="wave"
                  variant="rectangular"
                  sx={{ bgcolor: "#69696916" }}
                  style={{
                    width: `${widthText}px`,
                    height: `${heightText}px`,
                    position: "absolute",
                  }}
                />
              </div>
            )}

            <ReactQuill
              ref={quillRefComponent}
              modules={modules}
              formats={formats}
              id="SAMEQUILL"
              readOnly={generatingIA}
              // className="noselect"
              style={{
                position: "absolute",
                top: `${myPositionY.current}px`,
                left:
                  positionX === 0
                    ? `${myPositionX.current}px`
                    : `${positionX}px`,
                width: `${widthText}px`,
                height: `${heightText}px`,
                padding: "0px",
                background: "rgba(0, 0, 0, 0)",
              }}
              theme="snow"
            />
          </div>
        </Html>
      )}

      {isEditingText && !isHideButton && (
        <Html>
          <ButtonIA
            handleIA={handleIA}
            isActive={isIA}
            setIsActive={setIsIA}
            x={shapeProps.x - 50}
            y={shapeProps.y - 2}
            onClick={() => setIsIA((prev) => !prev)}
          />
        </Html>
      )}

      {isEditingText && shapeProps.isModify && (
        <Html>
          <ButtonAccept
            positionX={myPositionX.current}
            positionY={myPositionY.current}
          >
            <ButtonCustom onClick={handleOpenAcceptDiff}>
              {/* <img src={iconAccept} alt="" /> */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M19.8981 16.545C20.0402 16.6871 20.0402 16.9174 19.8981 17.0595L17.1264 19.8319C17.0582 19.9001 16.9657 19.9385 16.8692 19.9385C16.7727 19.9385 16.6801 19.9002 16.6119 19.8319L15.2257 18.4457C15.0836 18.3037 15.0836 18.0733 15.2257 17.9312C15.3678 17.7891 15.5981 17.7891 15.7402 17.9312L16.8691 19.0601L19.3836 16.5451C19.5256 16.403 19.756 16.4029 19.8981 16.545Z"
                  fill="#007B36"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M18.8679 16.1329C19.2194 15.7813 19.7895 15.7812 20.1411 16.1328C20.4928 16.4843 20.4929 17.0544 20.1413 17.406L17.0538 20.4943C16.885 20.6631 16.656 20.758 16.4172 20.758C16.1783 20.758 15.9493 20.6632 15.7805 20.4943L14.2364 18.9502C13.8848 18.5986 13.8848 18.0286 14.2364 17.6769C14.588 17.3253 15.158 17.3253 15.5096 17.6769L16.417 18.5843L18.8679 16.1329Z"
                  fill="#007B36"
                />
                <path
                  d="M13.1154 20.7578H6.18207C5.19541 20.7578 4.4043 19.9667 4.4043 18.98V6.53559C4.4043 5.54892 5.19541 4.75781 6.18207 4.75781H18.6265C19.6132 4.75781 20.4043 5.54892 20.4043 6.53559V13.4689C19.8621 13.1578 19.2665 12.9356 18.6265 12.8289V6.53559H6.18207V18.98H12.4754C12.5821 19.62 12.8043 20.2156 13.1154 20.7578ZM12.4043 17.2023H8.84874C8.35782 17.2023 7.95985 16.8043 7.95985 16.3134C7.95985 15.8224 8.35782 15.4245 8.84874 15.4245H12.4043M14.7865 13.6467H8.84874C8.35782 13.6467 7.95985 13.2487 7.95985 12.7578C7.95985 12.2669 8.35782 11.8689 8.84874 11.8689H15.8887C16.4189 11.8689 16.8487 12.2987 16.8487 12.8289C16.0932 12.9534 15.3999 13.2378 14.7865 13.6467ZM12.4043 8.31337H15.9599C16.4508 8.31337 16.8487 8.71134 16.8487 9.20226C16.8487 9.69318 16.4508 10.0911 15.9599 10.0911H8.84874C8.35782 10.0911 7.95985 9.69318 7.95985 9.20226C7.95985 8.71134 8.35782 8.31337 8.84874 8.31337H12.4043Z"
                  fill="#007B36"
                />
              </svg>
              <span>Aceitar alterações</span>
            </ButtonCustom>
            <ButtonCustom onClick={handleOpenDeclineDiff}>
              {/* <img src={iconAccept} alt="" /> */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
              >
                <path
                  d="M6.18207 4.75781H18.6265C19.6132 4.75781 20.4043 5.54892 20.4043 6.53559V13.4689C19.8621 13.1578 19.2665 12.9356 18.6265 12.8289V6.53559H6.18207V18.98H12.4754C12.5821 19.62 12.8043 20.2156 13.1154 20.7578H6.18207C5.19541 20.7578 4.4043 19.9667 4.4043 18.98V6.53559C4.4043 5.54892 5.19541 4.75781 6.18207 4.75781ZM7.95985 9.20226C7.95985 8.71134 8.35782 8.31337 8.84874 8.31337H15.9598C16.4508 8.31337 16.8487 8.71134 16.8487 9.20226C16.8487 9.69318 16.4508 10.0911 15.9598 10.0911H8.84874C8.35782 10.0911 7.95985 9.69318 7.95985 9.20226ZM7.95985 12.7578C7.95985 12.2669 8.35782 11.8689 8.84874 11.8689H15.8887C16.4189 11.8689 16.8487 12.2987 16.8487 12.8289C16.0932 12.9534 15.3998 13.2378 14.7865 13.6467H8.84874C8.35782 13.6467 7.95985 13.2487 7.95985 12.7578ZM7.95985 16.3134C7.95985 15.8224 8.35782 15.4245 8.84874 15.4245H12.4043V17.2023H8.84874C8.35782 17.2023 7.95985 16.8043 7.95985 16.3134Z"
                  fill="#DE2D2D"
                />
                <path
                  d="M19.8926 15.9639C19.8612 15.9324 19.8239 15.9074 19.7828 15.8904C19.7417 15.8733 19.6977 15.8646 19.6533 15.8646C19.6088 15.8646 19.5648 15.8733 19.5237 15.8904C19.4826 15.9074 19.4453 15.9324 19.4139 15.9639L17.7537 17.6207L16.0935 15.9605C16.062 15.929 16.0247 15.9041 15.9837 15.8871C15.9426 15.8701 15.8986 15.8613 15.8541 15.8613C15.8097 15.8613 15.7657 15.8701 15.7246 15.8871C15.6835 15.9041 15.6462 15.929 15.6148 15.9605C15.5833 15.9919 15.5584 16.0292 15.5414 16.0703C15.5244 16.1114 15.5156 16.1554 15.5156 16.1998C15.5156 16.2443 15.5244 16.2883 15.5414 16.3294C15.5584 16.3704 15.5833 16.4078 15.6148 16.4392L17.275 18.0994L15.6148 19.7596C15.5833 19.791 15.5584 19.8283 15.5414 19.8694C15.5244 19.9105 15.5156 19.9545 15.5156 19.999C15.5156 20.0434 15.5244 20.0874 15.5414 20.1285C15.5584 20.1696 15.5833 20.2069 15.6148 20.2383C15.6462 20.2697 15.6835 20.2947 15.7246 20.3117C15.7657 20.3287 15.8097 20.3375 15.8541 20.3375C15.8986 20.3375 15.9426 20.3287 15.9837 20.3117C16.0247 20.2947 16.062 20.2697 16.0935 20.2383L17.7537 18.5781L19.4139 20.2383C19.4453 20.2697 19.4826 20.2947 19.5237 20.3117C19.5648 20.3287 19.6088 20.3375 19.6533 20.3375C19.6977 20.3375 19.7417 20.3287 19.7828 20.3117C19.8239 20.2947 19.8612 20.2697 19.8926 20.2383C19.924 20.2069 19.949 20.1696 19.966 20.1285C19.983 20.0874 19.9918 20.0434 19.9918 19.999C19.9918 19.9545 19.983 19.9105 19.966 19.8694C19.949 19.8283 19.924 19.791 19.8926 19.7596L18.2324 18.0994L19.8926 16.4392C20.0216 16.3102 20.0216 16.0929 19.8926 15.9639Z"
                  fill="#DE2D2D"
                />
                <path
                  d="M15.5224 15.4522C15.6174 15.4128 15.7192 15.3926 15.822 15.3926C15.9248 15.3926 16.0266 15.4128 16.1216 15.4522C16.2166 15.4915 16.3029 15.5492 16.3756 15.6219L17.7219 16.9682L19.0672 15.6256C19.1398 15.553 19.2264 15.4949 19.3213 15.4556C19.4163 15.4161 19.5182 15.3958 19.6211 15.3958C19.724 15.3958 19.8259 15.4161 19.921 15.4556C20.016 15.495 20.1024 15.5528 20.1751 15.6256C20.4773 15.9282 20.4772 16.4267 20.1748 16.7291L18.8288 18.0751L20.1748 19.421C20.2475 19.4937 20.3051 19.58 20.3445 19.675C20.3838 19.77 20.4041 19.8718 20.4041 19.9746C20.4041 20.0775 20.3838 20.1793 20.3445 20.2743C20.3051 20.3693 20.2475 20.4556 20.1748 20.5283C20.1021 20.601 20.0157 20.6586 19.9208 20.698C19.8258 20.7373 19.724 20.7576 19.6211 20.7576C19.5183 20.7576 19.4165 20.7373 19.3215 20.698C19.2265 20.6586 19.1402 20.601 19.0675 20.5283L17.7216 19.1823L16.3756 20.5283C16.3029 20.601 16.2166 20.6586 16.1216 20.698C16.0266 20.7373 15.9248 20.7576 15.822 20.7576C15.7192 20.7576 15.6174 20.7373 15.5224 20.698C15.4274 20.6586 15.3411 20.601 15.2684 20.5283C15.1957 20.4556 15.138 20.3693 15.0987 20.2743C15.0593 20.1793 15.0391 20.0775 15.0391 19.9746C15.0391 19.8718 15.0593 19.77 15.0987 19.675C15.138 19.58 15.1957 19.4937 15.2684 19.421L16.6143 18.0751L15.2684 16.7291C15.1957 16.6564 15.138 16.5701 15.0987 16.4751C15.0593 16.3801 15.0391 16.2783 15.0391 16.1755C15.0391 16.0727 15.0593 15.9709 15.0987 15.8759C15.138 15.7809 15.1957 15.6946 15.2684 15.6219C15.3411 15.5492 15.4274 15.4915 15.5224 15.4522Z"
                  fill="#DE2D2D"
                />
              </svg>
              <span>Rejeitar alterações</span>
            </ButtonCustom>
          </ButtonAccept>
        </Html>
      )}

      {isSelected && (
        <Rect
          x={shapeProps.x}
          y={shapeProps.y}
          onMouseEnter={(event) => {
            if (event.currentTarget.attrs.id) {
              setHoverSelectedObject(event.currentTarget.attrs.id);
            }

            setBlockDblClick(true);
          }}
          onMouseLeave={() => {
            setBlockDblClick(false);
          }}
          onClick={() => {
            if (isEditing === "open") {
              setSelectedObject(null);
            }
          }}
          id={shapeProps.id}
          onDblClick={(event) => {
            if (wasEditing) return;
            if (isEditingText) {
              setIsEditingText(false);
              setSelectedObject(null);
            } else {
              handleEdit(event);
            }
          }}
          ref={rectangule}
          draggable={shapeRef.current?.draggable()}
          width={widthText}
          height={heightText}
          //onDragStart={handleDragStart}
          onDragMove={(event: Konva.KonvaEventObject<MouseEvent>) => {
            //linking image moviment with transformer
            if (isSelected) {
              shapeRef.current?.setAbsolutePosition({
                ...event.target?.getAbsolutePosition(),
              });
            }
          }}
          onDragStart={(event) => {
            if (hasGroupShared.current) {
              setHasModificationsRect(true);
            }

            setPageObject(event?.currentTarget?.getStage()?.attrs?.id);
            if (isEditing !== "open") {
              setIsDraggingObject(true);
            }

            if (
              currentMultipleSelection.current?.nodes().length === 0 ||
              !currentMultipleSelection.current
            ) {
              addToHistory();
            }

            const isTargetInsideMultipleSelection =
              currentMultipleSelection.current?.nodes().includes(event.target);

            //if mutipleSelection includes element being dragged
            if (isTargetInsideMultipleSelection) {
              setSelectedObject(null);
            } else {
              if (isEditing === "open" || isEditingText) {
                setSelectedObject(null);
                return;
              }
              setSelectedObject(shapeRef.current);
              currentMultipleSelection.current?.setNodes([]);
            }
          }}
          onDragEnd={handleDragEnd}
        />
      )}
    </Fragment>
  );
};

export default memo(RichTextImage);
