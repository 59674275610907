import { ImportDocModal } from "../../../one-click";
import { Container, HeadingText, Text, Button, HStack } from "./styles";
import React from "react";
import { ContractAnimation } from "../../../../shared/components/ContractAnimation";
import { Backdrop } from "@material-ui/core";
import useDocumentUpload from "../../../one-click/hooks/useDocumentUpload";
import { ToastContainer } from "react-toastify";
import LogoIA from '../../../../shared/assets/logo-ia.svg';
import { useMetric } from "../../../../hooks/metric";
import { useAuth } from "../../../../hooks/auth";

const OneClickSection = ({ refetchTemplates }) => {
  const {
    isLoading,
    open,
    setOpen,
    setLoading,
    fileInputRef,
    uploadDocument
  } = useDocumentUpload();

  const { data } = useAuth()

  const { handleIAMenu } = useMetric()

  return (
    <>
      <Backdrop
        style={{
          zIndex: 999999,
          color: "#fff"
        }}
        open={isLoading && !open}
        onClick={() => setLoading(false)}
      >
        <ContractAnimation />
        <ToastContainer />
      </Backdrop>

      <Container>
        <HStack>
          <HeadingText>
            <img src={LogoIA} alt="Logo IA" width={"150px"} />
          </HeadingText>
          <Text>
            Implemente Legal Design em seu contrato de forma automática. <br />{" "}
            Basta fazer o upload do seu contrato em "Word" que o UX DOC fará
            todo o resto.
          </Text>
        </HStack>
        <input
          type="file"
          accept=".docx"
          onChange={uploadDocument}
          ref={fileInputRef}
          style={{ display: "none" }}
        />
        {/* <Button onClick={() => {
          handleIAMenu({
            title: 'Importando documento',
            user_id: data.user.id
          })
          setOpen(prevState => !prevState)
        }}>Importar documento</Button> */}
        <ImportDocModal
          open={open}
          handleClose={() => {
            setOpen(false);
            refetchTemplates();
          }}
        />
      </Container>
    </>
  );
};

export default OneClickSection;
