import { useState } from "react";
import PlainText from "../../../../../../../shared/components/PlainText";
import Options from "../Options";
import {
  Container,
  SubscriberBox,
  AvatarCustom,
  AvailableDate
} from "./styled";
import {
  IModalSign,
  SignDataProps
} from "../../../../../../../dtos/signedProps";

interface ISignItem {
  sign: SignDataProps;
  formatDate: (date: string) => string;
  formatDateFinal: (date: string) => string;
  setOpenModal: React.Dispatch<React.SetStateAction<IModalSign>>;
}

const SignItem = ({
  sign,
  formatDate,
  formatDateFinal,
  setOpenModal
}: ISignItem) => {
  const [isVisibleOption, setIsVisibleOption] = useState(false);
  const [isShowingAll, setIsShowingAll] = useState(false); // Estado para controlar exibição completa

  const remainingUsersCount = sign.signature_users.length > 3 ? sign.signature_users.length - 3 : 0;

  return (
    <Container
      onMouseEnter={() => setIsVisibleOption(true)}
      onMouseLeave={() => setIsVisibleOption(false)}
    >
      <PlainText> {sign.signature.title} </PlainText>
      <PlainText>
        {sign.signature.status === "Assinatura expirada"
          ? "Expirado"
          : sign.signature.status}
      </PlainText>
      <SubscriberBox>
        {/* Exibe os 3 primeiros usuários */}
        {sign.signature_users?.slice(0, 3).map((user) => (
          <AvatarCustom avatar_url={`${user.avatar_url}`} key={user.id}>
            {(user.avatar_url.includes('default') || !user.avatar_url) && (
              <span>
                {`${user.first_name.charAt(0).toUpperCase()}${user.last_name
                  .charAt(0)
                  .toUpperCase()}`}
              </span>
            )}
          </AvatarCustom>
        ))}

        {/* Exibe o avatar com a quantidade restante */}
        {remainingUsersCount > 0 && !isShowingAll && (
          <AvatarCustom isExpand onClick={() => setIsShowingAll(true)} style={{ cursor: 'pointer' }}>
            <span>{`+${remainingUsersCount}`}</span>
          </AvatarCustom>
        )}

        {/* Exibe todos os usuários ao clicar */}
        {isShowingAll &&
          sign.signature_users?.slice(3).map((user) => (
            <AvatarCustom avatar_url={`${user.avatar_url}`} key={user.id}>
              {(user.avatar_url.includes('default') || !user.avatar_url) && (
                <span>
                  {`${user.first_name.charAt(0).toUpperCase()}${user.last_name
                    .charAt(0)
                    .toUpperCase()}`}
                </span>
              )}
            </AvatarCustom>
          ))}

        {remainingUsersCount > 0 && isShowingAll && (
          <AvatarCustom isExpand onClick={() => setIsShowingAll(false)} style={{ cursor: 'pointer' }}>
            <span>{`-`}</span>
          </AvatarCustom>
        )}
      </SubscriberBox>
      <PlainText> {formatDate(sign.signature.created_at)} </PlainText>
      <PlainText>
        {sign?.signature?.status === "Concluído"
          ? `${sign?.signature?.difference_days} dias`
          : '-'}
      </PlainText>
      <AvailableDate isExpired={false}>
        {sign.signature.status === "Concluído"
          ? "Concluído"
          : formatDateFinal(sign.signature.final_date)
        }
      </AvailableDate>
      {isVisibleOption && <Options sign={sign} setOpenModal={setOpenModal} />}
    </Container>
  );
};

export default SignItem;
