import React, { useCallback, useEffect, useMemo, useState } from "react";
import Modal from "react-modal";
import {
  AvatarCustom,
  Button,
  ContainerModal,
  FirstAreaItem,
  Footer,
  Header,
  ItemUserInfo,
  LoadingFlex,
  Table,
  Trash,
} from "./styles";
import { AnimatePresence, motion } from "framer-motion";
import api from "../../../../services/api";
import { toast } from "react-toastify";

import { useMainHook } from "../../../../hooks/main";
import { useSharedDocument } from "../../hooks/sharedDocument";
import { useNavigate, useParams } from "react-router-dom";
import { useDiff } from "../../hooks/diffDocuments";
import TitleH5 from "../../../../shared/components/TitleH5";
import ButtonExit from "../../../../shared/components/ButtonExit";
import PersonalizedIcon from "../../../../shared/assets/customIcons/PersonalizedIcon";
import MountIcons from "../../../../shared/utils/MountIcons";
import PlainText from "../../../../shared/components/PlainText";
import ButtonMaxWidth from "../../../../shared/components/ButtonMaxWidth";
import { useAuth } from "../../../../hooks/auth";
import { Typography } from "@material-ui/core";
import { PuffLoader } from "react-spinners";
import Tooltip from "../Tooltip";

interface UsersSharedProps {
  id?: string;
  name?: string;
  email?: string;
  avatar_url?: string;
  permissions?: string;
  is_anonymous?: boolean;
}

interface ISelectMember {
  user: UsersSharedProps;
  index: number;
}

interface ModalSharedInfoProps {
  isOpen: boolean;
  onRequestClose?: () => void;
}

export const ModalSharedInfo: React.FC<ModalSharedInfoProps> = ({
  isOpen,
  onRequestClose,
}) => {
  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        delayChildren: 0.5,
        type: "spring",
        duration: 1,
        stiffness: 100,
        staggerDirection: -1,
      },
    },
  };

  const item = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        delayChildren: 1.9,
        type: "spring",
        stiffness: 100,
      },
    },
  };

  const {
    currentTemplateInfo,
    myProfileId,
    objectScreen,
    handleOpenModalBlockFuncionalities,
    hasGroupShared,
  } = useMainHook();


  const { updateIfGroup, setUpdateIfGroup } = useDiff();
  const { data } = useAuth();

  const navigate = useNavigate();
  const { templateid, typetemplate } = useParams();
  const [sharedUsers, setSharedUsers] = useState<UsersSharedProps[]>([]);
  const { refreshUsersShared, setRefreshUsersShared } = useSharedDocument();
  const [isOwnerDocument, setIsOwnerDocument] = useState(false);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [indexDelete, setIndexDelete] = useState(0);
  const [toggleIsUsersShared, setToggleIsUsersShared] = useState(false);
  const [isConfirmDelete, setIsConfirmDelete] = useState<boolean>(false);
  const [selectUser, setIsSelectUser] = useState<ISelectMember>({} as ISelectMember);

  const isOwner = useMemo(() => {
    // Filtra apenas os Owners na lista de usuários compartilhados
    const owners = sharedUsers.filter(user => user.permissions === 'Owner');

    // Verifica se o usuário atual é um Owner
    const isCurrentUserOwner = owners.some(user => user.email === data.user.email);

    return isCurrentUserOwner;
  }, [sharedUsers, data]);


  useEffect(() => {
    const autoLoadSharedUsers = async () => {
      try {
        const responseTemplatesUsers = await api.get(
          `user-share-template/all-shared-template-users/${templateid}`
        );
        setSharedUsers(responseTemplatesUsers.data.users);
      } catch (err) {
        console.error(err, "ERROR when fetch users shared template");
      }
    };

    if (typetemplate === 'team-template') return;
    autoLoadSharedUsers();
  }, [refreshUsersShared, toggleIsUsersShared]);

  const handleDeleteUserShare = useCallback(
    async (idUser: string, isAnnonymous: boolean, event, index) => {
      event.preventDefault();
      setIndexDelete(index);
      let userId = idUser;
      let documentId = templateid;
      let isUserAnnonymous = isAnnonymous ? 1 : 0;

      try {
        setLoadingDelete(true);
        const responseDeleteUserShare = await api.delete(
          `user-share-template/delete-user-share/${userId}/${documentId}/${isUserAnnonymous}`
        );
        if (!isOwner) {
          toast.success('Usuário removido com sucesso');
          onRequestClose();
          navigate(-1);
        }
        setRefreshUsersShared(!refreshUsersShared);
        setLoadingDelete(false);
      } catch (err) {
        console.error(err, "ERROR when delete user shared");
        setLoadingDelete(false);
      }
    },
    [refreshUsersShared, indexDelete, loadingDelete, isOwner]
  );

  const handleTrashClick = (user, event, index) => {
    event.preventDefault();
    setToggleIsUsersShared((prevState) => !prevState);

    // Verifica se há mudanças no objeto para bloquear download com marcas de edições
    let changes = false;
    objectScreen.forEach((page) => {
      page.renderObjects.forEach((renderedObject) => {
        if (renderedObject.text && !changes) {
          changes = renderedObject.isModify ? true : false;
        }
      });
    });

    if (!changes) {
      handleDeleteUserShare(user.id, user.is_anonymous, event, index);
      setUpdateIfGroup((prevState) => !prevState);
    } else {
      onRequestClose();
      handleOpenModalBlockFuncionalities("shared");
    }
  };

  useEffect(() => {
    const timeOut = setTimeout(() => {
      setForceUpdate(!forceUpdate);
    }, 2000);

    return clearTimeout(timeOut);
  }, []);

  useEffect(() => {
    if (myProfileId) {
      const getOwnerId = sharedUsers.find(
        (user) => user.permissions === "Owner"
      );

      if (getOwnerId?.id === myProfileId) {
        setIsOwnerDocument(true);
      } else {
        setIsOwnerDocument(false);
      }
    }
  }, [forceUpdate]);

  useEffect(() => {
    setIsConfirmDelete(false);
  }, [onRequestClose]);


  return (
    <Modal
      className="react-modal-content"
      overlayClassName="react-modal-overlay"
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      ariaHideApp={false}
    >
      <AnimatePresence>
        <ContainerModal
          as={motion.form}
          initial="hidden"
          animate="show"
          variants={container}
          exit={{
            opacity: 0,
            scale: 0.8,
            x: -300,
          }}
          transition={{ duration: 1 }}
        >
          <Header>
            <TitleH5
              title={isConfirmDelete ? 'Deseja remover seu acesso do documento?' : `Compartilhado - ${currentTemplateInfo?.current?.title}`}
            />

            <ButtonExit disabled={loadingDelete} onClick={onRequestClose} className="icon-exit" />
          </Header>

          {
            isConfirmDelete ? (
              <>
                <div style={{ paddingInline: '20px' }}>
                  <Typography variant="h5" style={{
                    fontFamily: 'Ubuntu',
                    fontSize: '14px',
                  }}>
                    Ao confirmar, você não poderá mais ver ou editar este documento.
                  </Typography>
                </div>
                <Footer>
                  <Button
                    typeStyle='purple'
                    onClick={() => setIsConfirmDelete(false)}
                    disabled={loadingDelete}
                  >
                    Cancelar
                  </Button>
                  <Button
                    typeStyle='white'
                    onClick={(event) => handleTrashClick(selectUser.user, event, selectUser.index)}
                  >
                    {loadingDelete ?
                      <LoadingFlex>
                        <PuffLoader
                          color="#000"
                          size="28px"
                        />
                      </LoadingFlex>
                      : 'Remover meu acesso'
                    }
                  </Button>
                </Footer>
              </>
            ) : (
              <>
                <Table>
                  <div>
                    <p>Nome</p> <p>E-mail</p> <p>Função na equipe</p>
                  </div>

                  <ul>
                    {sharedUsers.map((user, index) => (
                      <ItemUserInfo key={user.id}>
                        <FirstAreaItem>
                          <figure>
                            <AvatarCustom indexOfUser={index + 1}>
                              <PlainText>
                                {`${user.name.charAt(0).toUpperCase()}`}
                              </PlainText>
                            </AvatarCustom>
                          </figure>

                          <Tooltip title={`${user.name}`} placement="top">
                            <p>{user.name}</p>
                          </Tooltip>
                        </FirstAreaItem>
                        <Tooltip title={`${user.email}`} placement="top">
                          <span className="email">{user.email}</span>
                        </Tooltip>
                        <PlainText>
                          {user.permissions === "Owner" ? "Proprietário" : "Membro"}{" "}
                        </PlainText>
                        {/* Owner pode excluir outros, mas não ele mesmo */}
                        {(isOwner && user.permissions !== "Owner") && (
                          <Trash onClick={(event) => handleTrashClick(user, event, index)}>
                            <PersonalizedIcon
                              dPath={MountIcons.IconTrash.dPath}
                              viewBox={MountIcons.IconTrash.viewBox}
                              inactivatedColor="#999C9F"
                            />
                          </Trash>
                        )}

                        {/* Membro pode excluir apenas ele mesmo */}
                        {user.permissions !== "Owner" && user.email === data.user.email && (
                          <Trash
                            onClick={() => {
                              setIsSelectUser({
                                user,
                                index
                              });
                              setIsConfirmDelete(prevState => !prevState);
                            }}
                          // onClick={(event) => handleTrashClick(user, event, index)}
                          >
                            <PersonalizedIcon
                              dPath={MountIcons.IconTrash.dPath}
                              viewBox={MountIcons.IconTrash.viewBox}
                              inactivatedColor="#999C9F"
                            />
                          </Trash>
                        )}
                      </ItemUserInfo>
                    ))}
                  </ul>
                </Table>
                <Footer>
                  <ButtonMaxWidth
                    onClick={onRequestClose}
                    text="Fechar"
                    typeStyle="purple"
                  />
                </Footer>
              </>
            )
          }
        </ContainerModal>
      </AnimatePresence>
    </Modal>
  );
};
