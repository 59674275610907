import styled from "styled-components";

// Styled Components
export const SelectContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;

export const InputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
`;

// Estilo do input
export const Input = styled.input`
  width: 100%;
  padding-right: 25px; /* Para acomodar a seta */
  font-size: 14px;
  border: none;
  outline: none;
  background: transparent;
`;

// A seta (adicionada como um elemento separado)
export const Arrow = styled.span`
  position: absolute;
  right: 10px;
  font-size: 12px;
  color: #333;
  pointer-events: none;
`;

export const IconSelected = styled.img`
  padding-right: 5px;
  width: 30px;
  height: 20px;
`;

export const Options = styled.div`
  position: absolute;
  width: 100%;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 0px 0px 5px 5px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
  max-height: 200px;
  overflow-y: auto;
  z-index: 1;
  top: 70px;
`;

export const Option = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;

  &:hover {
    background-color: #f0f0f0;
  }
`;

export const Icon = styled.img`
  margin-right: 10px;
  font-size: 18px;
`;

export const Label = styled.span`
  color: #343A40;
  font-family: Ubuntu;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
`;

export const LabelOptions = styled.option`
  color:#343A40;
  font-family: Ubuntu;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
`