import styled from "styled-components";
import { Theme } from "../../../../dtos/theme";

const getColorByIndex = (index: number) => {
  const colors = ["#24958E", "#F90", "#06C", "#663399"];
  const colorIndex = (index - 1) % colors.length;
  return colors[colorIndex];
};

export const ContainerModal = styled.form`
  width: 700px;
  border-radius: 5px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
  background: ${({ theme }: Theme) => theme.colors.whiteColor};
  padding-block: 20px;

  position: relative;

  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding-inline: 20px;

  border-bottom: 1px solid #ebebec;
  padding-bottom: 10px;

  .btn-exit {
    position: absolute;
    top: 13px;
    right: 13px;
  }
`;

export const Text = styled.span`
  display: flex;
  align-items: center;
  gap: 8px;

  padding-inline: 20px;
  font-size: 12px;
  font-weight: 400;
  line-height: 150%;
`;

export const Wrapper = styled.div`
  padding-inline: 20px;
  padding-top: 20px;

  display: flex;
  gap: 10px;
`;

export const VerticalStack = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  margin-top: 1rem;
  flex-direction: column;
`;

export const ItemUserInfo = styled.li`
  display: grid;
  grid-template-columns: 4fr 4fr 2.7fr 0.8fr;
  align-items: center;
  min-height: 44px;

  span {
    color: #a5a5a5;
  }

  .email {
    color: #999c9f;

    max-width: 23ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
    font-weight: 400;
    line-height: 150%;
    text-align: left;
  }

  .active-user {
    font-size: 14px;
    font-weight: 400;
    line-height: 120%;
    color: #007b36;
  }
`;

export const Table = styled.div`
  border-radius: 5px 5px 0px 0px;
  border: 1px solid ${({ theme }) => theme.colors.black20};
  margin-inline: 20px;

  & > div {
    height: 30px;
    border-radius: 5px 5px 0px 0px;
    background-color: ${({ theme }) => theme.colors.black10};

    display: flex;
    align-items: center;

    display: grid;
    grid-template-columns: 4fr 4fr 2.7fr 0.8fr;

    font-family: "Ubuntu";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    color: ${({ theme }) => theme.colors.black100};

    p:first-child {
      padding-left: 54px;
    }
  }

  ul {
    display: flex;
    flex-direction: column;
    min-height: 44px;
    max-height: 132px;

    overflow-y: auto;
  }

  .containerLoading {
    width: 100%;
    height: 44px;

    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const FirstAreaItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding-left: 15px;

  p {
    max-width: 23ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
    font-weight: 400;
    line-height: 150%;
    text-align: left;
  }
`;

export const AvatarCustom = styled.div<{ indexOfUser: number }>`
  min-width: 30px;
  min-height: 30px;
  border-radius: 50%;
  border: 1px solid #fff;
  user-select: none;
  background-color: ${({ indexOfUser }) => getColorByIndex(indexOfUser)};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #fff;

  &:nth-child(1) {
    transform: translateX(0);
  }

  &:nth-child(2) {
    transform: translateX(-5px);
  }

  &:nth-child(3) {
    transform: translateX(-10px);
  }

  span {
    color: #fff;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;
