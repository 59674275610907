import { datadogRum } from '@datadog/browser-rum';

const datadogEnv: string = process.env.REACT_APP_DATADOG_TOKEN || 'dev';

if (datadogEnv === 'prod') {
  datadogRum.init({
    applicationId: process.env.REACT_APP_DATADOG_PUBLIC_APPLICATION_ID_KEY,
    clientToken: process.env.REACT_APP_DATADOG_PUBLIC_CLIENT_TOKEN_KEY,
    site: 'datadoghq.com',
    service: 'uxdoc-prod',
    env: datadogEnv,
    version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'allow',
  });
}

export default datadogRum;