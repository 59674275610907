// import DropdownZoom from "../../../DropdownZoom";

import React, { useCallback, useEffect, useRef, useState } from "react";

import IconToolbar from "../../../IconToolbar";
import ButtonToolbar from "../../ButtonToolbar";
import DropdownOpacity from "../../DropdownOpacity";
import LockedIcon from "../../icons/LockedIcon";
import UnlockIcon from "../../icons/UnlockIcon";
import {
  Container,
  ContainerRichText,
  ContainerRight,
  ContainerTextToolbar,
  Divider,
  MenuTextSentiveCase,
  VerticalDivider,
} from "./styles";
import { useTextsEdition } from "../../../../hooks/textsEdition";

import Tooltip from "../../../Tooltip";

import ButtonRuler from "../../../../../../shared/components/ButtonRuler";
import { useWorkspaceEditor } from "../../../../hooks/workspaceEditor";
import AutomaticNumbering from "../../../AutomaticNumbering";
import Comments from "../../../Comments";
import FontFamilyMenu from "./components/FontFamilyMenu";
import FontSizeMenu from "./components/FontSizeMenu";
import ButtonMenu from "./components/ButtonMenu";
import Bold from "./components/Icons/Bold";
import CaseSensitive from "./components/Icons/CaseSensitive";
import Italic from "./components/Icons/Italic";
import Underline from "./components/Icons/Underline";
import Colors from "./components/Icons/Colors";
import LeftAlign from "./components/Icons/LeftAlign";
import CenterAlign from "./components/Icons/CenterAlign";
import RightAlign from "./components/Icons/RightAlign";
import JustifyAlign from "./components/Icons/JustifyAlign";
// import List from "./components/Icons/List";
import CheckList from "./components/Icons/CheckList";
import RecoilRight from "./components/Icons/RecoilRight";
import RecoilLeft from "./components/Icons/RecoilLeft";
import LineAlign from "./components/Icons/LineAlign";
import ControlReviews from "./components/ControlReviews";
// import Quill, { RangeStatic } from "quill";
import MenuColors from "../MenuColors";
import Strikeness from "./components/Icons/Strikeness";
// import Quill from "quill";
import { useSelection } from "../../../../hooks/selection";
import { useMainHook } from "../../../../../../hooks/main";
import { cloneDeep } from "lodash";
import LineHeightMenu from "./components/LineHeightMenu";
import DiffMenu from "./components/DiffMenu";
import { useParams } from "react-router-dom";
import api from "../../../../../../services/api";
import { toast } from "react-toastify";
import { useAutomaticSaving } from "../../../../hooks/automaticSaving";

// import { Container } from './styles';

interface RichTextToolProps {
  isActiveBringToForward: boolean;
  handleClickBringToForwardIcon: () => void;
  isActiveTakeBack: boolean;
  handleClickTakeBackIcon: () => void;
  handleAllToFront: (idPage: number) => void;
  idPage: number;
  handleAllToBack: (idPage: number) => void;
  valueOpacity: number;
  setValueOpacity: React.Dispatch<React.SetStateAction<number>>;
  handleChangeOpacity: (value: number) => void;
  handleClickOpacity: () => void;
  handleBlockElements: () => void;
  handleToggleBlockElement: () => void;
  isDraggable: boolean;
  handleDeleteElement: () => void;
  iconTrash: string;
}

const RichTextTool: React.FC<RichTextToolProps> = ({
  isActiveBringToForward,
  handleClickBringToForwardIcon,
  isActiveTakeBack,
  handleClickTakeBackIcon,
  handleAllToFront,
  idPage,
  handleAllToBack,
  valueOpacity,
  setValueOpacity,
  handleChangeOpacity,
  handleClickOpacity,
  handleBlockElements,
  handleToggleBlockElement,
  isDraggable,
  handleDeleteElement,
  iconTrash,
}) => {
  const {
    isEditing,
    quillRefComponent,
    currentQuillFormats,
    setCurrentQuillFormats,
    selectionQuill,
    setSelectionQuill,
  } = useTextsEdition();

  const { setObjectScreen, hasGroupShared, pendingSave } = useMainHook();

  const { selectedObject } = useSelection();
  const refInputMenuSize = useRef<HTMLInputElement>(null);
  const { handleShowRuler, showRuler } = useWorkspaceEditor();
  const [isTextMenu, setIsTextMenu] = useState(false);
  const [isSizeMenu, setIsSizeMenu] = useState(false);
  // const []
  const [isDiffMenu, setIsDiffMenu] = useState(false);
  const [isTextOptions, setIsTextOptions] = useState(false);

  const [fontFamily, setFontFamily] = useState("Open Sans");
  const [colorText, setColorText] = useState("#000");
  const [sizeFont, setSizeFont] = useState("8px");
  const [isColorsOptions, setIsColorsOptions] = useState(false);
  const menuColorsRef = useRef<HTMLDivElement>(null);
  const menuSensitiveRef = useRef<HTMLDivElement>(null);
  const menuLineHeightRef = useRef<HTMLDivElement>(null);
  const controlDiffMenuRef = useRef<HTMLDivElement>(null);
  const [isLineHeightMenu, setIsLineHeightMenu] = useState(false);
  const [blockClose, setBlockClose] = useState(false);
  const [showColor, setShowColor] = useState("#000");
  const [isDiffActive, setIsDiffActive] = useState(false);
  // const [isFocusedSize, setIsFocusedSize] = useState(false);
  // const { pendingSave } = useAutomaticSaving();

  const [identMax, setIdentMax] = useState(false);
  const [identMin, setIdentMin] = useState(false);

  const { templateid } = useParams();

  const handleOpenLineHeightMenu = useCallback(() => {
    if (isLineHeightMenu) {
      setIsLineHeightMenu(false);
    } else {
      setIsLineHeightMenu(true);
    }
  }, [setIsLineHeightMenu, isLineHeightMenu]);

  const onCloseColorsMenu = useCallback(() => {
    setIsColorsOptions(false);
  }, [setIsColorsOptions]);

  const onCloseSensitiveMenu = useCallback(() => {
    setIsTextOptions(false);
  }, [setIsTextOptions]);

  const onCloseLineHeightMenu = useCallback(() => {
    setIsLineHeightMenu(false);
  }, [setIsLineHeightMenu]);

  const onCloseDiffMenu = useCallback(() => {
    setIsDiffMenu(false);
  }, [setIsDiffMenu]);

  useEffect(() => {
    (async () => {
      try {
        const isChangeTracking = await api.get(
          `user-share-template/all-shared-template-users/${templateid}`
        );

        setIsDiffActive(isChangeTracking.data.is_change_tracking_enabled);
      } catch (err) {
        console.error(err, "Err get tracking changes status");
      }
    })();
  }, [templateid]);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        !blockClose &&
        isLineHeightMenu &&
        menuLineHeightRef.current &&
        !menuLineHeightRef.current.contains(event.target as Node)
      ) {
        onCloseLineHeightMenu();
      }
    };

    window.addEventListener("click", handleOutsideClick);

    return () => {
      window.removeEventListener("click", handleOutsideClick);
    };
  }, [isLineHeightMenu, onCloseLineHeightMenu, blockClose]);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        isColorsOptions &&
        menuColorsRef.current &&
        !menuColorsRef.current.contains(event.target as Node)
      ) {
        onCloseColorsMenu();
      }
    };

    window.addEventListener("click", handleOutsideClick);

    return () => {
      window.removeEventListener("click", handleOutsideClick);
    };
  }, [isColorsOptions, menuColorsRef]);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        isTextOptions &&
        menuSensitiveRef.current &&
        !menuSensitiveRef.current.contains(event.target as Node)
      ) {
        onCloseSensitiveMenu();
      }
    };

    window.addEventListener("click", handleOutsideClick);

    return () => {
      window.removeEventListener("click", handleOutsideClick);
    };
  }, [isTextOptions, menuSensitiveRef]);

  const handleBoldText = useCallback(() => {
    const selection = quillRefComponent.current.getEditor().getSelection();
    const editor = quillRefComponent.current.getEditor();

    if (selection) {
      const format = editor.getFormat(selection);
      editor.format("bold", !format.bold);
    }
  }, [quillRefComponent]);

  const handleItalicText = useCallback(() => {
    const quill = quillRefComponent.current.getEditor();
    const selection = quill.getSelection();

    if (selection) {
      const format = quill.getFormat(selection);
      quill.format("italic", !format.italic);
    }
  }, [quillRefComponent]);

  const handleUnderlineText = useCallback(() => {
    const quill = quillRefComponent.current.getEditor();
    const selection = quill.getSelection();

    if (selection) {
      const format = quill.getFormat(selection);
      quill.format("underline", !format.underline);
    }
  }, [quillRefComponent]);

  const handleStrikeText = useCallback(() => {
    const quill = quillRefComponent.current.getEditor();
    const selection = quill.getSelection();

    if (selection) {
      const format = quill.getFormat(selection);
      quill.format("strike", !format.strike);
    }
  }, [quillRefComponent]);

  const handleAlignLeftText = useCallback(() => {
    const quill = quillRefComponent.current.getEditor();
    const selection = quill.getSelection();

    if (selection) {
      quill.format("align", false);
    }
  }, [quillRefComponent]);

  const handleAlignCenterText = useCallback(() => {
    const quill = quillRefComponent.current.getEditor();
    const selection = quill.getSelection();

    if (selection) {
      quill.format("align", "center");
    }
  }, [quillRefComponent]);

  const handleAlignRightText = useCallback(() => {
    const quill = quillRefComponent.current.getEditor();
    const selection = quill.getSelection();

    if (selection) {
      quill.format("align", "right");
    }
  }, [quillRefComponent]);

  const handleAlignJustifyText = useCallback(() => {
    const quill = quillRefComponent.current.getEditor();
    const selection = quill.getSelection();

    if (selection) {
      quill.format("align", "justify");
    }
  }, [quillRefComponent]);

  const onHandleDiffMenu = useCallback(() => {
    setIsDiffMenu((prev) => !prev);
  }, [setIsDiffMenu]);

  const handleToggleChecklist = useCallback(() => {
    const quill = quillRefComponent.current.getEditor();
    const selection = quill.getSelection();

    if (selection) {
      const format = quill.getFormat(selection);
      if (format.list) {
        quill.format("list", false);
      } else {
        quill.format("list", "bullet");
      }
    }
  }, [quillRefComponent]);

  const handleIndentRight = useCallback(() => {
    setIdentMax(true);
    setIdentMin(false);
    const quill = quillRefComponent.current.getEditor();
    const selection = quill.getSelection();

    if (selection) {
      quill.format("indent", "+1");
      setTimeout(() => {
        setIdentMax(false);
        setIdentMin(false);
      }, 300);
    }
  }, [quillRefComponent]);

  const handleIndentLeft = useCallback(() => {
    setIdentMax(false);
    setIdentMin(true);
    const quill = quillRefComponent.current.getEditor();
    const selection = quill.getSelection();

    if (selection) {
      quill.format("indent", "-1");
      setTimeout(() => {
        setIdentMax(false);
        setIdentMin(false);
      }, 300);
    }
  }, [quillRefComponent]);

  const setLineHeight = useCallback(
    (value: string) => {
      const quill = quillRefComponent.current.getEditor();
      // const selection = quill.getSelection();
      const length = quill.getLength();
      quill.formatText(0, length, "lineheight", value);

      setObjectScreen((oldState) => {
        let cloneState = cloneDeep(oldState);
        cloneState.forEach((page) => {
          page.renderObjects.forEach((object) => {
            if (selectedObject.attrs.id === object.id) {
              object.lineHeight = value;
            }
          });
        });

        return cloneState;
      });

      pendingSave.current = true;

      selectedObject.setAttrs({
        lineHeight: value,
      });

      // Tentativa de fazer o style direto! (Sem sucesso)
      // const editor = quill.root;
      // editor.style.lineHeight = value;
    },
    [quillRefComponent, setObjectScreen, selectedObject, pendingSave]
  );

  const handleOpenMenu = useCallback(
    (event) => {
      event.preventDefault();
      // const selection = quillRefComponent.current.getEditor().getSelection();
      // setSelectionQuill(selection);
      quillRefComponent.current.getEditor().focus();
      quillRefComponent.current.getEditor().setSelection(selectionQuill);
      setIsTextMenu((prev) => !prev);
    },
    [quillRefComponent, setIsTextMenu, selectionQuill]
  );

  const handleOnCloseMenu = useCallback(() => {
    setIsTextMenu(false);
  }, [setIsTextMenu]);

  const handleOpenSizeMenu = useCallback(() => {
    // quillRefComponent.current.getEditor().focus();
    // quillRefComponent.current.getEditor().setSelection(selectionQuill);
    setIsSizeMenu((prev) => !prev);
  }, [setIsSizeMenu]);

  const handleOnCloseSizeMenu = useCallback(() => {
    setIsSizeMenu(false);
  }, [setIsSizeMenu]);

  const handleOpenSensitiveTextMenu = useCallback(() => {
    setIsTextOptions((prev) => !prev);
  }, [setIsTextOptions]);

  const handleChangeFontSize = useCallback(
    (size: number) => {
      const quill = quillRefComponent.current.getEditor();
      quill.focus();
      // quill.setSelection(selectionQuill);
      // const selection = quill.getSelection();

      setSizeFont(`${size}px`);

      if (selectionQuill) {
        if (selectionQuill.length === 0) {
          const lengthQuillText = quill.getLength();
          quill.formatText(0, lengthQuillText, "size", `${size}px`);
          // refInputMenuSize.current.focus();
        } else {
          // quill.setSelection({ index: selectionQuill.index, length: 0 });

          quill.format("size", `${size}px`);
          // quill.blur();
          // refInputMenuSize.current.focus();
          // quill.setSelection({ index: selectionQuill.index, length: 0 });
        }
      }
    },
    [quillRefComponent, selectionQuill, setSizeFont]
  );

  const handleChangeFontFamily = useCallback(
    (font: string) => {
      const quill = quillRefComponent.current.getEditor();
      quill.focus();
      setFontFamily(font);

      if (selectionQuill) {
        if (selectionQuill.length === 0) {
          const lengthQuillText = quill.getLength();
          quill.formatText(0, lengthQuillText, "font", font);
        } else {
          quill.format("font", font);
        }
      }
    },
    [quillRefComponent, selectionQuill, setFontFamily]
  );

  const handleTransformText = useCallback(
    (
      type: "uppercase" | "lowercase" | "firstUpperCase" | "eachWordUpperCase"
    ) => {
      let isSharedGroup = false;

      if (hasGroupShared.current) {
        hasGroupShared.current = false;
        isSharedGroup = true;
      }

      // hasGroupShared.current = false;
      if (type === "uppercase") {
        const quill = quillRefComponent.current.getEditor();
        const selection = selectionQuill;
        const formats = quill.getFormat(selection.index, selection.length);

        if (selection) {
          const selectedText = quill.getText(
            selectionQuill.index,
            selectionQuill.length
          );

          const transformedText = selectedText.toUpperCase();
          quill.deleteText(selectionQuill.index, selectionQuill.length);
          quill.insertText(selectionQuill.index, transformedText, formats);
          quill.setSelection({ length: 0, index: 0 });
          // hasGroupShared.current = false;
        }
      } else if (type === "lowercase") {
        const quill = quillRefComponent.current.getEditor();
        const selection = selectionQuill;
        const formats = quill.getFormat(selection.index, selection.length);

        if (selection) {
          const selectedText = quill.getText(
            selectionQuill.index,
            selectionQuill.length
          );

          const transformedText = selectedText.toLowerCase();

          quill.deleteText(selectionQuill.index, selectionQuill.length);
          quill.insertText(selectionQuill.index, transformedText, formats);
          quill.setSelection({ length: 0, index: 0 });
        }
      } else if (type === "firstUpperCase") {
        const quill = quillRefComponent.current.getEditor();
        const selection = selectionQuill;
        const formats = quill.getFormat(selection.index, selection.length);

        if (selection) {
          const selectedText = quill.getText(selection.index, selection.length);

          const transformedText =
            selectedText.charAt(0).toUpperCase() +
            selectedText.slice(1).toLowerCase();

          quill.deleteText(selection.index, selection.length);
          quill.insertText(selection.index, transformedText, formats);
        }
      } else if (type === "eachWordUpperCase") {
        const quill = quillRefComponent.current.getEditor();
        const selection = selectionQuill;
        const formats = quill.getFormat(selection.index, selection.length);

        if (selection) {
          const selectedText = quill.getText(
            selectionQuill.index,
            selectionQuill.length
          );

          // split text in words
          const words = selectedText.split(" ");

          // transform first character to uppercase and the rest to lowercase for each word
          const transformedWords = words.map((word) => {
            const lowerCaseWord = word.toLowerCase();
            return (
              lowerCaseWord.charAt(0).toUpperCase() + lowerCaseWord.slice(1)
            );
          });

          // accumulate words again
          const transformedText = transformedWords.join(" ");

          quill.deleteText(selectionQuill.index, selectionQuill.length);
          quill.insertText(selectionQuill.index, transformedText, formats);
          quill.setSelection({ length: 0, index: 0 });
        }
      }
      if (isSharedGroup) {
        hasGroupShared.current = true;
      }
    },
    [quillRefComponent, selectionQuill, hasGroupShared]
  );

  const handleChangeColorText = useCallback(() => {
    setIsColorsOptions((prev) => !prev);
  }, [setIsColorsOptions]);

  const handleChooseColorText = useCallback(
    (color: string) => {
      // const selection = quillRefComponent.current.getEditor().getSelection();
      const editor = quillRefComponent.current.getEditor();

      if (selectionQuill) {
        editor.formatText(
          selectionQuill.index,
          selectionQuill.length,
          "color",
          color
        );
      }

      setColorText(color);
    },
    [quillRefComponent, setColorText, selectionQuill]
  );

  useEffect(() => {
    if (currentQuillFormats && quillRefComponent) {
      if (currentQuillFormats.font) {
        let fontArray = [];

        if (typeof currentQuillFormats.font === "string") {
          fontArray.push(currentQuillFormats.font);
        } else {
          fontArray.push(currentQuillFormats.font[0]);
        }

        setFontFamily(fontArray[0]);
      }

      if (currentQuillFormats.size) {
        let sizeArray = [];
        if (typeof currentQuillFormats.size === "string") {
          sizeArray.push(currentQuillFormats.size);
        } else {
          sizeArray.push(currentQuillFormats.size[0]);
        }

        setSizeFont(sizeArray[0]);
      }

      if (currentQuillFormats.color) {
        let colorArray = [];
        if (typeof currentQuillFormats.color === "string") {
          colorArray.push(currentQuillFormats.color);
        } else {
          colorArray.push(currentQuillFormats.color[0]);
        }

        setShowColor(colorArray[0]);
      }
    }
  }, [quillRefComponent, currentQuillFormats]);

  if (isEditing === "open") {
    return (
      <ContainerTextToolbar>
        <Tooltip
          placement="bottom-start"
          description="Selecione uma fonte para o seu texto."
          title="Fonte"
        >
          <FontFamilyMenu
            fontFamily={fontFamily}
            onClose={handleOnCloseMenu}
            onClick={handleOpenMenu}
            isActive={isTextMenu}
            changeFontFamily={handleChangeFontFamily}
          />
        </Tooltip>

        <FontSizeMenu
          refInput={refInputMenuSize}
          onClose={handleOnCloseSizeMenu}
          onClick={handleOpenSizeMenu}
          handleOpen={handleOpenSizeMenu}
          isActive={isSizeMenu}
          changeFontSize={handleChangeFontSize}
          sizeFont={sizeFont}
          setSizeFont={setSizeFont}
        />

        <VerticalDivider />

        <div style={{ position: "relative" }}>
          <ButtonMenu
            title="Maiúsculas e Minúsculas"
            description="Mudar o texto selecionado para maiúscula, minúscula ou outros usos comuns de maiúsculas/minúsculas."
            refMenu={menuSensitiveRef}
          >
            <CaseSensitive onClick={handleOpenSensitiveTextMenu} />
          </ButtonMenu>

          {isTextOptions && (
            <MenuTextSentiveCase>
              <div onClick={() => handleTransformText("firstUpperCase")}>
                <span>Primeira letra da frase em maiúscula</span>
              </div>
              <div onClick={() => handleTransformText("lowercase")}>
                <span>minúscula</span>
              </div>
              <div>
                <span onClick={() => handleTransformText("uppercase")}>
                  MAIÚSCULA
                </span>
              </div>
              <div onClick={() => handleTransformText("eachWordUpperCase")}>
                <span>Colocar Cada Palavra Em Maiúscula</span>
              </div>
            </MenuTextSentiveCase>
          )}
        </div>

        <VerticalDivider />

        <ButtonMenu
          title="Negrito"
          description="Ressalte seu texto com negrito."
          onClick={handleBoldText}
        >
          <Bold isActive={currentQuillFormats?.bold} />
        </ButtonMenu>

        <ButtonMenu
          title="Itálico"
          description="Aplique o efeito itálico ao seu texto."
          onClick={handleItalicText}
        >
          <Italic isActive={currentQuillFormats?.italic} />
        </ButtonMenu>

        <ButtonMenu
          title="Sublinhado"
          description="Sublinhe seu texto."
          onClick={handleUnderlineText}
        >
          <Underline isActive={currentQuillFormats?.underline} />
        </ButtonMenu>

        <ButtonMenu
          title="Tachado"
          description="Insira uma linha sobre o seu texto."
          onClick={handleStrikeText}
        >
          <Strikeness isActive={currentQuillFormats?.strike} />
        </ButtonMenu>

        <VerticalDivider />

        <ButtonMenu
          title="Cor do texto"
          description="Mude a cor do seu texto"
          refMenu={menuColorsRef}
          isDisable
        >
          <Colors color={showColor} onClick={handleChangeColorText} />
          {isColorsOptions && (
            <MenuColors
              callbackColor={handleChooseColorText}
              // refMenu={menuColorsRef}
              currentColor={colorText}
              setCurrentColor={setColorText}
              quillRef={quillRefComponent}
              selectionQuill={selectionQuill}
            />
          )}
        </ButtonMenu>

        <VerticalDivider />

        <ButtonMenu
          title="Alinhar a esquerda"
          description="Escolha o alinhamento a esquerda para o seu texto."
          onClick={handleAlignLeftText}
        >
          <LeftAlign
            isActive={
              !currentQuillFormats?.align ||
              currentQuillFormats?.align === "left"
            }
          ></LeftAlign>
        </ButtonMenu>

        <ButtonMenu
          title="Alinhar centralizado"
          description="Escolha o alinhamento centralizado para o seu texto."
          onClick={handleAlignCenterText}
        >
          <CenterAlign
            isActive={currentQuillFormats?.align === "center"}
          ></CenterAlign>
        </ButtonMenu>

        <ButtonMenu
          title="Alinhar a direita"
          description="Escolha o alinhamento a direita para o seu texto."
          onClick={handleAlignRightText}
        >
          <RightAlign
            isActive={currentQuillFormats?.align === "right"}
          ></RightAlign>
        </ButtonMenu>

        <ButtonMenu
          title="Alinhar justificado."
          description="Escolha o alinhamento justificado para o seu texto."
          onClick={handleAlignJustifyText}
        >
          <JustifyAlign
            isActive={currentQuillFormats?.align === "justify"}
          ></JustifyAlign>
        </ButtonMenu>

        <VerticalDivider />

        {/* <ButtonMenu onClick={handleToggleList}>
          <List></List>
        </ButtonMenu> */}

        <ButtonMenu
          title="Marcadores"
          description="Crie uma lista de marcadores."
          onClick={handleToggleChecklist}
        >
          <CheckList isActive={currentQuillFormats?.list}></CheckList>
        </ButtonMenu>
        <VerticalDivider />
        <ButtonMenu
          title="Aumentar recuo"
          description="Aproxime o seu parágrafo Afaste o seu parágrafo da margem do documento."
          onClick={handleIndentRight}
        >
          <RecoilRight isActive={identMax}></RecoilRight>
        </ButtonMenu>

        <ButtonMenu
          title="Diminuir recuo"
          description="Aproxime o seu parágrafo da margem do documento."
          onClick={handleIndentLeft}
        >
          <RecoilLeft isActive={identMin}></RecoilLeft>
        </ButtonMenu>
        <VerticalDivider />
        <div style={{ position: "relative" }}>
          <ButtonMenu
            title="Altura de linha"
            description="Defina altura de linha do seu texto."
            refMenu={menuLineHeightRef}
            onClick={handleOpenLineHeightMenu}
          >
            <LineAlign></LineAlign>
          </ButtonMenu>
          {isLineHeightMenu && (
            <LineHeightMenu
              callback={setLineHeight}
              setBlockClose={setBlockClose}
              isCurrentValue={selectedObject?.attrs?.lineHeight}
            ></LineHeightMenu>
          )}
        </div>

        <VerticalDivider />

        <div style={{ position: "relative" }}>
          {/* <ButtonMenu
            title="Controle de alterações"
            description="Controle todas as alterações feitas no documento. Isso é útil se o documento está quase concluído e você está trabalhando com outras pessoas na revisão."
            refMenu={controlDiffMenuRef}
          >
            <ControlReviews
              isActive={isDiffActive}
              onClick={onHandleDiffMenu}
            />
          </ButtonMenu> */}
          {isDiffMenu && (
            <DiffMenu
              callback={setLineHeight}
              isDiffMenu={isDiffMenu}
              // refMenu={controlDiffMenuRef}
              onCloseDiffMenu={onCloseDiffMenu}
              setIsDiffActive={setIsDiffActive}
              // setBlockClose={setBlockClose}
              // isCurrentValue={selectedObject?.attrs?.lineHeight}
            ></DiffMenu>
          )}
        </div>
      </ContainerTextToolbar>
    );
  }

  return (
    <Container>
      <ContainerRichText>
        <>
          <ButtonToolbar
            isActive={isActiveBringToForward}
            className="btn-toolbar"
            type="button"
            tooltipTitle="Avançar"
            tooltipDescription="Trazer o objeto selecionado um nível para a frente, para que fique à frente de menos objetos."
            tooltipPlacement="bottom-start"
            onClick={handleClickBringToForwardIcon}
            component="toFront"
          />
          <div>
            <ButtonToolbar
              isActive={isActiveTakeBack}
              className="btn-toolbar"
              type="button"
              component="toBack"
              tooltipTitle="Recuar"
              tooltipDescription="Enviar o objeto selecionado um nível para trás, para que fique atrás de mais objetos."
              tooltipPlacement="bottom-start"
              onClick={handleClickTakeBackIcon}
            />
          </div>
          <ButtonToolbar
            isActive={isActiveTakeBack}
            className="btn-toolbar"
            type="button"
            component="isAllToFront"
            tooltipTitle="Tudo para frente"
            tooltipDescription="Trazer o objeto selecionado para a frente de todos os outros objetos."
            tooltipPlacement="bottom-start"
            onClick={() => handleAllToFront(idPage)}
          />
          <ButtonToolbar
            isActive={isActiveTakeBack}
            className="btn-toolbar"
            type="button"
            component="isAllToBack"
            tooltipTitle="Tudo para trás"
            tooltipDescription="Enviar o objeto selecionado para trás de todos os outros objetos."
            tooltipPlacement="bottom-start"
            onClick={() => handleAllToBack(idPage)}
          />
          <DropdownOpacity
            valueOpacity={valueOpacity}
            setValueOpacity={setValueOpacity}
            callback={handleChangeOpacity}
            // isActive={isActiveDash}
            className="btn-toolbar"
            type="button"
            tooltipTitle="Transparência"
            tooltipDescription="Manipule a transparência do objeto."
            tooltipPlacement="bottom-start"
            onClick={handleClickOpacity}
            component="isOpacity"
          />
        </>
      </ContainerRichText>

      <ContainerRight>
        <AutomaticNumbering />
        <ButtonRuler showRuler={showRuler} handleShowRuler={handleShowRuler} />
        <Divider>
          <Comments />
        </Divider>

        <Divider>
          <Tooltip
            title={isDraggable ? "Bloquear" : "Desbloquear"}
            description={
              isDraggable
                ? "Evite que um elemento possa ser alterado durante a edição do documento."
                : "Permita a edição do elemento"
            }
            placement="bottom-start"
          >
            <button
              onClick={() => {
                handleBlockElements();
                handleToggleBlockElement();
              }}
              className="btn-toolbar"
            >
              {isDraggable ? (
                <UnlockIcon className="width-icon-toolbar" />
              ) : (
                <LockedIcon className="width-icon-toolbar" />
              )}
            </button>
          </Tooltip>
        </Divider>

        <Divider>
          <Tooltip
            title="Excluir"
            description="Exclua um elemento que você criou."
            placement="bottom-start"
          >
            <button className="btn-toolbar" onClick={handleDeleteElement}>
              <IconToolbar
                className="width-icon-toolbar"
                src={iconTrash}
                description="Excluir"
              />
            </button>
          </Tooltip>
        </Divider>
      </ContainerRight>
    </Container>
  );
};

export default RichTextTool;

