/*
this page render our component in library 
here list all of clauses of that user and
more features like favorite clauses, edit clauses,
remove clauses, add clauses and show clauses
also search in clauses library one specific by tag of key words
*/

import React, { SetStateAction, useCallback, useEffect, useState } from "react";

import {
  Container,
  Header,
  ButtonHeader,
  Main,
  GridText,
  TableText,
  ContainerLogin,
  NoElements
} from "./styles";

import { toast, ToastContainer } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import cloneDeep from "lodash/cloneDeep";

import { useMainHook } from "../../../../hooks/main";
import api from "../../../../services/api";

import { ContractAnimation } from "../../../../shared/components/ContractAnimation";
import PersonalizedIcon from "../../../../shared/assets/customIcons/PersonalizedIcon";
import HeaderSection from "../../components/HeaderSection";
import MountIcons from "../../../../shared/utils/MountIcons";
import SelectCategory from "../../components/SelectCategory";
import SearchBar from "../../components/SearchBar";
import Pagination from "../../components/Pagination";
import ItemClause from "../components/ItemClause";
import ModalController from "../components/ModalController";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { MyClausesDataProps } from "../../../../dtos/Clauses";
import { queryClient } from "../../../../App";

//set up interfaces

export interface ObjectNewClause {
  user_clause_id?: string;
  title: string;
  clause: string;
  description: string;
  is_favorite: boolean;
  key_words?: string;
}

interface MyClauseObjectProps {
  user_clause_id: string;
  title: string;
  clause: string;
  description: string;
  is_favorite: boolean;
  key_words?: string;
  id: string;
}

interface IDataMyClausules {
  clauses: MyClausesDataProps[];
  totalPages: number;
}

const MyClausules: React.FC = () => {
  // const queryClien = useQueryClient();
  const navigator = useNavigate();
  const { loadingDebounce, debouncedSearchTerm } = useMainHook();

  //route properties
  const { number_page, filter } = useParams();
  const pageNumber = number_page === undefined ? 0 : parseInt(number_page) - 1;
  const filterCategory = filter === undefined ? "all" : filter;

  //set up states
  const [objectClause, setObjectClause] = useState<ObjectNewClause>({
    title: "",
    clause: "",
    description: "Clause by user",
    is_favorite: false,
    key_words: ""
  });
  const [modalIsOpen, setIsModalOpen] = useState(false);
  const [activeModal, setActiveModal] = useState<
    "showClausules" | "createClausules" | "editClausules" | "deleteClausules"
  >();

  //Debounce responsible for keeping the user completing the search word
  //now we need get all clauses that user and set up the main state
  //dont forget which params be used on pagination and search clauses
  //update total of pages of number received by api and generate
  //numbers of pages which will be ready for user
  const {
    data: dataMyClausules,
    isLoading,
    refetch
  } = useQuery<IDataMyClausules>(
    ["myClausules", filterCategory, pageNumber, debouncedSearchTerm],
    async () => {
      const response = await api.get("user-clause", {
        params: {
          search: debouncedSearchTerm === "" ? null : debouncedSearchTerm,
          filter: filterCategory === "all" ? null : filterCategory,
          pagination: pageNumber
        }
      });

      return {
        clauses: response.data.dataArray,
        totalPages: response.data.pagination
      };
    },
    {
      refetchOnWindowFocus: false,
      onError: err => {
        console.error(err);
      }
    }
  );

  //fixing one conflict with zIndex
  /* useEffect(() => {
    if (modalIsOpen) {
      setIsModalCreateClause(true);
    } else {
      setIsModalCreateClause(false);
    }
  }, [modalIsOpen]); */

  //when user click on clause list and we
  // need show modal with details of clause
  const handleShowClausule = useCallback(
    (item: MyClausesDataProps, index?: number) => {
      setActiveModal("showClausules");
      setObjectClause({
        user_clause_id: item.id,
        title: item.title,
        clause: item.clause,
        description: "Created by user",
        is_favorite: item.is_favorite,
        key_words: item.key_words
      });
      setIsModalOpen(true);
    },
    []
  );

  //when user click on edit clause then open modal and
  //update objectClause clause state
  const handleEditClausule = useCallback(
    (item: MyClausesDataProps, index?: number) => {
      setActiveModal("editClausules");
      setObjectClause({
        user_clause_id: item.id,
        title: item.title,
        clause: item.clause,
        description: "Created by user",
        is_favorite: item.is_favorite,
        key_words: item.key_words
      });
      setIsModalOpen(true);
    },
    []
  );

  //when user click on add new clause then
  //open modal and change kind of active modal
  const handleCreateNewClausule = useCallback(() => {
    setActiveModal("createClausules");
    setIsModalOpen(true);
  }, []);

  //just received one id about that clause and call api delete request
  const handleRemoveClause = useMutation({
    mutationFn: async (id: string) => {
      await api.delete(`user-clause/${id}`);
    },
    onSuccess: () => {
      if (pageNumber > 0 && dataMyClausules?.clauses?.length === 1) {
        navigator(`/library/${pageNumber}`);
      } else {
        refetch();
      }
    },
    onError: err => {
      toast.error("Error ao deletar texto");
      console.error(err, "error when remove clause");
    }
  });

  //Responsible for opening the delete text modal and indicating the text to be deleted
  const handleOpenModalDeleteClausule = (item: MyClausesDataProps) => {
    setActiveModal("deleteClausules");
    setObjectClause({
      user_clause_id: item.id,
      title: item.title,
      clause: item.clause,
      description: "Created by user",
      is_favorite: item.is_favorite,
      key_words: item.key_words
    });
    setIsModalOpen(true);
  };

  //this callback trigger onChange event
  //and received one state for update dynamic type value into state
  //pass it event target value of onChange
  const onChangeDynamicState = useCallback(
    (
      event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
      type: string,
      setState: React.Dispatch<SetStateAction<ObjectNewClause | string>>
    ) => {
      setState(oldState => {
        let cloneState = cloneDeep(oldState);
        cloneState[type] = event.target.value;
        return cloneState;
      });
    },
    []
  );

  //this function transform one clause as favorite clause,
  //received one id of that clause and current value
  //and update it in our endpoint api
  const handleFavoriteClause = useMutation({
    mutationFn: async ({
      id,
      title,
      clause,
      description,
      is_favorite,
      key_words
    }: MyClausesDataProps) => {
      const response = await api.put("user-clause", {
        user_clause_id: id,
        title: title,
        clause: clause,
        description: description,
        is_favorite: !is_favorite,
        key_words: key_words
      });

      return response.data as MyClauseObjectProps;
    },
    onSuccess: data => {
      const newArayClases = dataMyClausules?.clauses?.map(element => {
        if (element.id === data.id) {
          return { ...element, is_favorite: !element.is_favorite };
        }

        return element;
      });

      queryClient.setQueryData(
        ["myClausules", filterCategory, pageNumber, debouncedSearchTerm],
        { clauses: newArayClases, totalPages: dataMyClausules.totalPages }
      );
    },
    onError: err => {
      console.error(err, "Error when favoriting my clause");
    }
  });

  //when user click on save edit clause then we can update our
  //object within clause was edited and send to api
  const handleEditingClauseSpecific = useMutation({
    mutationFn: async () => {
      const response = await api.put("user-clause", objectClause);

      return response.data as MyClausesDataProps;
    },
    onSuccess: data => {
      setIsModalOpen(false);

      const newArayClases = dataMyClausules?.clauses?.map(element =>
        element.id === data.id ? data : element
      );

      queryClient.setQueryData(
        ["myClausules", filterCategory, pageNumber, debouncedSearchTerm],
        { clauses: newArayClases, totalPages: dataMyClausules.totalPages }
      );

      toast.success("Clausula editada com sucesso!");
    },
    onError: err => {
      console.error(err, "error when update clause user");
      toast.error("Ops, algo deu errado!");
    }
  });

  //when user click on create new clause, then before that user edited fields
  //send to our api endpoint to add clause, and finally reset all fields
  const handleNewClause = useMutation({
    mutationFn: async () => {
      const { data } = await api.post("user-clause", {
        title: objectClause.title,
        clause: objectClause.clause,
        description: objectClause.description,
        is_favorite: false,
        key_words: objectClause.key_words ? objectClause.key_words : undefined
      });

      return data as MyClausesDataProps;
    },
    onSuccess: data => {
      //If reaching the maximum number of text per page, a new search will be performed.
      //Because if you add it without the refetch, pagination will not appear.
      if (dataMyClausules?.clauses?.length === 12 || pageNumber !== 0) {
        refetch();
      } else {
        const newArray = cloneDeep(dataMyClausules?.clauses);
        newArray.unshift(data);

        queryClient.setQueryData(
          ["myClausules", filterCategory, pageNumber, debouncedSearchTerm],
          { clauses: newArray, totalPages: dataMyClausules.totalPages }
        );
      }

      toast.success("Clausula cadastrada com sucesso!");
      setIsModalOpen(false);
    },
    onError: err => {
      console.error(err, "error when created new clause");
      toast.error("Ops, algo deu errado!");
    }
  });

  const loading =
    isLoading ||
    loadingDebounce ||
    handleNewClause.isLoading ||
    handleEditingClauseSpecific.isLoading;

  return (
    <>
      <ToastContainer />
      <ModalController
        modalIsOpen={modalIsOpen}
        activeModal={activeModal}
        objectClause={objectClause}
        onChangeDynamicState={onChangeDynamicState}
        setObjectClause={setObjectClause}
        handleNewClause={handleNewClause.mutate}
        setIsModalOpen={setIsModalOpen}
        setActiveModal={setActiveModal}
        handleEditingClauseSpecific={handleEditingClauseSpecific.mutate}
        handleRemoveClause={handleRemoveClause.mutate}
      />
      <Container>
        <Header>
          <HeaderSection
            title="Biblioteca de texto"
            description="Aqui você pode cadastrar 
          modelos de cláusulas, teses, e textos no geral para serem arrastadas 
          com facilidade para os seus projetos sem necessidade de digitação."
          />

          <div>
            <ButtonHeader onClick={handleCreateNewClausule}>
              <PersonalizedIcon
                dPath={MountIcons.IconCrossAdd.dPath}
                viewBox={MountIcons.IconCrossAdd.viewBox}
                inactivatedColor="#FFF"
              />
              Cadastrar novo texto
            </ButtonHeader>
            <SearchBar
              screen="library"
              pageNumber={pageNumber}
              description="Digite aqui para encontrar o texto que procura."
            />
          </div>
        </Header>

        <Main>
          <SelectCategory
            category={filterCategory}
            page={number_page}
            screen="library"
          />
          <TableText>
            <div>
              <p>Título</p> <p>Categoria</p>
            </div>

            <GridText>
              {loading ? (
                <ContainerLogin>
                  <ContractAnimation />
                </ContainerLogin>
              ) : dataMyClausules?.clauses?.length === 0 ? (
                <NoElements>Nenhum texto encontrado.</NoElements>
              ) : (
                dataMyClausules?.clauses?.map((item, index) => (
                  <ItemClause
                    item={item}
                    index={index}
                    handleShowClausule={handleShowClausule}
                    handleFavoriteClause={handleFavoriteClause.mutate}
                    handleEditClausule={handleEditClausule}
                    handleRemoveClause={handleRemoveClause.mutate}
                    handleOpenModalDeleteClausule={
                      handleOpenModalDeleteClausule
                    }
                    key={item.id}
                  />
                ))
              )}
            </GridText>
          </TableText>
        </Main>
        <Pagination
          total={dataMyClausules?.totalPages}
          pageNow={pageNumber}
          screen="library"
          afterUrl={filter && filter}
        />
      </Container>
    </>
  );
};

export default MyClausules;
