import React, { useCallback, useEffect, useState } from "react";
import ReactLoading from "react-loading";

import {
  BoxModal,
  Container,
  Button,
  Text,
  Footer,
  HStack,
  Heading,
  ContainerBody,
  TextDone,
} from "./styles";
import { TitleBox } from "../../../../components/HeaderModal2FA/styles";
import {
  ButtonCancel,
  ButtonDisable,
  DescriptionText,
} from "../SecurityPage/styles";
import { CheckCircle, X } from "phosphor-react";
import api from "../../../../../../../services/api";
import { useTwoFactorAuth } from "../../../../../../../hooks/twoFactorAuth";
import { useAuth } from "../../../../../../../hooks/auth";
import { toast } from "react-toastify";
import { useTheme } from "../../../../../../../hooks/theme";

type Props = {
  setIsDisable2FA: React.Dispatch<React.SetStateAction<boolean>>;
};

const ModalDisable2FA: React.FC<Props> = ({ setIsDisable2FA }) => {
  const { setIsUserHasOTP } = useTwoFactorAuth();
  const { setData } = useAuth();
  const { theme } = useTheme();

  const [isDoneDisable, setIsDoneDisable] = useState(true);

  const [loading, setLoading] = useState(false);

  const handleDisableOTP = useCallback(async () => {
    try {
      setLoading(true);
      const responseDisableOTP = await api.post(
        "two-factor-authentication/disable"
      );
      if (responseDisableOTP.data) {
        setIsUserHasOTP(false);

        setData((oldState) => {
          return {
            ...oldState,
            user: {
              ...oldState.user,
              otp_enabled: false,
            },
          };
        });
      }

      setIsUserHasOTP(false);
      setLoading(false);
      setIsDoneDisable(true);
      // toast.success("Autenticacao de dois fatores desabilitado");
    } catch (err) {
      toast.error("Ocorreu um erro, tente mais tarde.");
      setLoading(false);
      setIsDoneDisable(true);
      console.error(err, "error when disabled");
    }
  }, [setIsUserHasOTP, setData, setIsDoneDisable]);

  useEffect(() => {
    setIsDoneDisable(false);
  }, []);

  if (isDoneDisable) {
    return (
      <Container>
        <BoxModal>
          <ContainerBody>
            <TitleBox>
              <DescriptionText style={{ fontWeight: 700, marginTop: 0 }}>
                Desabilitar autenticação de dois fatores
              </DescriptionText>

              <Button
                onClick={() => {
                  setIsDisable2FA(false);
                  setIsDoneDisable(false);
                }}
              >
                <X size={20} color="#999C9F" />
              </Button>
            </TitleBox>
            <HStack>
              <CheckCircle size={24} color="#007B36" />
              <Heading>Desativação concluída</Heading>
            </HStack>
            <TextDone>
              Método de autenticação por aplicativo{" "}
              <strong>Google Authenticator</strong> removido.
            </TextDone>
          </ContainerBody>
          <Footer>
            <ButtonDisable
              color={theme.colors.primary}
              onClick={() => {
                setIsDoneDisable(false);
                setIsDisable2FA(false);
              }}
            >
              Concluído
            </ButtonDisable>
          </Footer>
        </BoxModal>
      </Container>
    );
  }

  return (
    <Container>
      <BoxModal>
        <TitleBox>
          <DescriptionText style={{ fontWeight: 700, marginTop: 0 }}>
            Desabilitar autenticação de dois fatores
          </DescriptionText>

          <Button onClick={() => setIsDisable2FA(false)}>
            <X size={20} color="#999C9F" />
          </Button>
        </TitleBox>

        <Text>
          A desativação da autenticação de dois fatores reduz a segurança da sua
          conta.
        </Text>
        <Footer>
          <ButtonCancel onClick={() => setIsDisable2FA(false)}>
            Cancelar
          </ButtonCancel>
          <ButtonDisable color="#de2d2d" onClick={handleDisableOTP}>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <ReactLoading
                  type="spin"
                  color={"#fff"}
                  height={16}
                  width={16}
                />
              </div>
            ) : (
              `Desativar`
            )}
          </ButtonDisable>
        </Footer>
      </BoxModal>
    </Container>
  );
};

export default ModalDisable2FA;
