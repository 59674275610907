/*
this file make possibilities of user make upload of your own image
inside the UX DOC, then if user drag and drop in zone upload area
or click on button to upload images so this user will be these
image in your library
*/
import React, { useCallback, useEffect, useState } from "react";
import { useMainHook } from "../../../../hooks/main";
import ButtonPrimary from "../ButtonPrimary";
import CardElements from "../Sidebar/components/CardElements";
import { ContainerSidebarNav } from "../Sidebar/components/ContainerSidebar/styles";
import GridElements from "../Sidebar/components/GridElements";
import GridItemElements from "../Sidebar/components/GridItemElements";
import ImageUploading from "react-images-uploading";
import { Box, ButtonClose, Heading, HStack, MainImage, VStack } from "./styles";
import api from "../../../../services/api";
import { TextContent, Text } from "../ClausesView/styles";
import IconSvg from "../IconSvg";
import removeIcon from "../../assets/close.svg";
import { ContainerLoading } from "../LoadingEditor/styles";
import { ContractAnimation } from "../../../../shared/components/ContractAnimation";
import { useAsidebarEditor } from "../../hooks/asidebarEditor";
import { arrayMoveImmutable } from "array-move";
import Tooltip from "../Tooltip";
import ButtonMaxWidth from "../../../../shared/components/ButtonMaxWidth";
import { MoonLoader } from "react-spinners";
// set up interfaces
interface ImageType {
  dataURL?: string;
  file?: File;
  [key: string]: any;
}

export interface imagePropsApi {
  id: string;
  user_id: string;
  name: string;
  created_at: string;
  updated_at: string;
  image_url: string;
}

const UploadImages: React.FC = () => {
  const { dragUrl } = useMainHook();
  const { setSelectedTab } = useAsidebarEditor();
  //max number of images to drop
  const maxNumber = 69;
  const [images, setImages] = React.useState<imagePropsApi[]>([]);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [dragFile, setDragFile] = useState(false);
  const [grabbing, setGrabbing] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [newImages, setNewImages] = useState<imagePropsApi[]>([]);

  // get and manipulate images from api, and when user add new images or
  //remove images
  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const fetchImages = await api.get("user-image-repository", {
          params: {
            pagination: currentPage
          }
        });
        const listOfImages = fetchImages.data as imagePropsApi[];
        setImages(listOfImages);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    })();
  }, [forceUpdate]);

  //verifing with the api if next page exists
  useEffect(() => {
    const autoLoad = async () => {
      const response = await api.get("user-image-repository", {
        params: {
          pagination: currentPage + 1
        }
      });
      //if return length then has more pages
      if (response.data.dataArray.length > 0) {
        setHasMore(true);
      } else {
        //if return length 0 because doesn`t exists any pages more
        setHasMore(false);
      }
    };

    autoLoad();
  }, [currentPage]);

  //intercept div for generate infinity scroll
  //for pagination observer when final div appear
  useEffect(() => {
    if (!loading) {
      const intersectionObserver = new IntersectionObserver(entries => {
        if (entries.some(entry => entry.isIntersecting)) {
          if (hasMore) {
            setCurrentPage(state => state + 1);
          }
        }
      });
      //when intersection id sentinella appear
      intersectionObserver?.observe(document?.querySelector("#newUploads"));
      //cleanup function for observer
      return () => intersectionObserver?.disconnect();
    }
  }, [loading, hasMore]);

  //this callback trigger when user need do upload in the UX DOC
  const onChange = async (imageList: ImageType[]) => {
    // data for submit
    let myListOfImages: imagePropsApi[] = [];
    setDragFile(false);
    let lengthOfList = imageList.length;
    imageList.forEach(async (imageObject, index) => {
      const fetchNewImage = await api.post("user-image-repository");
      try {
        const { id, created_at, name, updated_at, user_id } =
          fetchNewImage.data as imagePropsApi;
        let formData = new FormData();
        formData.append("image", imageObject.file);
        await api.patch(`user-image-repository/image-upload/${id}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        });

        myListOfImages.push({
          created_at: created_at,
          id: id,
          image_url: imageObject.data_url,
          name: name,
          updated_at: updated_at,
          user_id: user_id
        });
      } catch (error) {
        console.error("error on uploading images", error.response.data);
      }

      if (myListOfImages.length === lengthOfList) {
        setImages(prevState => {
          let newState = [...prevState, ...myListOfImages];
          let myLength = newState.length;
          let modificationFinalArray = arrayMoveImmutable(
            newState,
            myLength,
            0
          );
          return modificationFinalArray;
        });
      }

      setForceUpdate(!forceUpdate);
    });
  };

  const handleGenerateNameFile = (item: string): string => {
    if (item === "default") {
      return "Arraste para usar";
    }

    const [, value] = item.split("-");
    const [nameFile] = value.split(".");

    return `Arraste para usar - ${nameFile.charAt(0).toUpperCase() + nameFile.slice(1).toLowerCase()
      }`;
  };

  //when user need remove image when click button remove image
  const handleRemoveImage = useCallback(
    async (id: string) => {
      try {
        await api.delete(`user-image-repository/${id}`);
        setForceUpdate(!forceUpdate);
      } catch (err) {
        // console.error(err, "ERROR when remove image upload user");
      }
    },
    [forceUpdate]
  );

  //when user clicked on escape key in keyboard then
  //we will closed modal to drag images in UI
  function handleEscapeWhenDragImages(e: KeyboardEvent) {
    if (e.key === "Escape") {
      setDragFile(false);
    }
  }

  //this use effect trigger when user was dragged any image
  //in draggable area to upload images, if that user cancel action
  //of drag new images to upload...
  useEffect(() => {
    if (dragFile) {
      document.addEventListener("keydown", handleEscapeWhenDragImages, true);
    }
    //just clean up event listener
    return () => {
      document.removeEventListener("keydown", handleEscapeWhenDragImages, true);
    };
  }, [dragFile]);
  return (
    <ImageUploading
      multiple
      value={uploadedImages}
      onChange={onChange}
      maxNumber={maxNumber}
      dataURLKey="data_url"
    >
      {({ onImageUpload, isDragging, dragProps }) => (
        <ContainerSidebarNav
          onDragOver={() => {
            if (!grabbing) {
              setDragFile(true);
            }
          }}
          onMouseOut={() => {
            setGrabbing(false);
          }}
          style={{ position: "relative" }}
        >
          <>
            {dragFile && !grabbing && (
              <div
                onMouseOut={() => {
                  setDragFile(false);
                }}
                style={{
                  width: "100%",
                  height: "100vh",
                  background: "rgba(0, 0, 0, 0.8)",
                  position: "absolute",
                  zIndex: "990",
                  left: "0",
                  top: "0",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}
                {...dragProps}
              >
                <h1 style={{ color: "white", transform: "translateY(-50px)" }}>
                  Arraste aqui seus uploads!
                </h1>
              </div>
            )}
            <VStack>
              <div className="App">
                <div className="upload__image-wrapper">
                  <ButtonMaxWidth
                    text={
                      isDragging ? "Pode soltar as imagens!" : "Fazer upload"
                    }
                    onClick={onImageUpload}
                    typeStyle="purple"
                  />
                </div>
              </div>
            </VStack>

            {loading ? (
              <ContainerLoading>
                <ContractAnimation />
              </ContainerLoading>
            ) : (
              <HStack>
                {images.length > 0 && !loading ? (
                  <GridElements columns={4} className="grid-upload-images">
                    {images.map((image, index) => (
                      <GridItemElements
                        isFixSize
                        className="wrapper-upload-images"
                        key={image.id}
                      >
                        <Tooltip title="Excluir" placement="bottom-start">
                          <ButtonClose
                            onClick={() => handleRemoveImage(image.id)}
                          >
                            <IconSvg src={removeIcon} description="" />
                          </ButtonClose>
                        </Tooltip>
                        <CardElements>
                          <Tooltip
                            title={handleGenerateNameFile(image.name)}
                            placement="auto"
                          >
                            <MainImage src={image.image_url}>
                              <img
                                draggable
                                src={image.image_url}
                                style={{
                                  opacity: 0,
                                  width: "100%",
                                  height: "100%",
                                  margin: "auto auto"
                                }}
                                alt={`img#@@#${image.name}`}
                                onDragStart={(
                                  e: React.DragEvent<HTMLImageElement>
                                ) => {
                                  setGrabbing(true);
                                  dragUrl.current = e.target;
                                }}
                              />
                            </MainImage>
                          </Tooltip>
                        </CardElements>
                      </GridItemElements>
                    ))}
                  </GridElements>
                ) : (
                  loading && null
                )}
              </HStack>
            )}
            <li
              id="newUploads"
              className="testing"
              style={{
                height: "80px",
                display: "flex",
                marginBottom: "4rem",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              {hasMore && <MoonLoader size={14} color={"#000"} />}
            </li>

            {!loading && images.length < 1 && (
              <Box>
                <TextContent>
                  <Text>Você ainda não inseriu imagens no seu projeto.</Text>
                </TextContent>
              </Box>
            )}
          </>
        </ContainerSidebarNav>
      )}
    </ImageUploading>
  );
};

export default UploadImages;
