import { HTMLProps } from "react";
import styled from "styled-components";
import { Theme } from "../../../../dtos/theme";

interface VStackProps extends HTMLProps<HTMLDivElement> {
  margin?: string;
  height?: string;
}

export const MainImage = styled.div<{ src?: string }>`
  display: flex;
  justify-content: center; /* Centraliza horizontalmente */
  align-items: center; /* Centraliza verticalmente */
  width: 60px;
  height: 60px;
  background-image: ${({ src }) => src && `url(${src})`};
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center; /* Centraliza a imagem dentro da div */
`;

export const VStack = styled.div<VStackProps>`
  display: flex;
  flex-direction: column;
  width: 100%;

  justify-content: space-between;
  padding: 10px;

  button {
    height: 30px;
  }
`;
export const GridItem = styled.div`
  width: 100%;
  height: 100%;
  max-height: 128px;
  max-width: 133px;

  min-height: 128px;
  min-width: 133px;
  display: flex;
  position: relative;
  border-radius: 1rem;
  align-items: center;
  justify-content: center;

  .close-icon {
    position: absolute;
    right: 5px;
    top: 5px;
    width: 12px;
    height: 12px;
    cursor: pointer !important;
    z-index: 300;
  }
`;

export const ButtonClose = styled.button`
  position: absolute;
  right: 5px;
  top: 5px;
  cursor: pointer !important;
  z-index: 300;
  background-color: transparent;

  img {
    width: 12px;
    height: 12px;
  }
`;

export const HStack = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;

  overflow-y: auto;

  .grid-upload-images {
    background-color: #fff;
    border-radius: 5px;
    padding: 10px;
  }
`;

export const Heading = styled.div`
  font-size: 1.8rem;
  margin: 1.8rem 0 1.8rem 1.6rem;
  color: ${({ theme }: Theme) => theme.colors.black};

  @media (max-width: 1600px) {
    font-size: 1.6rem;
    margin: 1.8rem 0 1.5rem 1.6rem;
  }

  @media (max-width: 1366px) {
    font-size: 1.5rem;
    margin: 1.8rem 0 1.3rem 1.6rem;
  }
`;

export const Center = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Box = styled.div`
  width: 100;
  height: 100%;
  display: flex;
`;
