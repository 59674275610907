import { useCallback, useState } from "react";
import { cloneDeep } from "lodash";
import { toast } from "react-toastify";
import { Link, useLocation } from "react-router-dom";

import { Container, Modal, Item } from "./styles";

import api from "../../../../../../../services/api";
import { useSign } from "../../../../../../../hooks/sign";
import IconDocument from "../../../../assets/quick_reference-icon.svg";

import PersonalizedIcon from "../../../../../../../shared/assets/customIcons/PersonalizedIcon";
import MountIcons from "../../../../../../../shared/utils/MountIcons";
import DowloadICon from "../../../../../assets/dowload-icon.svg";
import IconRestore from "../../../../assets/restore_from_trash.svg";
import {
  IModalSign,
  SignDataProps,
} from "../../../../../../../dtos/signedProps";
import { useMetric } from "../../../../../../../hooks/metric";
import { AxiosResponse } from "axios";
import moment from "moment";

interface IOptionsProps {
  setOpenModal: React.Dispatch<React.SetStateAction<IModalSign>>;
  sign: SignDataProps;
}

interface ResponseDocSpecificProps {
  signature: {
    id: string;
    title: string;
    status: string;
    final_date: string;
    document_name: string;
    created_at: string;
    updated_at: string;
    document_base64: string;
    thumbnail_url: string;
  };
  signature_users: [
    {
      id: string;
      first_name: string;
      last_name: string;
      email: string;
      is_signed: boolean;
      document: null | string;
      is_individual: boolean;
      created_at: string;
      updated_at: string;
    }
  ];
  signature_log: [
    {
      id: string;
      event: string;
      created_at: string;
      updated_at: string;
    }
  ];
}

const Options = ({ setOpenModal, sign }: IOptionsProps) => {
  //route properties
  const location = useLocation();
  const screenNowSignature = location.pathname.split("/")[2];

  const {
    setDocToRemove,
    setDataSpamDocs,
    setAmountSignDocs,
    setRefreshPage,
    refreshPage,
  } = useSign();
  const { handleDowloadProjectAction } = useMetric();

  const [isOpen, setIsOpen] = useState(false);

  const handleDownloadPDF = useCallback(
    async (document_id: string, title: string) => {
      try {
        toast.promise(
          api.get(`signatures/${document_id}`).then(async (response) => {
            const { signature, signature: { status } } = response.data;

            const signatureAssigner = status === 'Concluído';

            if (signature.document_base64) {
              try {
                const arrayBuffer = Uint8Array.from(
                  atob(signature.document_base64),
                  (c) => c.charCodeAt(0)
                );

                const blob = new Blob([arrayBuffer], {
                  type: "application/pdf",
                });
                const fileUrl = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = fileUrl;
                link.download = `${signatureAssigner ? title.replaceAll(".", "-") + '_assinado' : title.replaceAll(".", "-")}`;
                link.click();
                window.URL.revokeObjectURL(fileUrl);
                // register action to metrics
                handleDowloadProjectAction({
                  project_id: document_id,
                  automated: false,
                  type: "pdf",
                  date: moment().format('YYYY-DD-MM'),
                  dateTime: moment().format('HH:mm:ss'),
                });
              } catch (err) {
                console.error(err, "error creating PDF blob");
                // throw new Error("Erro ao criar o PDF. Tente novamente.");
              }
            } else {
              // throw new Error("Base64 não encontrado no documento.");
            }
          }),
          {
            pending: "Baixando seu documento...",
            success: "Download concluído.",
            error: "Deu erro, tente novamente mais tarde.",
          }
        );
      } catch (err) {
        toast.error("Apenas um erro. Tente novamente depois.");
      }
    },
    []
  );

  const handleRestoreDoc = useCallback(
    async (id: string) => {
      try {
        const responseRestoreDoc = await api.put(`signature-trash`, {
          signatures: [
            {
              signature_id: id,
            },
          ],
        });

        setIsOpen(!isOpen);
        setRefreshPage(!refreshPage);
        toast.success("Documento restaurado com sucesso.");
      } catch (err) {
        console.error(err.response, "error when update restore doc spam docs");
        toast.error("Erro ao restaurar documento.");
        setIsOpen(!isOpen);
      }
    },
    [isOpen, setRefreshPage, refreshPage]
  );

  return (
    <Container onClick={() => setIsOpen(!isOpen)} isActive={isOpen}>
      <span />
      <span />
      <span />

      {isOpen && (
        <Modal>
          <Item>
            <Link to={`/view-signature/${sign.signature.id}`}>
              <img src={IconDocument} alt="" />
              Detalhes
            </Link>
          </Item>

          <Item
            onClick={() =>
              handleDownloadPDF(sign.signature.id, sign.signature.title)
            }
          >
            <div>
              <img src={DowloadICon} alt="" /> Baixar
            </div>
          </Item>

          {screenNowSignature === "trash" && (
            <Item
              onClick={() => {
                handleRestoreDoc(sign.signature.id);
              }}
            >
              <div>
                <img src={IconRestore} alt="" />
                Restaurar
              </div>
            </Item>
          )}

          <Item
            onClick={() => {
              setDocToRemove(sign.signature.id);
              setOpenModal({
                isOpen: true,
                modalName: "Delete document",
              });
              setIsOpen(!isOpen);
            }}
          >
            <div>
              <PersonalizedIcon
                dPath={MountIcons.IconTrash.dPath}
                viewBox={MountIcons.IconTrash.viewBox}
                inactivatedColor="#676B70"
              />
              {screenNowSignature === "trash"
                ? `Excluir definitivamente`
                : `Excluir`}
            </div>
          </Item>
        </Modal>
      )}
    </Container>
  );
};

export default Options;

