import React, { useEffect, useState } from "react";
import {
  ConfirmButton,
  DeadlineLabel,
  FlowContainer,
  Header,
  LoadingContainer,
  StepActiveArrow,
  StepNormalText,
  StepTextWrapper,
  StepWrapper,
  StepsFlexWrapper,
  THead,
  Table,
  TableActions,
  TableContainer,
  TableData,
  TableHead,
  TableHeader,
  TableRow,
  TablesWrapper,
  Title
} from "./styles";
import { ApprovalFlow, Step } from "../../types/ApprovalFlow";
import AddStep from "../AddStep";
import { useNavigate, useParams } from "react-router-dom";
import StepIconSmall from "../StepIconSmall";
import EditIcon from "../EditIcon";
import TrashIcon from "../TrashIcon";
import { ContractAnimation } from "../../../../../shared/components/ContractAnimation";
import api from "../../../../../services/api";
import {
  ApprovalFlowRequest,
  StepRequest
} from "../../types/ApprovalFlowRequest";
import { Box, Stack } from "@mui/material";
import { BiChevronLeft } from "react-icons/bi";
import { ToastContainer } from "react-toastify";
import { Container, FullContent } from "../../styles";
import { useAuth } from "../../../../../hooks/auth";
import DeleteStep from "../DeleteStep";
import OneClick from "./../../../oneclick/pages/index";

const FlowEdit: React.FC = () => {
  const navigate = useNavigate();
  const params = useParams<{ id: string }>();
  const [loading, setLoading] = useState(true);

  const [open, setOpen] = useState(false);
  const [flow, setFlow] = useState<ApprovalFlowRequest>();
  const [steps, setSteps] = useState<StepRequest[]>([]);
  const [activeStep, setActiveStep] = useState<StepRequest>();
  const [isEditing, setIsEditing] = useState(false);
  const { data } = useAuth();
  const [isUserEditor, setIsUserEditor] = useState(false);
  const [deleteStepOpen, setDeleteStepOpen] = useState(false);

  const editStep = () => {
    setIsEditing(true);
    setOpen(true);
  };

  useEffect(() => {
    const fetchSteps = async () => {
      setLoading(true);
      const response = await api.get(`/approval-flows/${params.id}`);
      const responseData = response.data as ApprovalFlow;
      const flowParsed = mapToRequest(responseData);
      setIsUserEditor(
        flowParsed.members.some(
          member =>
            member.member_id === data?.user.id && member.role === "editor"
        )
      );
      setFlow(flowParsed);
      setSteps(flowParsed.steps);
      setActiveStep(flowParsed.steps[0]);
      setTimeout(() => {
        setLoading(false);
      }, 300);
    };
    fetchSteps();
  }, [data?.user.id, params.id]);

  const back = () => {
    navigate("/approvalflow/my-flows", { replace: true });
  };

  const updateFlow = async (newSteps: StepRequest[]) => {
    try {
      setLoading(true);
      const updatedFlow = { ...flow, steps: newSteps } as ApprovalFlowRequest;
      await api.put(`/approval-flows`, updatedFlow);
      setTimeout(() => {
        setLoading(false);
        if (newSteps.length > 0) setActiveStep(newSteps[0]);
        else navigate(`/approvalflow/${updatedFlow.id}/flow-steps`);
      }, 300);
    } catch (error) {
      console.error(error);
    }
  };

  const mapToRequest = (flow: ApprovalFlow): ApprovalFlowRequest => {
    const mappedMembers = flow.members.map(member => {
      const { first_name, last_name, created_at, updated_at, email, ...rest } =
        member;
      return rest;
    });

    const mappedSteps = flow.steps.map(step => {
      const { created_at, updated_at, requirements, approvers, ...rest } = step;
      const mappedRequirements = requirements.map(requirement => {
        const { created_at, updated_at, ...rest } = requirement;
        return rest;
      });
      const mappedApprovers = approvers.map(approver => {
        const { created_at, updated_at, ...rest } = approver;
        return rest;
      });
      return {
        ...rest,
        requirements: mappedRequirements,
        approvers: mappedApprovers
      };
    });

    return {
      id: flow.id,
      name: flow.name,
      steps: mappedSteps,
      members: mappedMembers,
      is_template: flow.is_template,
      status: flow.status
    };
  };

  const deleteStep = () => {
    setDeleteStepOpen(true);
  };

  return (
    <FullContent>
      <ToastContainer />
      <Container>
        <FlowContainer>
          {!loading && (
            <Header>
              <Stack direction="row" alignItems={"center"}>
                <Box style={{ cursor: "pointer" }} onClick={back}>
                  <BiChevronLeft fontSize={"24px"} />
                </Box>
                <Title>{flow?.name}</Title>
              </Stack>
            </Header>
          )}

          {loading && (
            <LoadingContainer>
              <ContractAnimation />
            </LoadingContainer>
          )}
          <StepsFlexWrapper>
            {!loading &&
              steps.map(step => (
                <StepWrapper key={step.id} onClick={() => setActiveStep(step)}>
                  <StepIconSmall
                    color="#7547A3"
                    active={activeStep?.id === step.id}
                  />
                  <StepTextWrapper>
                    <StepNormalText>{step.name}</StepNormalText>
                  </StepTextWrapper>
                  {activeStep?.id === step.id && <StepActiveArrow />}
                </StepWrapper>
              ))}
          </StepsFlexWrapper>
          {!loading && activeStep && (
            <TablesWrapper>
              <TableHeader>
                <DeadlineLabel>
                  Prazo para aprovação: {activeStep.deadline} dias
                </DeadlineLabel>

                {isUserEditor && (
                  <TableActions>
                    <Box onClick={editStep}>
                      <EditIcon width="24px" height="24px" color="#999C9F" />
                    </Box>
                    <Box onClick={deleteStep}>
                      <TrashIcon />
                    </Box>
                  </TableActions>
                )}
              </TableHeader>

              <TableContainer>
                <Table>
                  <THead>
                    <tr>
                      <TableHead style={{ width: "30%" }}>
                        Requisitos de aprovação
                      </TableHead>
                      <TableHead>Descrição</TableHead>
                    </tr>
                  </THead>
                  <tbody>
                    {activeStep.requirements.map(requirement => (
                      <TableRow key={requirement.id}>
                        <TableData>{requirement.name}</TableData>
                        <TableData>{requirement.description}</TableData>
                      </TableRow>
                    ))}
                  </tbody>
                </Table>
              </TableContainer>
              <TableContainer>
                <Table>
                  <THead>
                    <tr>
                      <TableHead style={{ width: "30%" }}>
                        Aprovadores
                      </TableHead>
                      <TableHead>Email</TableHead>
                    </tr>
                  </THead>
                  <tbody>
                    {activeStep.approvers.map(approver => (
                      <TableRow key={approver.id}>
                        <TableData>{approver.name}</TableData>
                        <TableData>{approver.email}</TableData>
                      </TableRow>
                    ))}
                  </tbody>
                </Table>
              </TableContainer>
            </TablesWrapper>
          )}

          {!loading && <ConfirmButton onClick={back}>Voltar</ConfirmButton>}

          {open && (
            <AddStep
              open={open}
              step={activeStep}
              setStep={setActiveStep}
              onClose={(step?: Step) => {
                if (step) {
                  const index = steps.findIndex(s => s.id === step.id);
                  const newSteps = [...steps];
                  newSteps[index] = step;
                  setSteps(newSteps);
                  updateFlow(newSteps);
                }
                setOpen(false);
              }}
              stepNumber={steps.length + 1}
              isEditing={isEditing}
              flow_id={params.id}
            />
          )}

          {deleteStepOpen && (
            <DeleteStep
              open={deleteStepOpen}
              onClose={() => setDeleteStepOpen(false)}
              onConfirm={() => {
                const newSteps = steps.filter(
                  step => step.id !== activeStep?.id
                );
                setSteps(newSteps);
                updateFlow(newSteps);
                setDeleteStepOpen(false);
              }}
            />
          )}
        </FlowContainer>
      </Container>
    </FullContent>
  );
};

export default FlowEdit;
