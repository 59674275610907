import { Link } from "react-router-dom";
import styled from "styled-components";
const getColorByIndex = (index: number) => {
  const colors = ["#24958E", "#F90", "#06C", "#663399"];
  const colorIndex = (index - 1) % colors.length;
  return colors[colorIndex];
};

export const Container = styled.li`
  display: grid;
  grid-template-columns: 1.5fr 0.4fr 0.4fr 0.3fr;
  align-items: center;
  border-bottom: solid 1px #ebebec;
  position: relative;

  p {
    font-family: "Ubuntu";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    color: ${({ theme }) => theme.colors.black100};
  }

  :hover {
    background-color: #f5f5f5;
  }
`;

export const TitleItem = styled(Link)`
  padding-inline: 16px;
  height: 100%;

  display: flex;
  align-items: center;
  gap: 14px;
  text-decoration: none;

  p {
    height: 100%;
    width: 100%;

    display: flex;
    align-items: center;

    cursor: pointer;
  }

  img,
  svg {
    width: 24px;
    height: 24px;

    cursor: pointer;
  }
`;

export const OptionsItem = styled.div<{ isActive: boolean }>`
  cursor: pointer;
  width: 40px;
  height: 40px;

  position: absolute;
  bottom: 1px;
  right: 2px;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;

  span {
    background-color: ${({ theme, isActive }) =>
      isActive ? theme.colors.primary90 : theme.colors.black75};
    width: 3px;
    height: 3px;
    border-radius: 50%;
  }

  :hover {
    background-color: ${({ theme }) => theme.colors.black10};
    border-radius: 5px;
  }
`;

export const Modal = styled.div`
  position: absolute;
  z-index: 2;
  top: 40px;
  right: 0;

  background-color: ${({ theme }) => theme.colors.white100};
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  width: 117px;

  div {
    height: 44px;
    padding: 15px;

    display: flex;
    align-items: center;
    gap: 8px;

    font-family: "Ubuntu";
    font-size: 14px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.black75};

    :first-child {
      svg {
        transform: scale(1.3);
      }
    }

    :hover {
      background-color: ${({ theme }) => theme.colors.black5};
    }
  }

  svg {
    height: 24px;
    width: 24px;
  }
`;

export const SubscriberBox = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  .avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid #fff;
    user-select: none;

    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #fff;
    transform: translateX(-5px);
  }

  .avatar span {
    color: #fff;
    text-align: center;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid #fff;
    user-select: none;

    position: relative;
  }

  img:nth-child(2n) {
    left: -6px;
  }
  img:nth-child(3n) {
    left: -12px;
  }
  img:nth-child(4n) {
    left: -18px;
  }
`;

export const AvatarCustom = styled.div<{ avatar_url?: string, isExpand?: boolean }>`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #fff;
  user-select: none;
  background-color: #ccc; /* Cor de fallback caso a imagem não carregue */
  background-image: url(${({ avatar_url }) => avatar_url});
  background-size: cover;
  background-position: center;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${({ isExpand  }) => isExpand ? 'pointer' : 'default' }}

  &:nth-child(1) {
    transform: translateX(0);
  }

  &:nth-child(2) {
    transform: translateX(-5px);
  }

  &:nth-child(3) {
    transform: translateX(-10px);
  }

  &:nth-child(4) {
    transform: translateX(-15px);
  }

  span {
    color: #fff;
    text-align: center;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;


export const Users = styled.div``;
