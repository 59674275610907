import { Box, Stack } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Label, Link } from "./styles";
import Logo from "../../../../../shared/assets/uxlogotipo-large.svg";
import { ContractAnimation } from "../../../../../shared/components/ContractAnimation";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import api from "../../../../../services/api";

const RequestToken: React.FC = () => {
  const [token, setToken] = useState("");
  const [numbers, setNumbers] = useState(["", "", "", "", "", ""]);
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const [loading, setLoading] = useState(false);
  const { approver_id } = useParams();
  const navigator = useNavigate();

  const handlePaste = (
    event: React.ClipboardEvent<HTMLInputElement>,
    index: number
  ) => {
    const paste = event.clipboardData.getData("text");
    if (paste.length === numbers.length) {
      event.preventDefault();
      const newNumbers = paste
        .split("")
        .map(num => (num.match(/\d/) ? num : ""));
      setNumbers(newNumbers);
      const nextIndex = newNumbers.findIndex(
        (num, i) => num === "" && i > index
      );
      if (nextIndex !== -1) {
        inputRefs.current[nextIndex]?.focus();
      } else if (newNumbers[newNumbers.length - 1] !== "") {
        inputRefs.current[newNumbers.length - 1]?.focus();
      }
    }
  };

  const handleTokenChange = (value: string, index: number) => {
    const newNumbers = [...numbers];
    newNumbers[index] = value;
    setNumbers(newNumbers);
    setToken(newNumbers.join(""));
    if (value.length === 1 && index < numbers.length - 1) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  useEffect(() => {
    const handleSubmit = () => {
      setTimeout(() => {
        setLoading(true);
      }, 500);

      setTimeout(() => {
        setLoading(false);
        const finalToken = numbers.join("");
        navigator(`/fluxo-de-aprovacao/${approver_id}/${finalToken}`);
      }, 3000);
    };

    // Check if all inputs have been filled
    const allFilled = numbers.every(num => num !== "");
    if (allFilled) {
      handleSubmit();
    }
  }, [approver_id, navigator, numbers, token]);

  useEffect(() => {
    let hasFetched = false
    const fetchToken = async () => {
      if (hasFetched) return;
      hasFetched = true;
      try {
        await api.post(
          `/approval-projects/${approver_id}/generate-token`
        );
      } catch (error) {
        console.error(error);
        toast.error("Erro ao enviar token");
      }
    };
    fetchToken();
  }, [approver_id]);

  return (
    <Box
      display={"flex"}
      height={"100vh"}
      width={"100vw"}
      alignItems={"center"}
      justifyContent={"center"}
      bgcolor={"#ffffff"}
    >
      <ToastContainer />
      {loading && <ContractAnimation />}
      {!loading && (
        <Stack spacing={2} maxWidth={"400px"} alignItems={"center"}>
          <img src={Logo} alt="Logo UXDOC" width={"150px"} />
          <Label>Por favor, digite o código que enviamos para seu email</Label>
          <Stack spacing={2} direction={"row"}>
            {numbers.map((number, index) => (
              <input
                type="text"
                id="tokenInput"
                value={number}
                onChange={event => handleTokenChange(event.target.value, index)}
                onPaste={event => handlePaste(event, index)}
                ref={el => (inputRefs.current[index] = el)}
                pattern="[0-9]{6}"
                key={index}
                maxLength={1}
                required
                style={{
                  width: "41px",
                  height: "47px",
                  border: "1px solid #A5A5A5",
                  textAlign: "center",
                  fontSize: "16px",
                  fontWeight: "600"
                }}
              />
            ))}
          </Stack>
          <Stack alignItems={"center"} spacing={1}>
            <Label>Não encontrou?</Label>
            <Label>Solicite novamente.</Label>
          </Stack>

          <Link>Sair</Link>
        </Stack>
      )}
    </Box>
  );
};

export default RequestToken;
