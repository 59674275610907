import styled from "styled-components";
import { Theme } from "../../../../dtos/theme";
import { ButtonHTMLAttributes } from "react";

interface BoxColorProps {
  color: string;
}

interface ProfileUserProps {
  isAnnonymous?: boolean;
}

const getColorByIndex = (index: number) => {
  const colors = ["#24958E", "#F90", "#06C", "#663399"];
  const colorIndex = (index - 1) % colors.length;
  return colors[colorIndex];
};

export const Button = styled.button<{
  typeStyle: "purple" | "white";
}>`
  width: 100%;
  max-height: 40px;
  background-color: ${({ typeStyle, theme }) =>
    typeStyle === "purple" ? theme.colors.primary90 : theme.colors.white100};
  color: ${({ typeStyle, theme }) =>
    typeStyle === "purple" ? theme.colors.white100 : theme.colors.primary90};
  padding: 13px 16px;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.colors.primary90};

  font-family: "Ubuntu";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  :hover {
    background-color: ${({ typeStyle, theme }) =>
      typeStyle === "purple" ? theme.colors.primary : theme.colors.white100};
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
  }
`;

export const LoadingFlex = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;


export const ContainerModal = styled.form`
  width: 700px;
  border-radius: 5px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
  background: ${({ theme }: Theme) => theme.colors.whiteColor};
  padding-block: 20px;

  display: flex;
  flex-direction: column;
  gap: 20px;

  .icon-exit {
    position: absolute;
    right: 13px;
    top: 13px;
  }

  ::-webkit-scrollbar {
    width: 05px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: gray;
    border-radius: 130px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.colors.background};
  }
`;

export const Header = styled.div`
  padding-inline: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ebebec;
`;

export const Box = styled.div`
  display: flex;
  width: 100%;
  min-height: 73px;
  border-radius: 10px;
  align-items: center;
  border: solid 1px red;
  padding: 0 1.6rem;
  line-height: 190%;
  justify-content: center;
`;

export const Text = styled.span`
  color: black;
  font-size: 1.4rem;
  text-align: center;
  max-width: 783px;
  margin-bottom: 1rem;
`;

export const Wrapper = styled.div`
  display: flex;
  background: red;
  width: 100%;
  justify-content: space-between;
`;

export const TextArea = styled.textarea`
  width: 100%;
  min-height: 227px;
  padding: 1rem 2rem;
  border-radius: 20px;

  &::placeholder {
    font-size: 1.6rem;
    font-weight: 400;
    color: ${({ theme }: Theme) => theme.colors.gray900};
  }
`;

export const HStack = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 2rem;
`;

export const HStackBtn = styled.div`
  display: flex;
  width: 160px;
  justify-content: center;
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;

export const InputHex = styled.input`
  width: 100px;
  height: 30px;
  padding: 0 0.5rem;

  &::placeholder {
    color: #c4c4c4;
    opacity: 0.6;
  }
`;

export const BoxColor = styled.div<BoxColorProps>`
  width: 30px;
  height: 30px;
  border-radius: 5px;
  background: ${({ theme, color }) =>
    color == ""
      ? "conic-gradient(red, yellow, lime, aqua, blue, magenta, red)"
      : "red"};
  cursor: not-allowed;
  border: 1px solid black;
`;

export const ButtonBack = styled.button`
  width: 120px;
  height: 40px;
  background: white;
  color: black;
  border: 1px solid black;
  border-radius: 5px;
`;

export const Title = styled.span`
  font-weight: 400;
  font-size: 1.6rem;
  color: #343a40;

  @media (max-width: 1366px) {
    font-size: 1.4rem;
  }
`;

export const Line = styled.div`
  border-bottom: 1px solid #a5a5a5;
  width: 100%;
  height: 5px;
  margin: 1rem auto;
`;

export const HorizontalStack = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
`;

export const InputBase = styled.input`
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: 1px solid #a5a5a5;
  border-radius: 10px;
  padding: 0 1rem;

  ::placeholder {
    color: #a5a5a5;
  }
`;

export const FlexInput = styled.div`
  display: flex;
  width: 80%;
  height: 100%;
  padding-right: 1rem;
`;

export const ButtonShared = styled.button`
  height: 40px;
  background-color: black;
  color: white;
  padding: 0 1rem;
  margin-left: auto;
  border-radius: 10px;
  min-width: 130px;

  transition: background 120ms linear;

  :hover {
    background: ${({ theme }) => theme.colors.background};
  }
`;

export const VerticalStack = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  margin-top: 1rem;
  flex-direction: column;

  @media (max-width: 1366px) {
    margin-top: 0;
  }
`;

export const WrapperProfileShared = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  height: 49px;
  margin: 0.5rem 0rem;

  .removeItem {
    width: 16px;
    height: 16px;
    transform: translateY(3px);
  }

  @media (max-width: 1600px) {
    .removeItem {
      width: 14px;
      height: 14px;
      transform: translateY(1px);
    }
  }

  @media (max-width: 1366px) {
    margin: 0.1rem 0rem;

    .removeItem {
      width: 12px;
      height: 12px;
      transform: translateY(1px);
    }
  }
`;

export const AvatarContainer = styled.div`
  width: 10%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const NameContainer = styled.div`
  display: flex;
  flex: 1;
  height: 100%;

  align-items: center;
`;

export const ProfileUser = styled.span<ProfileUserProps>`
  font-size: 1.4rem;
  color: #343a40;
  align-items: center;
  margin-left: 1rem;
  opacity: ${({ isAnnonymous }) => isAnnonymous && "0.2"};

  @media (max-width: 1366px) {
    font-size: 1.2rem;
    margin-left: 0;
  }
`;

export const Table = styled.div`
  border-radius: 5px 5px 0px 0px;
  border: 1px solid ${({ theme }) => theme.colors.black20};
  margin-inline: 20px;

  & > div {
    height: 30px;
    border-radius: 5px 5px 0px 0px;
    background-color: ${({ theme }) => theme.colors.black10};

    display: flex;
    align-items: center;

    display: grid;
    grid-template-columns: 4fr 4fr 2.7fr 0.5fr;

    font-family: "Ubuntu";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    color: ${({ theme }) => theme.colors.black100};

    p:first-child {
      padding-left: 54px;
    }
  }

  ul {
    display: flex;
    flex-direction: column;
    min-height: 44px;
    max-height: 132px;

    overflow-y: auto;
  }

  .containerLoading {
    width: 100%;
    height: 44px;

    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const ItemUserInfo = styled.li`
  display: grid;
  grid-template-columns: 4fr 4fr 2.7fr 0.5fr;
  align-items: center;
  min-height: 44px;

  span {
    color: #a5a5a5;
  }

  .email {
    color: #999c9f;

    max-width: 23ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
    font-weight: 400;
    line-height: 150%;
    text-align: left;
  }
`;

export const FirstAreaItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding-left: 15px;

  p {
    max-width: 23ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
    font-weight: 400;
    line-height: 150%;
    text-align: left;
  }
`;

export const AvatarCustom = styled.div<{ indexOfUser: number }>`
  min-width: 30px;
  min-height: 30px;
  border-radius: 50%;
  border: 1px solid #fff;
  user-select: none;
  background-color: ${({ indexOfUser }) => getColorByIndex(indexOfUser)};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #fff;

  &:nth-child(1) {
    transform: translateX(0);
  }

  &:nth-child(2) {
    transform: translateX(-5px);
  }

  &:nth-child(3) {
    transform: translateX(-10px);
  }

  span {
    color: #fff;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;

export const Trash = styled.div`
  width: 24px;
  height: 24px;

  svg {
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  padding-inline: 20px;
  margin-top: 10px;
`;
