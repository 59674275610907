import React, { useCallback, useEffect, useState } from "react";
import Modal from "react-modal";
import ButtonPrimary from "../ButtonPrimary";
import { InputModal } from "../InputModal";
import Switch from "react-switch";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  AvatarContainer,
  AvatarCustom,
  Box,
  BoxColor,
  Button,
  ButtonBack,
  ButtonDeleteUserShared,
  ButtonShared,
  ContainerModal,
  ControlEditionsButton,
  FirstAreaItem,
  FlexInput,
  Footer,
  FooterWraper,
  Header,
  HorizontalStack,
  HStack,
  HStackBtn,
  InputBase,
  InputHex,
  ItemUserInfo,
  Left,
  Line,
  NameContainer,
  ProfileUser,
  Right,
  Table,
  Text,
  TextArea,
  Title,
  Trash,
  VerticalStack,
  WrapperProfileShared,
} from "./styles";
import { AnimatePresence, motion } from "framer-motion";
import * as yup from "yup";
import api from "../../../../services/api";
import { useModalContext } from "../../../../hooks/modalContext";
import { useSelection } from "../../hooks/selection";

import { useParams } from "react-router-dom";
import { useMainHook } from "../../../../hooks/main";
import PuffLoader from "react-spinners/PuffLoader";
import { useForm, SubmitHandler, Controller, useWatch } from "react-hook-form";
import LoadingEditor from "../LoadingEditor";
import ReactLoading from "react-loading";
import { useSharedDocument } from "../../hooks/sharedDocument";
import IconSvg from "../IconSvg";
import closeIcon from "../../assets/close.svg";
import deleteIcon from "../../assets/deleteIcon.svg";
import useComponentVisible from "../../hooks/useComponentVisible";
import { getColors } from "../../utils/getColorAvatar";
import { Circle, Heart, Ring } from "react-spinners-css";
import { cloneDeep } from "lodash";
import { toast, ToastContainer } from "react-toastify";
import { useHeaderEditor } from "../../hooks/headerEditor";
import verifyIcon from "../../assets/checkicon.svg";
import { useDiff } from "../../hooks/diffDocuments";
import MultiplesAvatarLetter from "../../../dashboard/master/components/MultiplesAvatarLetter";
import { useMetric } from "../../../../hooks/metric";
import ButtonExit from "../../../../shared/components/ButtonExit";
import TitleH5 from "../../../../shared/components/TitleH5";
import PlainText from "../../../../shared/components/PlainText";
import PersonalizedIcon from "../../../../shared/assets/customIcons/PersonalizedIcon";
import MountIcons from "../../../../shared/utils/MountIcons";
import ButtonMaxWidth from "../../../../shared/components/ButtonMaxWidth";
import Tooltip from "../Tooltip";

interface SubmitProps {
  email: string;
}

interface UsersSharedProps {
  id?: string;
  name?: string;
  email?: string;
  avatar_url?: string;
  permissions?: string;
  is_anonymous?: boolean;
  color?: string;
}

interface ShareTemplateUsersProps {
  user_template_id: string;
  users: UsersSharedProps[];
}

interface ModalSharedDocumentProps {
  isOpen: boolean;
  onRequestClose: () => void;
}

export const ModalSharedDocument: React.FC<ModalSharedDocumentProps> = ({
  isOpen,
  onRequestClose,
}) => {
  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        delayChildren: 0.5,
        type: "spring",
        duration: 1,
        stiffness: 100,
        staggerDirection: -1,
      },
    },
  };

  const item = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        delayChildren: 1.9,
        type: "spring",
        stiffness: 100,
      },
    },
  };

  const [letters, setLetters] = useState("");
  const { selectedObject, setSelectedObject } = useSelection();
  const { templateid, typetemplate } = useParams();
  const [sharedUsers, setSharedUsers] = useState<UsersSharedProps[]>([]);
  const [loading, setLoading] = useState(false);
  const {
    currentTemplateInfo,
    myProfileId,
    objectScreen,
    handleOpenModalBlockFuncionalities,
    hasGroupShared,
    isActiveControlOfEditions,
    setIsActiveControlOfEditions,
  } = useMainHook();
  const { refreshUsersShared, setRefreshUsersShared } = useSharedDocument();
  const [isOwnerDocument, setIsOwnerDocument] = useState(false);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [indexDelete, setIndexDelete] = useState(0);
  const [messageSpam, setmessageSpam] = useState(false);
  const [isOwnerEmail, setIsOwnerEmail] = useState("");
  const [switchValue, setSwitchValue] = useState(false);
  const [loadingSwitch, setLoadingSwitch] = useState(false);
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(true);

  const { handleSaveTemplate, colorAvatar } = useHeaderEditor();
  const { templateStartedShared, setTemplateStartedShared } = useDiff();
  const { handleSharedProjectAction } = useMetric();

  const schema = yup.object({
    email: yup.string().email("Email invalido").required(),
  });

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<SubmitProps>({
    resolver: yupResolver(schema),
  });

  const handleDeleteUserShare = useCallback(
    async (idUser: string, isAnnonymous: boolean, event, index) => {
      event.preventDefault();
      setIndexDelete(index);
      let userId = idUser;
      let documentId = templateid;
      let isUserAnnonymous = isAnnonymous ? 1 : 0;

      try {
        setLoadingDelete(true);
        const responseDeleteUserShare = await api.delete(
          `user-share-template/delete-user-share/${userId}/${documentId}/${isUserAnnonymous}`
        );

        setRefreshUsersShared(!refreshUsersShared);
        setLoadingDelete(false);
      } catch (err) {
        console.error(err, "ERROR when delete user shared");
        setLoadingDelete(false);
      }
    },
    [refreshUsersShared, indexDelete, loadingDelete]
  );

  const onSubmit = useCallback(
    async (data: SubmitProps) => {
      setLoading(true);

      if (data.email === isOwnerEmail) {
        toast.error("Este é seu email. Mude o email para compartilhar.");
        setLoading(false);
        return;
      }

      let objectForApi = {
        user_template_id: templateid,
        users: [
          {
            email: data.email,
            permission_read: true,
            permission_update: true,
            permission_download: true,
          },
        ],
      };

      try {
        const responseCreateUserSharedTemplate = await api.post(
          "user-share-template",
          objectForApi
        );

        //Shared project action register function
        handleSharedProjectAction({ project_id: templateid });

        setRefreshUsersShared(!refreshUsersShared);
        // handleSaveTemplate(true);
        toast.success("Convite enviado com sucesso!");
        reset();
        setLoading(false);
        setmessageSpam(true);
        setTemplateStartedShared(true);
        setUpdateIfGroup((prevState) => !prevState);

        if (selectedObject) {
          setSelectedObject(null);
        }
      } catch (err) {
        console.error(err, "ERROR when send mail object for shared template");
        toast.error("Revise os dados e tente novamente");
        setLoading(false);
      }
    },
    [
      refreshUsersShared,
      templateStartedShared,
      templateStartedShared,
      selectedObject,
      isOwnerEmail,
    ]
  );

  useEffect(() => {
    const autoLoadSharedUsers = async () => {
      try {
        const responseTemplatesUsers = await api.get(
          `user-share-template/all-shared-template-users/${templateid}`
        );

        setSharedUsers(responseTemplatesUsers.data.users);
      } catch (err) {
        console.error(err, "ERROR when fetch users shared template");
      }
    };

    if (typetemplate === 'team-template') return;
    autoLoadSharedUsers();
  }, [refreshUsersShared]);

  useEffect(() => {
    const timeOut = setTimeout(() => {
      setForceUpdate(!forceUpdate);
    }, 2000);

    return () => {
      clearTimeout(timeOut);
    };
  }, []);

  useEffect(() => {
    if (myProfileId) {
      const getOwnerId = sharedUsers.find(
        (user) => user.permissions === "Owner"
      );

      if (getOwnerId?.id === myProfileId) {
        setIsOwnerDocument(true);
      } else {
        setIsOwnerDocument(false);
      }
    }
  }, [sharedUsers, myProfileId, forceUpdate]);

  // get profile for blocked
  // send email for the same user.
  useEffect(() => {
    const autoLoad = async () => {
      try {
        const response = await api.get("profile");
        setIsOwnerEmail(response.data.user.email);
      } catch (err) {
        console.error(err, "error when fetch profile");
      }
    };

    autoLoad();
  }, []);

  const { updateIfGroup, setUpdateIfGroup } = useDiff();

  const [toggleIsUsersShared, setToggleIsUsersShared] = useState(false);

  useEffect(() => {
    (async () => {
      if (typetemplate === 'team-template') return;
      try {
        const requestGroupShared = await api.get(
          `user-share-template/all-shared-template-users/${templateid}`
        );

        if (requestGroupShared.data.users) {
          if (requestGroupShared.data.users.length > 0) {
            if (!requestGroupShared.data.is_change_tracking_enabled) {
              hasGroupShared.current = false;
              setIsActiveControlOfEditions(false);
            }
          }
        }
      } catch (err) {
        console.error("Error when get group of users shared", err);
      }
    })();
  }, [toggleIsUsersShared]);

  const [forceUpdateSwitch, setForceUpdateSwitch] = useState(false);

  const handleControlOfEditions = useCallback(
    async (isActive) => {
      if (isActive) {
        let changes = false;

        objectScreen.forEach((page) => {
          page.renderObjects.forEach((renderedObject) => {
            if (renderedObject.text && !changes) {
              if (renderedObject.isModify) {
                changes = true;
              } else {
                changes = false;
              }
            }
          });
        });

        if (changes) {
          onRequestClose();
          //if have changes pending then open modal to
          //user resolve conflicts of texts before
          //that do download of document

          handleOpenModalBlockFuncionalities("disableEditions");
        } else {
          //if don`t have modifications in document
          //let him do download
          try {
            setLoadingSwitch(true);
            const controlOfEditionsResponse = await api.put(
              `user-share-template`,
              {
                user_template_id: templateid,
                is_change_tracking_enabled: !isActive,
              }
            );
            setForceUpdateSwitch(!forceUpdateSwitch);
            setIsActiveControlOfEditions(!isActiveControlOfEditions);
            setLoadingSwitch(false);
          } catch (err) {
            setLoadingSwitch(false);
            console.error(
              err,
              "error when put control of editions active or disabled"
            );
          }
        }
      } else {
        try {
          setLoadingSwitch(true);
          const controlOfEditionsResponse = await api.put(
            `user-share-template`,
            {
              user_template_id: templateid,
              is_change_tracking_enabled: !isActive,
            }
          );
          setForceUpdateSwitch(!forceUpdateSwitch);
          setIsActiveControlOfEditions(!isActiveControlOfEditions);
          setLoadingSwitch(false);
        } catch (err) {
          setLoadingSwitch(false);
          console.error(
            err,
            "error when put control of editions active or disabled"
          );
        }
      }
    },
    [
      objectScreen,
      onRequestClose,
      handleOpenModalBlockFuncionalities,
      templateid,
      forceUpdateSwitch,
      setIsActiveControlOfEditions,
      isActiveControlOfEditions,
    ]
  );

  const [loadingChecked, setLoadingChecked] = useState(false);

  useEffect(() => {
    (async () => {
      if (typetemplate === 'team-template') return;
      try {
        const requestShared = await api.get(
          `user-share-template/all-shared-template-users/${templateid}`
        );

        setIsActiveControlOfEditions(
          requestShared.data.is_change_tracking_enabled
        );
      } catch (err) { }
    })();
  }, [forceUpdateSwitch]);

  useEffect(() => {
    (async () => {
      if (typetemplate === 'team-template') return;
      try {
        setLoadingChecked(true);
        const requestShared = await api.get(
          `user-share-template/all-shared-template-users/${templateid}`
        );

        if (!requestShared.data.is_shared) {
          try {
            const responseCreateSharedUsers = await api.post(
              "user-share-template",
              {
                user_template_id: templateid,
                users: [],
              }
            );
          } catch (err) {
            console.error(err, "create shared group ");
          }
        }
        setIsActiveControlOfEditions(
          requestShared.data.is_change_tracking_enabled
        );
        setLoadingChecked(false);
      } catch (err) {
        console.error(err, "ERROR when get users shared list");
      }
    })();
  }, [refreshUsersShared]);

  const myEmail = useWatch({
    control,
    name: "email",
  });

  const handleKeyPress = async (
    event: React.KeyboardEvent<HTMLInputElement> | HTMLInputElement | any
  ) => {
    if (event.key === "Enter") {
      event.preventDefault();
      setLoading(true);

      if (myEmail === isOwnerEmail) {
        toast.error("Este é seu email. Mude o email para compartilhar.");
        setLoading(false);
        return;
      }

      let objectForApi = {
        user_template_id: templateid,
        users: [
          {
            email: myEmail,
            permission_read: true,
            permission_update: true,
            permission_download: true,
          },
        ],
      };

      try {
        const responseCreateUserSharedTemplate = await api.post(
          "user-share-template",
          objectForApi
        );

        //Shared project action register function
        handleSharedProjectAction({ project_id: templateid });

        setRefreshUsersShared(!refreshUsersShared);
        // handleSaveTemplate(true);
        toast.success("Convite enviado com sucesso!");

        setLoading(false);
        setmessageSpam(true);
        setTemplateStartedShared(true);
        setUpdateIfGroup((prevState) => !prevState);

        if (selectedObject) {
          setSelectedObject(null);
        }

        reset();
      } catch (err) {
        console.error(err, "ERROR when send mail object for shared template");
        toast.error("Revise os dados e tente novamente");
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    return () => {
      reset();
    };
  }, []);

  return (
    <>
      <Modal
        className="react-modal-content"
        overlayClassName="react-modal-overlay"
        isOpen={isOpen}
        onRequestClose={() => {
          reset();
          // onRequestClose();
          setmessageSpam(false);
        }}
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
      >
        <AnimatePresence>
          <ContainerModal
            as={motion.form}
            initial="hidden"
            animate="show"
            onSubmit={handleSubmit(onSubmit)}
            variants={container}
            exit={{
              opacity: 0,
              scale: 0.8,
              x: -300,
            }}
            transition={{ duration: 1 }}
          >
            <Header>
              <ButtonExit onClick={onRequestClose} className="btn-exit" />

              <TitleH5
                title={`Compartilhar ${currentTemplateInfo?.current?.title}`}
              />
            </Header>

            <HorizontalStack>
              <FlexInput>
                <label>E-mail</label>
                <Controller
                  control={control}
                  name="email"
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState,
                  }) => (
                    <InputBase
                      type="text"
                      placeholder="E-mail"
                      onKeyPress={handleKeyPress}
                      onChange={onChange}
                      {...register("email")}
                    />
                  )}
                />
              </FlexInput>

              {/* {errors.email?.message && (
              <p className="error-validation">{errors.email?.message}</p>
                )} */}

              {isOwnerDocument ? (
                <ButtonShared type="submit">
                  {loading ? (
                    <div className="loading-container">
                      <PuffLoader color="#fff" size="18" />
                    </div>
                  ) : (
                    `Enviar convite`
                  )}
                </ButtonShared>
              ) : (
                <ButtonShared
                  style={{ opacity: "0.2", cursor: "not-allowed" }}
                  disabled={true}
                >
                  {loading ? (
                    <div className="loading-container">
                      <PuffLoader color="#fff" size="18" />
                    </div>
                  ) : (
                    `Enviar convite`
                  )}
                </ButtonShared>
              )}
            </HorizontalStack>

            <Table>
              <div>
                <p>Nome</p> <p>E-mail</p> <p>Função na equipe</p>
              </div>

              {loading ? (
                <div className="containerLoading">
                  <ReactLoading
                    type="spin"
                    color="#000"
                    height={20}
                    width={20}
                  />
                </div>
              ) : (
                <ul>
                  {sharedUsers.map((user, index) => (
                    <ItemUserInfo key={user.name}>
                      <FirstAreaItem>
                        <figure>
                          <AvatarCustom indexOfUser={index + 1}>
                            <PlainText>
                              {`${user.name.charAt(0).toUpperCase()}`}
                            </PlainText>
                          </AvatarCustom>
                        </figure>
                        <Tooltip title={`${user.name}`} placement="top">
                          <p>{user.name}</p>
                        </Tooltip>
                      </FirstAreaItem>
                      <Tooltip title={`${user.email}`} placement="top">
                        <span className="email">{user.email}</span>
                      </Tooltip>
                      <PlainText>
                        {user.permissions === "Owner"
                          ? "Proprietário"
                          : "Membro"}{" "}
                      </PlainText>
                      {user.permissions !== "Owner" && (
                        <Trash
                          onClick={(event) => {
                            setToggleIsUsersShared((prevState) => !prevState);
                            //verify changes for block download with marks of editions
                            let changes = false;

                            objectScreen.forEach((page, index) => {
                              page.renderObjects.forEach(
                                (renderedObject, index) => {
                                  if (renderedObject.text && !changes) {
                                    if (renderedObject.isModify) {
                                      changes = true;
                                    } else {
                                      changes = false;
                                    }
                                  }
                                }
                              );
                            });

                            if (!changes) {
                              handleDeleteUserShare(
                                user.id,
                                user.is_anonymous,
                                event,
                                index
                              );

                              setUpdateIfGroup((prevState) => !prevState);
                            } else {
                              onRequestClose();
                              handleOpenModalBlockFuncionalities("shared");
                            }
                          }}
                        >
                          <PersonalizedIcon
                            dPath={MountIcons.IconTrash.dPath}
                            viewBox={MountIcons.IconTrash.viewBox}
                            inactivatedColor="#999C9F"
                          />
                        </Trash>
                      )}
                    </ItemUserInfo>
                  ))}
                </ul>
              )}
            </Table>

            {messageSpam && (
              <FooterWraper>
                <Left>
                  <IconSvg src={verifyIcon} description="" />
                </Left>
                <Right>
                  <p>
                    O convite foi enviado com sucesso. Caso o convidado não
                    receba o e-mail de convite, peça que verifique a caixa de
                    entrada, lixo eletrônico e SPAM.
                  </p>
                </Right>
              </FooterWraper>
            )}

            <Footer>
              <ButtonMaxWidth
                text="Cancelar"
                typeStyle="white"
                onClick={onRequestClose}
              />
              <ButtonMaxWidth
                text="Compartilhar"
                typeStyle="purple"
                onClick={onRequestClose}
              />
            </Footer>
          </ContainerModal>
        </AnimatePresence>
      </Modal>
    </>
  );
};

{
  /* <ControlEditionsButton>
              <>
                <span>Controle de alterações</span>
                {!loadingChecked ? (
                  <Switch
                    width={48}
                    height={20}
                    disabled={loadingSwitch ? true : false}
                    onChange={() => {
                      if (!loadingSwitch) {
                        handleControlOfEditions(isActiveControlOfEditions);
                      }
                    }}
                    checked={isActiveControlOfEditions}
                    uncheckedIcon
                    checkedIcon
                    onColor="#6EB800"
                    offColor="#CFD1D2"
                    activeBoxShadow="'0 0 2px 3px #D5D5D8'"
                  />
                ) : (
                  <div className="containerLoading">
                    <ReactLoading
                      type="spin"
                      color="#000"
                      height={20}
                      width={20}
                    />
                  </div>
                )}
              </>
            </ControlEditionsButton> */
}
