import React, { useEffect, useState } from "react";

import {
  ViewMain,
  Text,
  TextQrCode,
  ContainerManual,
  DisplayBase32,
} from "./styles";
import GeneratorQRCode from "../GeneratorQRCode";
import { PATH_GOOGLE_AUTH_IOS } from "../../constants/stepsSecure";
import LoadingTransition from "../LoadingTransition";
import api from "../../../../../services/api";
import { AxiosResponse } from "axios";
import { ResponseGenerateProps } from "../dtos/TwoFactor";
import { useTwoFactorAuth } from "../../../../../hooks/twoFactorAuth";
import { toast } from "react-toastify";

const Setup2FA: React.FC = () => {
  const [config, setConfig] = useState<"QR Code" | "Manual">("QR Code");
  const [loading, setLoading] = useState(true);
  const [QRCode, setQRCode] = useState("");
  const [manualCode, setManualCode] = useState("");

  const { setBackupRestoreCode } = useTwoFactorAuth();

  useEffect(() => {
    (async () => {
      try {
        const responseGenerateSetup2Fa: AxiosResponse<ResponseGenerateProps> =
          await api.post("two-factor-authentication/generate");

        const { base32, otpauth_url, reservation_code } =
          responseGenerateSetup2Fa.data;
        setQRCode(otpauth_url);
        setManualCode(base32);
        setBackupRestoreCode(reservation_code);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        toast.error("Ops, algo deu errado.");
        console.error(err.response, "error get qr code and codes generator 2fa");
      }
    })();
  }, []);

  if (loading) {
    return <LoadingTransition />;
  }

  if (config === "QR Code") {
    return (
      <>
        <Text>
          Após baixar o aplicativo e fazer login na sua conta, escaneie este QR
          code para configurá-la.
        </Text>
        <ViewMain>
          <GeneratorQRCode url={QRCode} />
          <TextQrCode onClick={() => setConfig("Manual")}>
            Inserir código manualmente
          </TextQrCode>
        </ViewMain>
      </>
    );
  }

  return (
    <ContainerManual>
      <Text style={{ maxWidth: "500px", textAlign: "center" }}>
        Baixe gratuitamente o aplicativo Google Authenticator, adicione uma nova
        conta e insira esse código para configurar sua conta.
      </Text>
      <DisplayBase32>
        <span> {manualCode} </span>
      </DisplayBase32>

      <TextQrCode onClick={() => setConfig("QR Code")}>
        Escanear o QR code
      </TextQrCode>
    </ContainerManual>
  );
};

export default Setup2FA;

