import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { ToastContainer } from "react-toastify";
import { useForm, useWatch } from "react-hook-form";
import InputRadio from "../../../../../shared/components/InputRadio";
import InputSelect from "../../../../../shared/components/InputSelect";
import IconAlert from "../../../../../shared/assets/icon-alert.svg";
import {
  Container,
  Form,
  PasswordBox,
  InputId,
  Terms,
  FooterForm,
  InputEmail,
} from "./styles";

import { yupResolver } from "@hookform/resolvers/yup";
import { useAuth } from "../../../../../hooks/auth";
import api from "../../../../../services/api";
import { useSelection } from "../../../../editor/hooks/selection";
import { useMainHook } from "../../../../../hooks/main";
import UxDocLogoLarge from "../../../../../shared/components/UxDocLogoLarge";
import TitleH3 from "../../../../../shared/components/TitleH3";
import InputForm from "../../../../../shared/components/InputForm";
import InputPassword from "../../../../../shared/components/InputPassword";
import PasswordAlert from "../components/PasswordAlert";
import ButtonMaxWidth from "../../../../../shared/components/ButtonMaxWidth";
import InputMask from "../../../../../shared/components/InputMask";
import AlertError from "../../../../../shared/components/AlertError";
import { useMetric } from "../../../../../hooks/metric";

interface SubmitPropsRegister {
  first_name?: string;
  last_name?: string;
  email?: string;
  password?: string;
  where_do_you_work?: string;
  how_did_you_meet_us?: string;
  phone?: string;
  register?: string;
  emailConfirm?: string;
  other?: string;
}

interface RegisterProps {
  isPayment?: boolean;
  setIsPayment?: React.Dispatch<React.SetStateAction<boolean>>;
}

type IDataErros =
  | "CPF ou CNPJ inválido!"
  | "Este e-mail já está cadastrado."
  | "Por favor aceite aos termos de uso"
  | "Ops! Algo deu errado, verifique os dados e tente novamente."
  | "Sintaxe de email errado!"
  | "E-mails diferentes!"
  | string;

const RegisterScreen: React.FC<RegisterProps> = ({
  isPayment,
  setIsPayment,
}) => {
  const { setForceBlockKey } = useSelection();
  const { setFirstLoading } = useMainHook();
  const { handleSelectHowYouMeUs, handleSelectWhereDoYouWork } = useMetric ();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [dataErros, setDataErros] = useState<IDataErros>("");
  const [isErrorRegister, setIsErrorRegister] = useState(false);
  const [isErrorEmail, setIsErrorEmail] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [showErrorRegister, setShowErrorRegister] = useState<{
    error: boolean;
    type: "cpf" | "cnpj";
  }>({
    error: true,
    type: "cpf",
  });
  const [customerEmail, setCustomerEmail] = useState("");

  useEffect(() => {
    document.title = `UX DOC | Criar conta`;
  }, []);

  const [showErrorPhone, setShowErrorPhone] = useState(true);
  const [typeRegisterCustomer, setTypeRegisterCustomer] = useState<
    "cpf" | "cnpj"
  >("cpf");
  const { signIn } = useAuth();
  const [hasSubmited, setHasSubmited] = useState(false);
  const [checkedLGPD, setCheckedLGPD] = useState(false);
  const [errorCPF, setErrorCPF] = useState(false);
  const [inputValueOthers, setInputValueOthers] = useState(false);
  const handleEmailCustomer = useCallback(
    (value) => {
      const validateEmail = value.match("@") && value.match(".com");
      if (!validateEmail) {
        setIsErrorEmail(true);
      } else {
        setIsErrorEmail(false);
      }
      setCustomerEmail(value);
    },
    [customerEmail]
  );

  const schema = yup.object({
    first_name: yup.string().required("Nome obrigatório"),
    last_name: yup.string().required("Sobrenome obrigatório"),
    emailConfirm: yup
      .string()
      .oneOf([customerEmail, null], "E-mail diferente")
      .required("Digite o email novamente"),
    password: yup
      .string()
      .min(8, "A senha deve ter no mínimo 8 caracteres")
      .matches(
        /(?=.*[A-Z])/g,
        "A senha deve conter pelo menos uma letra maiúscula"
      )
      .matches(/(?=.*\d)/g, "A senha deve conter pelo menos um número")
      .matches(/[a-z]/, "Senha deve conter pelo menos uma letra minúscula")
      .matches(/[*@!#%&()^~{}]+/, "A senha deve conter pelo menos um símbolo")
      .required(`Senha necessária`),
    where_do_you_work: yup.string().required(`Onde trabalha necessário`),
    how_did_you_meet_us: yup.string().required(`Como nos conheceu necessário`),
    other: yup
      .string()
      .test("isRequired", "Campo obrigatório", function (value) {
        if (inputValueOthers && !value) {
          throw this.createError({
            message: "Qual o outro canal de comunicação que nos conheceu?",
            path: "other",
          });
        }
        return true;
      }),
  });

  const [customerRegister, setCustomerRegister] = useState("");
  const [customerRegisterPhone, setCustomerRegisterPhone] = useState("");
  const [inputValueCpf, setInputValueCpf] = useState("");

  const handleRegisterCustomer = useCallback(
    (value, mask) => {
      setInputValueCpf(value);
      setCustomerRegister(mask._unmaskedValue);
    },
    [customerRegister]
  );

  const handleRegisterPhone = useCallback(
    (value, mask) => {
      setCustomerRegisterPhone(mask._unmaskedValue);
    },
    [customerRegister]
  );

  useEffect(() => {
    const lengthOfRegisterInput = customerRegister.length;

    if (typeRegisterCustomer === "cpf") {
      if (lengthOfRegisterInput === 11) {
        setShowErrorRegister((state) => {
          return {
            ...state,
            error: false,
            type: "cpf",
          };
        });
      } else {
        setShowErrorRegister((state) => {
          return {
            ...state,
            error: true,
            type: "cpf",
          };
        });
      }
    }

    if (typeRegisterCustomer === "cnpj") {
      if (lengthOfRegisterInput === 14) {
        setShowErrorRegister((state) => {
          return {
            ...state,
            error: false,
            type: "cnpj",
          };
        });
      } else {
        setShowErrorRegister((state) => {
          return {
            ...state,
            error: true,
            type: "cnpj",
          };
        });
      }
    }
  }, [customerRegister, typeRegisterCustomer]);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<SubmitPropsRegister>({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (dataErros === "CPF ou CNPJ inválido!") {
      setIsErrorRegister(true);
      setIsErrorEmail(false);
    } else if (
      dataErros === "Este e-mail já está cadastrado." ||
      "E-mails diferentes!"
    ) {
      setIsErrorEmail(true);
      setIsErrorRegister(false);
    }
  }, [dataErros]);

  useEffect(() => {
    if (
      errors.email ||
      errors.first_name ||
      errors.last_name ||
      errors.how_did_you_meet_us ||
      errors.password ||
      errors.phone ||
      errors.where_do_you_work ||
      errors.other
    ) {
      setHasSubmited(true);
    }
    if (errors.first_name?.message === "Nome obrigatório") {
      setDataErros("Nome obrigatório");
    } else if (errors.emailConfirm?.message === "E-mail diferente") {
      setDataErros("E-mails diferentes!");
    } else if (
      errors.password?.message === "A senha deve ter no mínimo 8 caracteres"
    ) {
      setDataErros("A senha deve ter no mínimo 8 caracteres");
    } else if (
      errors.password?.message ===
      "A senha deve conter pelo menos uma letra maiúscula"
    ) {
      setDataErros("A senha deve conter pelo menos uma letra maiúscula");
    } else if (
      errors.password?.message === "A senha deve conter pelo menos um número"
    ) {
      setDataErros("A senha deve conter pelo menos um número");
    } else if (
      errors.password?.message === "A senha deve conter pelo menos um símbolo"
    ) {
      setDataErros("A senha deve conter pelo menos um símbolo");
    } else if (
      errors.password?.message ===
      "Senha deve conter pelo menos uma letra minúscula"
    ) {
      setDataErros("Senha deve conter pelo menos uma letra minúscula");
    } else if (
      errors?.how_did_you_meet_us?.message === "Como nos conheceu necessário"
    ) {
      setDataErros("Como nos conheceu é obrigatório");
    } else if (
      errors?.where_do_you_work?.message === "Onde trabalha necessário"
    ) {
      setDataErros("Onde você atua necessário é obrigatório");
    } else if (
      errors?.other?.message ===
      "Qual o outro canal de comunicação que nos conheceu?"
    ) {
      setDataErros("Qual foi o canal é obrigatório");
    } else {
      if (customerEmail.length < 1) {
        setIsErrorEmail(true);
      }
    }
  }, [errors]);

  useEffect(() => {
    const lengthPhoneNumber = customerRegisterPhone.length;
    if (lengthPhoneNumber === 11) {
      setShowErrorPhone(false);
    } else {
      setShowErrorPhone(true);
    }
  }, [customerRegisterPhone]);

  const onSubmit = async (data: SubmitPropsRegister) => {
    if (!checkedLGPD) {
      setDataErros("Por favor aceite aos termos de uso");
    } else {
      if (showErrorRegister.error || showErrorPhone) {
        setHasSubmited(true);
      } else {
        setHasSubmited(false);
        setIsPayment(true);
        inputRef.current.style.color = "#000";
        inputRef.current.style.borderColor = "#000";
        try {
          setLoading(true);
          const {
            first_name,
            last_name,
            phone,
            where_do_you_work,
            how_did_you_meet_us,
            password,
            register,
            other,
          } = data;
          let newFirstName = first_name.split(" ");
          let newLastName = last_name.split(" ");
          for (let i = 0; i < newFirstName.length; i++) {
            newFirstName[i] =
              newFirstName[i].charAt(0).toUpperCase() +
              newFirstName[i].slice(1);
          }
          for (let i = 0; i < newLastName.length; i++) {
            newLastName[i] =
              newLastName[i].charAt(0).toUpperCase() + newLastName[i].slice(1);
          }
          let finalFirstName = newFirstName.join(" ");
          let finalLastName = newLastName.join(" ");

          let objectForApi = {
            first_name: finalFirstName.trim(),
            last_name: finalLastName.trim(),
            email: customerEmail.toLowerCase().trim(),
            password: password,
            phone: customerRegisterPhone,
            user_type: "collaborator",
            where_do_you_work: where_do_you_work,
            how_did_you_meet_us: how_did_you_meet_us,
            other: inputValueOthers ? other : undefined,
            document: customerRegister,
            is_individual:
              typeRegisterCustomer === "cpf"
                ? true
                : typeRegisterCustomer === "cnpj" && false,
          };
          const responseCreatedUser = await api.post("users", objectForApi);

          if (responseCreatedUser.status === 200) {
            setDataErros("");
            const myInputCpf: HTMLInputElement =
              document.querySelector("#input-mask-cpf");
            myInputCpf.value = customerRegister;
            myInputCpf.value = inputValueCpf;
            myInputCpf.style.color = "#000";
            handleSelectHowYouMeUs(how_did_you_meet_us)
            handleSelectWhereDoYouWork(where_do_you_work)
            await signIn(customerEmail, password);
            window.open("https://uxdoc.com.br/bem-vindo", "_self");
            setLoading(false);
            setFirstLoading(true);
          }

          setLoading(false);
        } catch (err) {
          console.error(err.response.data, "error");
          if (
            err.response.data.message === "Invalid CPF." ||
            err.response.data.message === "Invalid CNPJ."
          ) {
            setDataErros("CPF ou CNPJ inválido!");
            const myInputCpf: HTMLInputElement =
              document.querySelector("#input-mask-cpf");
            setErrorCPF(true);
            myInputCpf.value = customerRegister;
            myInputCpf.value = inputValueCpf;
            myInputCpf.style.color = "#DE2D2D";
            myInputCpf.style.borderColor = "#DE2D2D";
          } else if (
            err.response.data.message === "Email address alredy used"
          ) {
            setDataErros("Este e-mail já está cadastrado.");
            inputRef.current.style.color = "#DE2D2D";
            inputRef.current.style.borderColor = "#DE2D2D";
          } else if (err.response.data.message === "CPF already used") {
            setDataErros(
              "Esse CPF já está cadastrado. Se não foi você, entre em contato pelo link."
            );
          } else {
            setDataErros(
              "Ops! Algo deu errado, verifique os dados e tente novamente."
            );
          }
          setLoading(false);
        }
      }
    }
  };

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (errorCPF) {
      const myInputCpf: HTMLInputElement =
        document.querySelector("#input-mask-cpf");
      myInputCpf.style.color = "#000";
      myInputCpf.style.borderColor = "#000";
      setDataErros("");
    }

    return () => {
      setErrorCPF(false);
    };
  }, [customerRegister]);

  useEffect(() => {
    setForceBlockKey(true);

    return () => {
      setForceBlockKey(false);
    };
  }, []);

  const second = useWatch({
    control,
    name: "password",
  });

  const observerConfirmEmail = useWatch({
    control,
    name: "emailConfirm",
  });

  const othersOption = useWatch({
    control,
    name: "how_did_you_meet_us",
  });

  useEffect(() => {
    setInputValueOthers(othersOption === "Outros" ? true : false);
  }, [othersOption]);

  useEffect(() => {
    if (customerEmail === observerConfirmEmail) {
      setDataErros("");
    }
  }, [customerEmail, observerConfirmEmail]);

  const [passwordMessages, setPasswordMessages] = useState({
    haveMinLength: "",
    haveUpperOrLower: "",
    haveOneSymbol: "",
  });

  function hasSymbol(str: string) {
    const hasSymbol = /(?=.*\d)(?=.*[\W])/.test(str);

    if (hasSymbol) {
      setPasswordMessages((oldState) => {
        return {
          ...oldState,
          haveOneSymbol: "right",
        };
      });
    } else {
      setPasswordMessages((oldState) => {
        return {
          ...oldState,
          haveOneSymbol: "wrong",
        };
      });
    }
  }

  function hasUpperCaseAndLowerCase(str: string) {
    if (str) {
      const verifyExists = /[a-z]/.test(str) && /[A-Z]/.test(str);

      if (verifyExists) {
        setPasswordMessages((oldState) => {
          return {
            ...oldState,
            haveUpperOrLower: "right",
          };
        });
      } else {
        setPasswordMessages((oldState) => {
          return {
            ...oldState,
            haveUpperOrLower: "wrong",
          };
        });
      }
    }
  }

  useEffect(() => {
    if (!second) {
      setPasswordMessages((oldState) => {
        return {
          ...oldState,
          haveMinLength: "",
          haveUpperOrLower: "",
          haveOneSymbol: "",
        };
      });
    }

    if (second?.length > 1) {
      hasUpperCaseAndLowerCase(second);
    }

    if (second?.length > 1) {
      hasSymbol(second);
    }

    if (second !== undefined) {
      if (second?.length > 1 && second?.length >= 8) {
        setPasswordMessages((oldState) => {
          return {
            ...oldState,
            haveMinLength: "right",
          };
        });
      } else {
        setPasswordMessages((oldState) => {
          return {
            ...oldState,
            haveMinLength: "wrong",
          };
        });
      }
    }
  }, [second]);

  return (
    <Container>
      <ToastContainer />
      <UxDocLogoLarge />

      <TitleH3 title="Cadastre-se e ganhe 14 dias grátis!" />

      <Form onSubmit={handleSubmit(onSubmit)}>
        <InputForm
          label="Nome*"
          type="text"
          register={register}
          name="first_name"
          error={!!errors?.first_name?.message}
        />

        <InputForm
          label="Sobrenome*"
          type="text"
          register={register}
          name="last_name"
          error={!!errors?.last_name?.message}
        />

        <InputEmail error={hasSubmited && isErrorEmail}>
          <label> E-mail*</label>
          <div>
            <input
              type="email"
              style={{
                textTransform: "lowercase",
              }}
              value={customerEmail}
              onChange={(e) => handleEmailCustomer(e.target.value)}
              ref={inputRef}
            />
            {hasSubmited && isErrorEmail && (
              <img src={IconAlert} alt="ícone de alerta" />
            )}
          </div>
        </InputEmail>

        <InputForm
          label="Confirmar e-mail*"
          type="text"
          register={register}
          name="emailConfirm"
          error={!!errors.emailConfirm?.message}
        />

        <InputMask
          label="Telefone*"
          mask={"(00) 00000-0000"}
          onAccept={(value, mask) => handleRegisterPhone(value, mask)}
          error={hasSubmited && showErrorPhone}
        />

        <PasswordBox>
          <InputPassword
            isPasswordVisible={isPasswordVisible}
            setIsPasswordVisible={setIsPasswordVisible}
            register={register}
            error={!!errors?.password?.message}
          />

          <PasswordAlert passwordMessages={passwordMessages} />
        </PasswordBox>

        <InputId>
          <div>
            <InputRadio
              text="CPF*"
              checked={typeRegisterCustomer === "cpf" ? true : false}
              onChange={() => setTypeRegisterCustomer("cpf")}
            />
            <InputRadio
              text="CNPJ*"
              onChange={() => setTypeRegisterCustomer("cnpj")}
              checked={typeRegisterCustomer === "cnpj" ? true : false}
            />
          </div>
          <InputMask
            myId="input-mask-cpf"
            mask={
              typeRegisterCustomer === "cpf"
                ? "000.000.000-00"
                : typeRegisterCustomer === "cnpj" && "00.000.000/0000-00"
            }
            onAccept={(value, mask) => handleRegisterCustomer(value, mask)}
            error={isErrorRegister || (hasSubmited && showErrorRegister.error)}
          />
        </InputId>

        <InputSelect
          label="Onde você atua?"
          options={[
            "Escritório de advocacia pequeno",
            "Escritório de advocacia grande",
            "Departamento jurídico de empresa",
            "Sou advogado autônomo (a)",
            "Sou estudante de Direito",
            "Não tenho formação na área jurídica",
          ]}
          error={!!errors?.where_do_you_work?.message}
          register={register}
          name="where_do_you_work"
        />

        <InputSelect
          label="Como nos conheceu?"
          options={[
            "Cursos",
            "Instagram",
            "LinkedIn",
            "Google",
            "Indicação",
            "Outros",
          ]}
          register={register}
          error={!!errors?.how_did_you_meet_us?.message}
          name="how_did_you_meet_us"
        />

        {inputValueOthers && (
          <InputForm
            label="Qual?*"
            type="text"
            register={register}
            name="other"
            error={!!errors.other?.message}
          />
        )}

        <Terms>
          <input
            type="checkbox"
            checked={checkedLGPD}
            onChange={(e) => setCheckedLGPD(e.target.checked)}
          />
          <p>
            Estou de acordo com os{" "}
            <u>
              <i
                style={{ cursor: "pointer", marginRight: "3px" }}
                onClick={() =>
                  window.open("https://uxdoc.com.br/termos-de-uso/")
                }
              >
                termos de uso
              </i>
            </u>
            e a{`\n`}
            <u>
              {`\n`}
              <i
                onClick={() =>
                  window.open("https://uxdoc.com.br/politica-de-privacidade")
                }
                style={{ cursor: "pointer", marginRight: "1px" }}
              >
                {" "}
                política de privacidade
              </i>
            </u>{" "}
            do UX DOC.
          </p>
        </Terms>

        <FooterForm>
          <ButtonMaxWidth
            text="Cadastrar"
            typeStyle="purple"
            type="submit"
            disabled={loading ? true : false}
          />
          <ButtonMaxWidth
            onClick={() => navigate("/")}
            text="Já tem conta? Faça o login."
            typeStyle="white"
          />

          <AlertError dataError={dataErros} />
        </FooterForm>
      </Form>
    </Container>
  );
};

export default RegisterScreen;

