import React, { useState, useEffect } from "react";
import RoutesApp from "./routes";
import { BrowserRouter as Router } from "react-router-dom";
import GlobalStyle from "./shared/styles/global";
import AppProvider from "./hooks";
import { ConfigProvider } from "react-avatar";
import { isMobile } from "react-device-detect";
import MobileScreen from "./shared/components/mobile/screens/MobileScreen";
import { CookiesProvider } from "react-cookie";
import { QueryClient, QueryClientProvider } from "react-query";
import "react-toastify/dist/ReactToastify.css";
import "rc-slider/assets/index.css";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
    },
  },
});

function App() {
  const [screenMode, setScreenMode] = useState<"Desktop" | "Mobile" | "Unlock">(
    "Desktop"
  );
  const datadogEnv = process.env.REACT_APP_DATADOG_TOKEN || 'dev';
  const [screenUnlock, setScreenUnlock] = useState(false);
  // const [loading, setLoading] = useState(true);
  const [isPayment, setIsPayment] = useState(false);

  //get device of user
  useEffect(() => {
    if (isMobile) {
      setScreenMode("Mobile");
    } else {
      setScreenMode("Desktop");
    }
  }, [isMobile]);

  return (
    <CookiesProvider>
      <QueryClientProvider client={queryClient}>
        <ConfigProvider colors={["#24958E", "#D38E26"]}>
          <Router>
            <AppProvider>
              <GlobalStyle />
              {screenMode === "Desktop" || screenUnlock ? (
                <RoutesApp
                  unlockScreenMobile={screenUnlock}
                  setUnlockScreenMobile={setScreenUnlock}
                  isPayment={isPayment}
                  setIsPayment={setIsPayment}
                />
              ) : (
                <MobileScreen />
              )}
            </AppProvider>
          </Router>
        </ConfigProvider>
      </QueryClientProvider>
    </CookiesProvider>
  );
}

export default App;
