import React, { useCallback, useEffect, useRef } from "react";

import jsPDF from "jspdf";
import Konva from "konva";
import Rendering from "../../../modules/editor/components/Canvas/Rendering";

import { motion } from "framer-motion";
import { Layer, Stage } from "react-konva";
import { useMainHook } from "../../../hooks/main";
import { Container, StageContainer } from "./styles";
import { PagesProps } from "../../../dtos/PagesProps";
import { Modal, ModalProps } from "@material-ui/core";
import { useSelection } from "../../../modules/editor/hooks/selection";
import { useToolbarEditor } from "../../../modules/editor/hooks/toolbarEditor";
import { useDiff } from "../../../modules/editor/hooks/diffDocuments";
import { useTextsEdition } from "../../../modules/editor/hooks/textsEdition";
import api from "../../../services/api";
import { ToastContainer, toast } from "react-toastify";
import { useHeaderEditor } from "../../../modules/editor/hooks/headerEditor";

interface PersonalizedModalProps extends ModalProps {
  documentName: string;
  downloading: boolean;
  setDownloading: React.Dispatch<React.SetStateAction<boolean>>;
  pagesToBeRendered: PagesProps[] | null;
  fileExport: "pdf" | "word";
  toastId: any;
  isNewDownload?: any;
}
type ModalOmitted = Omit<PersonalizedModalProps, "open" | "children">;

const Downloadfiles: React.FC<ModalOmitted> = ({
  downloading,
  pagesToBeRendered,
  setDownloading,
  documentName,
  fileExport,
  isNewDownload,
  toastId,
  ...rest
}) => {
  const allStages = useRef<Konva.Stage[]>([]);

  const {
    quillRef,
    requestTextUpdate,
    textRich,
    isEditing,
    setIsEditing,
    blockingKeyboard,
    setBlockingKeyboard,
    quillRefComponent,
  } = useTextsEdition();

  const {
    templateStartedShared,
    setTemplateStartedShared,
    oldContentsState,
    setOldContentsState,
    newContentsState,
    setNewContentsState,
    selectionCursor,
    setSelectionCursor,
    findDiff,
    buttonRef,
    newDelta,
    setNewDelta,
    isEditingQuill,
    setIsEditingQuill,
    newChanges,
    setNewChanges,
    newContentsRef,
    oldContentsRef,
    getQuillCursorRef,
    isNewTextRef,
    isTypingQuillRef,
    whichUserEdited,
    interceptModifications,
    textIsChanged,
    blockRenderImage,
    blockBackspace,
    whichKeyPress,
    selectionFormatText,
  } = useDiff();

  const { isNewDownloadObject } = useHeaderEditor();

  const { nameOfCurrentUser, hasGroupShared } = useMainHook();
  // let idToastify: any;

  let idToastLoading = useRef(null);

  const handleDownloadPDF = useCallback(
    async (url: string, filename: string) => {
      try {
        toast.success("Documento exportado com sucesso!");
        const response = await fetch(url);
        const blob = await response.blob();
        const fileUrl = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = fileUrl;
        link.download = filename;
        link.click();
        window.URL.revokeObjectURL(fileUrl);

        allStages.current = [];

        try {
          await api.post("download/delete", {
            filename,
          });
          setDownloading(false);
        } catch (err) {
          setDownloading(false);
          console.error(err, "error when delete url of pdf");
        }
      } catch (err) {
        setDownloading(false);
        console.error(err, "error fetch url");
      }
    },
    [setDownloading]
  );

  let toastIdB = useRef(null);

  useEffect(() => {
    if (downloading) {
      toastIdB.current = toast.loading("Exportando documento.", {
        closeButton: true,
      });
    }
  }, [downloading]);

  useEffect(() => {
    (async () => {
      await new Promise((res, rej) => {
        setTimeout(() => {
          res("");
        }, 1000);
      });

      let documentPages = [];

      if (!isNewDownloadObject.isNewDownload) {
        allStages.current.forEach((page, index) => {
          const data_url = page.toDataURL({
            pixelRatio: 2,
          });
          documentPages.push({ base64: data_url });
        });
      }

      if (downloading) {
        try {
          const toastId = toast.loading("Exportando documento.");
          const pages = {
            page: documentPages,
            file_type: fileExport, // or "word" 
          };
          const sendBase = await api.post(`${isNewDownloadObject.isNewDownload ? `download/${isNewDownloadObject.isPDF ? 'new-pdf' : isNewDownloadObject.isPPTX ? 'pptx' : 'docx'}` : 'download'}`, 
            isNewDownloadObject.isNewDownload ? { user_template_id: isNewDownloadObject.id } : pages);
          const nameDoc = `${documentName.replaceAll(".", "-")}`;

          if (sendBase.data.url) {
            toast.dismiss(toastId);
            toast.dismiss(toastIdB.current);
            await handleDownloadPDF(sendBase.data.url, nameDoc);
          }
        } catch (err) {
          toast.dismiss(toastId);
          toast.dismiss(toastIdB.current);
          setDownloading(false);
          toast.error("Deu erro, tente mais tarde.");
          console.error(err.response, "error generate PDF");
          console.error(err?.response?.data, "error generate PDF");
        }
      }
    })();

    return () => {
      if (!downloading) {
        toast.dismiss(toastId);
      }
    };
  }, [downloading]);

  const {
    setSelectedObject,
    currentMultipleSelection,
    selectedObject,
    transformerSelectedObject,
  } = useSelection();

  const { blockCommandKeyboard, setBlockCommandKeyboard } = useSelection();
  const { addToHistory, setObjectScreen } = useMainHook();

  const widthCanvas: number = 596;
  const heigthCanvas: number = 842;

  return (
    <Modal
      BackdropProps={{ style: { backgroundColor: "rgba(0,0,0,0)" } }}
      open={downloading}
      {...rest}
      style={{ backgroundColor: "rgba(0,0,0,0)" }}
    >
      <Container>
        {pagesToBeRendered?.map((item, index) => (
          <StageContainer
            key={item.pageNumber}
            as={motion.div}
            style={{
              position: "absolute",
              right: window.innerWidth,
            }}
          >
            <Stage
              ref={(currentStage) => {
                if (currentStage && !allStages.current.includes(currentStage)) {
                  allStages.current.push(currentStage);
                }
              }}
              listening={false}
              pixelRatio={5}
              scaleX={1}
              scaleY={1}
              width={widthCanvas}
              height={heigthCanvas}
            >
              <Layer>
                {item.renderObjects.map((objectProps, index) => (
                  <Rendering
                    key={index}
                    selectedObject={selectedObject}
                    setSelectedObject={setSelectedObject}
                    objectProps={objectProps}
                    addToHistory={addToHistory}
                    commandKeyboard={blockCommandKeyboard}
                    setCommandBlockKeyboard={setBlockCommandKeyboard}
                    currentMultipleSelection={currentMultipleSelection}
                    setObjectScreen={setObjectScreen}
                    transformerSelectedObject={transformerSelectedObject}
                    quillRef={quillRef}
                    requestUpdateText={requestTextUpdate}
                    textRich={textRich}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    blockingKeyboard={blockingKeyboard}
                    setBlockingKeyboard={setBlockingKeyboard}
                    templateStartedShared={templateStartedShared}
                    setTemplateStartedShared={setTemplateStartedShared}
                    oldContentsState={oldContentsState}
                    setOldContentsState={setOldContentsState}
                    newContentsState={newContentsState}
                    setNewContentsState={setNewContentsState}
                    selectionCursor={selectionCursor}
                    setSelectionCursor={setSelectionCursor}
                    findDiff={findDiff}
                    buttonRef={buttonRef}
                    newDelta={newDelta}
                    setNewDelta={setNewDelta}
                    isEditingQuill={isEditingQuill}
                    setIsEditingQuill={setIsEditingQuill}
                    newChanges={newChanges}
                    setNewChanges={setNewChanges}
                    oldContentsRef={oldContentsRef}
                    newContentsRef={newContentsRef}
                    getQuillCursorRef={getQuillCursorRef}
                    isNewTextRef={isNewTextRef}
                    isTypingQuillRef={isTypingQuillRef}
                    quillRefComponent={quillRefComponent}
                    whichUserEdited={whichUserEdited}
                    nameOfCurrentUser={nameOfCurrentUser}
                    interceptModifications={interceptModifications}
                    textIsChanged={textIsChanged}
                    blockRenderImage={blockRenderImage}
                    blockBackspace={blockBackspace}
                    hasGroupShared={hasGroupShared}
                    whichKeyPress={whichKeyPress}
                    selectionFormatText={selectionFormatText}
                  />
                ))}
              </Layer>
            </Stage>
          </StageContainer>
        ))}
      </Container>
    </Modal>
  );
};

export default Downloadfiles;
