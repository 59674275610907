/*
this component render page of automation tab on dashboard
here user can view your automations and edit them
user can be view, edit, do download, remove automation of your account
*/

import React, { useCallback, useEffect, useRef, useState } from "react";
import { ToastContainer } from "react-toastify";
import { cloneDeep } from "lodash";

//set up styles
import {
  Container,
  ContainerLogin,
  GridAutomation,
  Header,
  HeaderTable,
  Main,
  NoElements,
  TableAutomation,
} from "./styles";

import { useNavigate, useParams } from "react-router-dom";
import { PagesProps } from "../../../../dtos/PagesProps";
import { useMainHook } from "../../../../hooks/main";
import api from "../../../../services/api";

import Pagination from "../../components/Pagination";
import HeaderSection from "../../components/HeaderSection";
import SearchBar from "../../components/SearchBar";
import SelectCategory from "../../components/SelectCategory";
import { ContractAnimation } from "../../../../shared/components/ContractAnimation";
import AutomationItem from "../components/AutomationItem";
import PreviewTemplateModal from "../../components/PreviewTemplateModal";
import Downloadfiles from "../../../../shared/components/DownloadFiles";
import ModalDeleteAutomation from "../../master/components/ModalDeleteAutomation";

//set up interfaces

interface MyAutomationsProps {
  id: string;
  user_id: string;
  title: string;
  description?: string;
  thumbnail: string;
  is_automation: boolean;
  automation_user_template_id: string;
  created_at: string;
  updated_at: string;
  thumbnail_url: string;
  is_favorite: boolean;
}

const MyAutomations: React.FC = () => {
  //config navigate
  const navigate = useNavigate();
  const { number_page, filter } = useParams();
  const pageNumber = number_page === undefined ? 0 : parseInt(number_page) - 1;
  const filterCategory = filter === undefined ? "all" : filter;

  const {
    setObjectScreen,
    setIdAutomationToDelete,
    setIsModalDeleteAutomation,
    refresh,
    setIsIntentBuyModal,
    isModalDeleteAutomation,
    debouncedSearchTerm,
    loadingDebounce,
    setIsLoadingModal,
    currentTemplateInfo,
    documentName,
  } = useMainHook();

  //set up all states
  const [automations, setAutomations] = useState<MyAutomationsProps[]>([]);
  const [downloading, setDownloading] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(true);

  //state Search Bar

  //stete Modal Preview
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [preview, setPreview] = useState({ pages: [], title: "" });
  const [loadingPreview, setLoadingPreview] = useState(true);
  const [templateId, setTemplateId] = useState("");

  const whichFileToBeDownload = useRef<"word" | "pdf">("pdf");
  const idToastifyDownloadRef = useRef<"">(null);
  const documentPreviewData = useRef<PagesProps[] | null>(null);

  //Debounce responsible for keeping the user completing the search word

  // fetch list of automation of that user and
  //ensure params such as search and pagination
  //when params change, trigger re-render to new list
  useEffect(() => {
    const autoLoadAutomations = async () => {
      setLoading(true);
      try {
        const responseAutomations = await api.get(
          "user-templates/list-automation-user-template-dashboard",
          {
            params: {
              search: debouncedSearchTerm ? debouncedSearchTerm : null,
              filter: filterCategory === "all" ? null : filterCategory,
              pagination: pageNumber,
            },
          }
        );
        setAutomations(responseAutomations.data.dataArray);
        setTotalPages(responseAutomations.data.pagination);
        setLoading(false);

        //Checks if there are automations on that page
        // If not, it sends me back to the previous page
        if (pageNumber > 0 && responseAutomations.data.dataArray.length === 0) {
          navigate(`/automations/${pageNumber}`);
        }
      } catch (err) {
        // console.error(err, "error when get automations");
        setLoading(false);
      }
    };

    autoLoadAutomations();
  }, [refresh, filterCategory, pageNumber, debouncedSearchTerm]);

  //this callback have responsability in call api
  //with delete resquest and pass it one id which wish remove
  //and delete automation document and show message of success
  const handleDeleteAutomation = useCallback(
    async (id: string) => {
      setIdAutomationToDelete(id);
      setIsModalDeleteAutomation(true);
    },
    [setIsModalDeleteAutomation, setIdAutomationToDelete]
  );

  //if user want edit one automation
  //get between api this document data by id
  //and update main state and redirect he to editor/project
  const handleEditAutomation = useCallback(
    async (id: string) => {
      try {
        setIsLoadingModal(true);
        const responseTemplate = await api.get(`user-templates/${id}`);

        currentTemplateInfo.current = responseTemplate.data;
        documentName.current = responseTemplate.data.title;

        setIsLoadingModal(false);
        navigate(`/editor/my-template/${id}`);
      } catch (err) {
        setIsLoadingModal(false);
        console.error("error when get specific template for load state ");
      }
    },
    [currentTemplateInfo, documentName, navigate, setIsLoadingModal]
  );

  const handleDateConvert = useCallback((data: string) => {
    const dataUTC = new Date(data);

    dataUTC.setUTCHours(dataUTC.getUTCHours());

    const dataBrasil = dataUTC.toLocaleString("pt-BR", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    });

    return dataBrasil.split(",");
  }, []);

  const handleCloseModal = useCallback(() => {
    setModalIsOpen(false);
  }, []);

  const handlePreviewDocumentAutomation = useCallback(
    async (templateId: string) => {
      try {
        setLoadingPreview(true);
        setModalIsOpen(true);
        const fetchDocumentPreview = await api.get(
          `user-templates/template/${templateId}`
        );

        const fetchName = await api.get(`user-templates/${templateId}`);

        setPreview({
          pages: fetchDocumentPreview.data.response.arrayOfPages,
          title: fetchName.data.title,
        });
        setLoadingPreview(false);
      } catch (error) {
        console.error(error, "error getting document to preview");
        setLoadingPreview(false);
      }
    },
    []
  );

  const handleFavoriteAutomation = useCallback(
    async (automationId) => {
      try {
        await api.put("/user-templates/favorite", {
          user_template_id: automationId,
        });

        setAutomations((prev) => {
          const clone = cloneDeep(prev);

          const newArray = clone.map((element) => {
            if (element.id === automationId) {
              return { ...element, is_favorite: !element.is_favorite };
            }

            return element;
          });

          return newArray;
        });
      } catch (error) {
        console.error(error)
      }
    },
    [setAutomations]
  );

  return (
    <>
      <Downloadfiles
        toastId={idToastifyDownloadRef}
        fileExport={whichFileToBeDownload.current}
        downloading={downloading}
        setDownloading={setDownloading}
        documentName={idToastifyDownloadRef.current}
        pagesToBeRendered={documentPreviewData.current}
      />
      <ToastContainer />
      <Container>
        <Header>
          <HeaderSection
            title="Versões automatizadas"
            description='Este é o local onde ficam salvos os projetos que você criou utilizando 
            seus templates com campos de texto automatizáveis.
            Para criar uma automação em seu projeto basta inserir a marcação {{...}} sem espaços, separados 
            por "_" nos locais de texto que deseje automatizar, Exemplo: {{nome_do_contratante}}.
            Para usar a automação, em “Meus projetos” abra o template desejado, e na aba de automação do 
            editor clique em preencher automação. Ao salvar o que foi preenchido o resultado será salvo aqui.'
          />
          <SearchBar
            screen="automations"
            pageNumber={pageNumber}
            description="Digite aqui para encontrar a automação que procura."
          />
        </Header>

        <Main>
          <SelectCategory
            category={filterCategory}
            page={number_page}
            screen="automations"
          />
          <TableAutomation>
            <HeaderTable>
              <p>Título</p> <p>Criado em</p>
            </HeaderTable>

            <GridAutomation>
              {loading || loadingDebounce ? (
                <ContainerLogin>
                  <ContractAnimation />
                </ContainerLogin>
              ) : automations.length === 0 ? (
                <NoElements>Nenhum projeto encontrado.</NoElements>
              ) : (
                automations.map((item) => (
                  <AutomationItem
                    isFavorite={item.is_favorite}
                    key={item.id}
                    item={item}
                    handlePreviewDocumentAutomation={
                      handlePreviewDocumentAutomation
                    }
                    setTemplateId={setTemplateId}
                    handleOpenAutomation={handleEditAutomation}
                    handleDeleteAutomation={handleDeleteAutomation}
                    handleDateConvert={handleDateConvert}
                    handleFavoriteAutomation={handleFavoriteAutomation}
                    downloading={downloading}
                    setDownloading={setDownloading}
                    idToastifyDownloadRef={idToastifyDownloadRef}
                    documentPreviewData={documentPreviewData}
                    whichFileToBeDownload={whichFileToBeDownload}
                    setIsIntentBuyModal={setIsIntentBuyModal}
                  />
                ))
              )}
            </GridAutomation>
          </TableAutomation>
        </Main>

        <Pagination
          total={totalPages}
          screen="automations"
          pageNow={pageNumber}
          afterUrl={filter && filter}
        />

        <PreviewTemplateModal
          open={modalIsOpen}
          handleCloseModal={handleCloseModal}
          pagesToBeRendered={preview.pages}
          title={preview.title}
          loadingPreview={loadingPreview}
          chooseTemplate={handleEditAutomation}
          templateId={templateId}
          texButton="Abrir Projeto"
        />
      </Container>
      {isModalDeleteAutomation && <ModalDeleteAutomation />}
    </>
  );
};

export default MyAutomations;

