import React, {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo
} from "react";

import { useAuth } from "./auth";
import api from "../services/api";
import mixpanel from "../services/mixpanel";
import datadogRum from "../services/datadog";

interface IMetricContextDataProps {
  handleDowloadProjectAction: (data: IHandleDowloadProjectAction) => void;
  handleCreatedProjectAction: (data: IHandleCreatedProjectAction) => void;
  handleSharedProjectAction: (data: IHandleSharedProjectAction) => void;
  handleCreatedAutomationAction: (data: IHandleCreatedAutomationAction) => void;
  handleCreatedTeamAction: (data: IHandleCreatedTeamAction) => void;
  handleTemplateUXDocDraggedProjectAction: (
    data: IHandleTemplateUXDocDraggedProjectAction
  ) => void;
  handleClauseUXDocDraggedProjectAction: (
    data: IHandleClauseUXDocDraggedProjectAction
  ) => void;
  handleIconDraggedProjectAction: (
    data: IHandleIconDraggedProjectAction
  ) => void;
  handlePartipantAddedToTeamAction: (
    data: IHandlePartipantAddedToTeamAction
  ) => void;
  handleCreatedVersionAction: (data: IHandleCreatedVersionAction) => void;
  handlePaymentAcceptedAction: () => void;
  handleUserEnterEditor: () => void;
  handleDocumentImportedOneClickAction: (
    data: IHandleDocumentImportedOneClickAction
  ) => void;
  handleProjectCreatedOneClickAction: (
    data: IHandleProjectCreatedOneClickAction
  ) => void;
  handleSignatureCreated: (data: IHandleSignatureCreated) => void;
  handleIAMenu: (data: IHandleAPIMenuDataProps) => void;
  handleSelectOptionsFromForm: (data: IHandleSelectOptionsFromFormDataProps) => void;
  handleSelectSendOrCancel: (data: IHandleSelectSendOrCancelDataProps) => void;
  handleCloseModal: (data: IHandleCloseModalDataProps) => void;
  handleSelectHowYouMeUs: (data: string) => void;
  handleSelectWhereDoYouWork: (data: string) => void;
}

interface IMetricContextProps {
  children: ReactNode;
}

interface IHandleDowloadProjectAction {
  project_id: string;
  automated: boolean;
  type: "pdf" | "word" | "pptx";
  date: string;
  dateTime: string;
  isEditor?: boolean;
}

interface IHandleCreatedProjectAction {
  project_id: string;
  creation_origin:
  | "User Template"
  | "Team Template"
  | "UXDoc Template"
  | "Shared Template"
  | "New Template";
  template_id: string | false;
  template_bits_name: string | false;
  researched: boolean;
}

interface IHandleSharedProjectAction {
  project_id: string;
}

interface IHandleTemplateUXDocDraggedProjectAction {
  project_id: string;
  template_id: string;
  template_bits_name: string | false;
}

interface IHandleClauseUXDocDraggedProjectAction {
  clause_id: string;
  project_id: string;
  researched: boolean;
  clause_categories: string;
}

interface IHandleIconDraggedProjectAction {
  icon_id: string;
  icon_name: string;
  project_id: string;
  researched: boolean;
}

interface IHandleCreatedAutomationAction {
  automation_id: string;
  project_id: string;
  project_template: React.MutableRefObject<any>
}

interface IHandleCreatedTeamAction {
  team_id: string;
}

interface IHandlePartipantAddedToTeamAction {
  team_id: string;
  participant_id: string;
}

interface IHandleCreatedVersionAction {
  version_id: string;
  project_id: string;
}

interface IHandleDocumentImportedOneClickAction {
  protocol_id: string;
}

interface IHandleProjectCreatedOneClickAction {
  project_id: string;
}

interface IHandleSignatureCreated {
  signature_id: string;
  import_origin: "MyProject" | "Local";
  final_date: string;
  number_subscribers: number;
  title: string;
}

interface IHandleAPIMenuDataProps {
  title: string;
  user_id: string;
}

interface IHandleSelectOptionsFromFormDataProps {
  etapa?: "1" | "2" | "3" | "4" | "5";
  user_id: string;
  select_document?: boolean;
  type_document?: string;
  ux_or_normal?: boolean;
  color?: boolean;
  upload_logo?: boolean;
}

interface IHandleSelectSendOrCancelDataProps {
  title: string;
  user_id: string;
  send: boolean;
}

interface IHandleCloseModalDataProps {
  user_id: string;
  close: boolean;
}

const MetricContext = createContext({} as IMetricContextDataProps);

const MetricProvider: React.FC<IMetricContextProps> = ({ children }) => {
  const { data } = useAuth();
  const findEmailBits = data?.user?.email.match("@bitsacademy.com.br");

  const mixpanelEnv = useMemo(() => {
    return process.env.REACT_APP_MIXPANEL_TOKEN === 'prod'
  }, [])

  const datadogEnv = useMemo(() => {
    return process.env.REACT_APP_DATADOG_TOKEN === 'prod'
  }, [])

  useEffect(() => {
    if (data && !findEmailBits && mixpanelEnv && datadogEnv) {
      let userType: string;

      switch (data.payment_method?.user_type) {
        case "user_free":
          userType = "pagante_stripe";
          break;
        case "b2b":
          userType = "user_b2b";
          break;
        case "ld_pass":
          userType = "user_ldpass";
          break;
        case "cortesia":
          userType = "cortesia";
          break;
        case "pre_venda":
          userType = "pre_venda";
          break;
        default:
          userType = data.payment_method?.user_type;
      }


      mixpanel.identify(data.user?.id, {
        $user_type: userType,
        $is_active: data.payment_method?.is_active,
        $how_did_meet_us: data.account_questions?.where_do_you_work,
        $performance_place: data.account_questions?.how_did_you_meet_us,
        $created_at: data.user?.created_at
      });


      datadogRum.setUser({
        id: data.user?.id,
        user_type: userType,
        is_active: data.payment_method?.is_active,
        how_did_meet_us: data.account_questions?.where_do_you_work,
        performance_place: data.account_questions?.how_did_you_meet_us,
        created_at: data.user?.created_at
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, datadogEnv, mixpanelEnv]);

  const handleDowloadProjectAction = useCallback(
    (data: IHandleDowloadProjectAction) => {
      if (!findEmailBits && mixpanelEnv) {
        mixpanel.track("Downloaded Project", {
          $project_id: data.project_id,
          $automated: data.automated,
          $type: data.type
        });

        try {
          api.put("/user-templates/download-counter", {
            user_template_id: data.project_id
          });
        } catch (error) {
          console.error("Error when trying to send metrics!", error);
        }
      }
    },
    [findEmailBits, mixpanelEnv]
  );

  const handleCreatedProjectAction = useCallback(
    (data: IHandleCreatedProjectAction) => {
      if (!findEmailBits && mixpanelEnv) {
        mixpanel.track("Project Created", {
          $project_id: data.project_id,
          $creation_origin: data.creation_origin,
          $template_id: data.template_id,
          $template_bits_name: data.template_bits_name,
          $researched: data.researched
        });
      }
    },
    [findEmailBits, mixpanelEnv]
  );

  const handleSharedProjectAction = useCallback(
    (data: IHandleSharedProjectAction) => {
      if (!findEmailBits && mixpanelEnv) {
        mixpanel.track("Shared Project", {
          $project_id: data.project_id
        });
      }
    },
    [findEmailBits, mixpanelEnv]
  );

  const handleTemplateUXDocDraggedProjectAction = useCallback(
    (data: IHandleTemplateUXDocDraggedProjectAction) => {
      if (!findEmailBits && mixpanelEnv) {
        console.log('entrou aqui')
        mixpanel.track("UXDoc Template Dragged Into Project", {
          $project_id: data.project_id,
          $template_id: data.template_id,
          $template_bits_name: data.template_bits_name
        });

        try {
          api.put("/bits-templates/update-usage-counter", {
            bits_template_id: data.template_id,
            user_template_id: data.project_id
          });
        } catch (error) {
          console.error("Error when trying to send metrics!", error);
        }
      }
    },
    [findEmailBits, mixpanelEnv]
  );

  const handleClauseUXDocDraggedProjectAction = useCallback(
    (data: IHandleClauseUXDocDraggedProjectAction) => {
      if (!findEmailBits && mixpanelEnv) {
        mixpanel.track("UxDoc Clause Dragged Into Project", {
          $clause_id: data.clause_id,
          $clause_categories: data.clause_categories,
          $project_id: data.project_id,
          $researched: data.researched
        });

        try {
          api.put("/bits-clause/usage-counter", {
            bits_clause_id: data.clause_id
          });
        } catch (error) {
          console.error("Error when trying to send metrics!", error);
        }
      }
    },
    [findEmailBits, mixpanelEnv]
  );

  const handleIconDraggedProjectAction = useCallback(
    (data: IHandleIconDraggedProjectAction) => {
      if (!findEmailBits && mixpanelEnv) {
        mixpanel.track("Icon Dragged Into The Project", {
          $icon_id: data.icon_id,
          $icon_name: data.icon_name,
          $project_id: data.project_id,
          $researched: data.researched
        });

        try {
          api.put("/icon-repository/usage-counter", {
            icon_repository_id: data.icon_id
          });
        } catch (error) {
          console.error("Error when trying to send metrics!", error);
        }
      }
    },
    [findEmailBits, mixpanelEnv]
  );

  const handleCreatedAutomationAction = useCallback(
    (data: IHandleCreatedAutomationAction) => {
      if (!findEmailBits && mixpanelEnv) {
        mixpanel.track("Automation Created", {
          $automation_id: data.automation_id,
          $project_id: data.project_id
        });
      }
    },
    [findEmailBits, mixpanelEnv]
  );

  const handleCreatedTeamAction = useCallback(
    (data: IHandleCreatedTeamAction) => {
      if (!findEmailBits && mixpanelEnv) {
        mixpanel.track("Team Created", {
          $team_id: data.team_id
        });
      }
    },
    [findEmailBits, mixpanelEnv]
  );

  const handlePartipantAddedToTeamAction = useCallback(
    (data: IHandlePartipantAddedToTeamAction) => {
      if (!findEmailBits && mixpanelEnv) {
        mixpanel.track("Participant Added To Team", {
          $team_id: data.team_id,
          $participant_id: data.participant_id
        });
      }
    },
    [findEmailBits, mixpanelEnv]
  );

  const handleCreatedVersionAction = useCallback(
    (data: IHandleCreatedVersionAction) => {
      if (!findEmailBits && mixpanelEnv) {
        mixpanel.track("New Version Created", {
          $version_id: data.version_id,
          $project_id: data.project_id
        });
      }
    },
    [findEmailBits, mixpanelEnv]
  );

  const handlePaymentAcceptedAction = useCallback(() => {
    if (!findEmailBits && mixpanelEnv) {
      mixpanel.track("Payment Accepted");
    }
  }, [findEmailBits, mixpanelEnv]);

  const handleUserEnterEditor = useCallback(() => {
    if (!findEmailBits && mixpanelEnv) {
      mixpanel.track("User access editor page");
    }
  }, [findEmailBits, mixpanelEnv]);

  const handleDocumentImportedOneClickAction = useCallback(
    (data: IHandleDocumentImportedOneClickAction) => {
      if (!findEmailBits && mixpanelEnv) {
        mixpanel.track("Document Imported Into OneClick", {
          $protocol_id: data.protocol_id
        });
      }
    },
    [findEmailBits, mixpanelEnv]
  );

  const handleProjectCreatedOneClickAction = useCallback(
    (data: IHandleProjectCreatedOneClickAction) => {
      if (!findEmailBits && mixpanelEnv) {
        mixpanel.track("Project Created With OneClick", {
          $project_id: data.project_id
        });
      }
    },
    [findEmailBits, mixpanelEnv]
  );

  const handleSignatureCreated = useCallback(
    (data: IHandleSignatureCreated) => {
      if (!findEmailBits && mixpanelEnv) {
        mixpanel.track("Subscription created", {
          $signature_id: data.signature_id,
          $import_origin: data.import_origin,
          $final_date: data.final_date,
          $number_subscribers: data.number_subscribers,
          $title: data.title
        });
      }
    },
    [findEmailBits, mixpanelEnv]
  );

  const handleSelectHowYouMeUs = useCallback((data: string) => {
    if(!findEmailBits && mixpanelEnv) {
      mixpanel.track("How you meet us chosen", {
        $how_you_me_us: data,
      })
    }
  }, [findEmailBits, mixpanelEnv])

  const handleSelectWhereDoYouWork = useCallback((data: string) => {
    if(!findEmailBits && mixpanelEnv) {
      mixpanel.track("Where do you work", {
        $where_do_you_work: data,
      })
    }
  }, [findEmailBits, mixpanelEnv])

  const handleIAMenu = useCallback((data: IHandleAPIMenuDataProps) => {
    if (!findEmailBits && datadogEnv) {
      datadogRum.addAction(`${data.title}`, {
        $user_id: data.user_id
      });
    }
  }, [findEmailBits, datadogEnv]);

  const handleSelectOptionsFromForm = useCallback((data: IHandleSelectOptionsFromFormDataProps) => {
    if (!findEmailBits && datadogEnv) {
      datadogRum.addAction(`Etapa: ${data.etapa}`, {
        ...data,
        $user_id: data.user_id,
        $select_document: data.select_document,
        $type_document: data.type_document,
        $ux_or_normal: data.ux_or_normal,
        $color: data.color,
        $upload_logo: data.upload_logo,
      });
    }
  }, [findEmailBits, datadogEnv]);

  const handleSelectSendOrCancel = useCallback((data: IHandleSelectSendOrCancelDataProps) => {
    if (!findEmailBits && datadogEnv) {
      datadogRum.addAction(`${data.title}`, {
        $user_id: data.user_id,
        $send: data.send
      });
    }
  }, [findEmailBits, datadogEnv]);

  const handleCloseModal = useCallback((data: IHandleCloseModalDataProps) => {
    if (!findEmailBits && datadogEnv) {
      datadogRum.addAction('Fechar modal', {
        $user_id: data.user_id,
        $close_modal: data.close
      });
    }
  }, [findEmailBits, datadogEnv]);

  return (
    <MetricContext.Provider
      value={{
        handleDowloadProjectAction,
        handleCreatedProjectAction,
        handleSharedProjectAction,
        handleTemplateUXDocDraggedProjectAction,
        handleClauseUXDocDraggedProjectAction,
        handleIconDraggedProjectAction,
        handleCreatedAutomationAction,
        handleCreatedTeamAction,
        handlePartipantAddedToTeamAction,
        handleCreatedVersionAction,
        handlePaymentAcceptedAction,
        handleUserEnterEditor,
        handleDocumentImportedOneClickAction,
        handleProjectCreatedOneClickAction,
        handleSignatureCreated,
        handleIAMenu,
        handleSelectOptionsFromForm,
        handleSelectSendOrCancel,
        handleCloseModal,
        handleSelectHowYouMeUs,
        handleSelectWhereDoYouWork
      }}
    >
      {children}
    </MetricContext.Provider>
  );
};

function useMetric(): IMetricContextDataProps {
  const context = useContext(MetricContext);

  if (!context) {
    throw new Error("useTeams must be used with an TeamsHookProvider");
  }

  return context;
}

export { MetricProvider, useMetric };
