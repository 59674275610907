import React, { useState } from "react";
import { Arrow, Icon, IconSelected, Input, InputWrapper, Label, LabelOptions, Option, Options, SelectContainer } from "./styles";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

type IOptions = {
  label: string;
  value: string;
  icon: string;
};

interface ISelect {
  options: IOptions[];
  value: string;
  placeholder?: string
  onChange: (value: string) => void;
}

const SelectWithIconsComponent = ({ options, value, placeholder = 'Selecione uma opção', onChange }: ISelect) => {
  const [showOptions, setShowOptions] = useState(false);

  const selectedOption = options.find(option => option.value === value); 

  const handleOptionClick = (selectedValue: string) => {
    onChange(selectedValue);
    setShowOptions(false);
  };

  return (
    <SelectContainer>
      <Label>Formato do arquivo</Label>
      <InputWrapper onClick={() => setShowOptions(!showOptions)}>
        {selectedOption && <IconSelected src={selectedOption.icon} alt={selectedOption.label} />}
        <Input
          value={options.find(option => option.value === value)?.label || ""}
          placeholder={placeholder}
          readOnly
        />
        <Arrow><ArrowDropDownIcon/></Arrow>
      </InputWrapper>
      {showOptions && (
        <Options>
          {options.map((option, index) => (
            <Option key={index} onClick={() => handleOptionClick(option.value)}>
              <Icon src={option.icon} alt={option.label} />
              <LabelOptions>{option.label}</LabelOptions>
            </Option>
          ))}
        </Options>
      )}
    </SelectContainer>
  );
};

export default SelectWithIconsComponent;
