import { AxiosResponse } from "axios";
import { cloneDeep } from "lodash";
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { toast } from "react-toastify";
import {
  ArrayOfTeamListProps,
  ISelectModalTeam,
  ListTeamsOfOwnerProps,
  TeamsListProps,
} from "../dtos/teamsUtils";

import api from "../services/api";
import { useMetric } from "./metric";
import { useQuery } from "react-query";
import { useAuth } from "./auth";
import { useParams } from "react-router-dom";

interface TeamsContextProps {
  children: ReactNode;
}

interface UsersTeamGroupProps {
  id: string;
  first_name: string;
  last_name: string;
  avatar: string;
  avatar_url: string;
  email: string;
  is_owner: true;
}

interface TeamsContextDataProps {
  isSettings: boolean;
  setIsSettings: React.Dispatch<React.SetStateAction<boolean>>;
  selectModal: ISelectModalTeam;
  setSelectModal: React.Dispatch<React.SetStateAction<ISelectModalTeam>>;
  insertNameTeam: string;
  setInsertNameTeam: React.Dispatch<React.SetStateAction<string>>;
  loadingCreateTeam: boolean;
  setLoadingCreateTeam: React.Dispatch<React.SetStateAction<boolean>>;
  handleCreateTeam: () => Promise<void>;
  listTeams: ArrayOfTeamListProps[];
  setListTeams: React.Dispatch<React.SetStateAction<ArrayOfTeamListProps[]>>;
  loadingListTeam: boolean;
  setLoadingListTeam: React.Dispatch<React.SetStateAction<boolean>>;
  createdTeamId: string;
  setCreatedTeamId: React.Dispatch<React.SetStateAction<string>>;
  nameOfTeam: string;
  setNameOfTeam: React.Dispatch<React.SetStateAction<string>>;
  handleUpdateListTeam: () => Promise<void>;
  isOwnerStaff: boolean;
  setIsOwnerStaff: React.Dispatch<React.SetStateAction<boolean>>;
  handleCopyTemplateTeam: (
    userTemplateId: string,
    teamId: string
  ) => Promise<void>;
  templatesOfTeams: TemplatesOfTeamProps[];
  setTemplatesOfTeams: React.Dispatch<
    React.SetStateAction<TemplatesOfTeamProps[]>
  >;
  handleDeleteTemplateTeam: (teamTemplateId: any) => Promise<void>;
  loadingDeleteTemplate: boolean;
  setLoadingDeleteTemplate: React.Dispatch<React.SetStateAction<boolean>>;
  usersTeamGroup: UsersTeamGroupProps[];
  setUsersTeamGroup: React.Dispatch<
    React.SetStateAction<UsersTeamGroupProps[]>
  >;
  handleRemoveMember: (id_member: string, team_id: any) => Promise<void>;
  userForDelete: {
    name: string;
    id: string;
  };
  setUserForDelete: React.Dispatch<
    React.SetStateAction<{
      name: string;
      id: string;
    }>
  >;
  clausesListTeam: ClauseListProps[];
  setClausesListTeam: React.Dispatch<React.SetStateAction<ClauseListProps[]>>;
  loadingClause: boolean;
  setLoadingClause: React.Dispatch<React.SetStateAction<boolean>>;
  clauseId: string;
  setClauseId: React.Dispatch<React.SetStateAction<string>>;
  forceUpdate: boolean;
  setForceUpdate: React.Dispatch<React.SetStateAction<boolean>>;
  handleTeamLeave: (id: string) => void;
  handleDeleteClauseTeam: (id: any) => Promise<void>;
  idLeaveTeam: string;
  setIdLeaveTeam: React.Dispatch<React.SetStateAction<string>>;
  listTeamsOfOwner: ListTeamsOfOwnerProps[];
  setListTeamsOfOwner: React.Dispatch<
    React.SetStateAction<ListTeamsOfOwnerProps[]>
  >;
}

interface ResponseCreateTeamProps {
  id: string;
  name: string;
  created_at: string;
  updated_at: string;
}

interface TeamsPropsResponse {
  team_users_id: string;
  team_id: string;
  name: string;
  created_at: string;
}

export interface TemplatesOfTeamProps {
  id: string;
  team_id: string;
  title: string;
  thumbnail: string;
  thumbnail_url: string;
  created_at: string;
  updated_at: string;
}

export interface ClauseListProps {
  id: string;
  team_id: string;
  title: string;
  clause: string;
  key_words: string;
  created_at: string;
  updated_at: string;
  is_favorite?: boolean;
}

//creating context api of react js
const TeamsContext = createContext({} as TeamsContextDataProps);

const TeamsHookProvider: React.FC<TeamsContextProps> = ({ children }) => {
  const { handleCreatedTeamAction } = useMetric();
  const { data } = useAuth();

  const [isSettings, setIsSettings] = useState(false);
  const [isOwnerStaff, setIsOwnerStaff] = useState(false);
  const [clausesListTeam, setClausesListTeam] = useState<ClauseListProps[]>([]);
  const [loadingClause, setLoadingClause] = useState(true);
  const [clauseId, setClauseId] = useState("");
  const [loadingDeleteTemplate, setLoadingDeleteTemplate] = useState(false);
  const [idLeaveTeam, setIdLeaveTeam] = useState("");
  const [insertNameTeam, setInsertNameTeam] = useState("");
  const [createdTeamId, setCreatedTeamId] = useState("");
  const [loadingListTeam, setLoadingListTeam] = useState(true);
  const [nameOfTeam, setNameOfTeam] = useState("");
  const [listTeams, setListTeams] = useState<ArrayOfTeamListProps[]>([]);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [loadingCreateTeam, setLoadingCreateTeam] = useState(false);
  const [templatesOfTeams, setTemplatesOfTeams] = useState<
    TemplatesOfTeamProps[]
  >([]);
  const [userForDelete, setUserForDelete] = useState({
    name: "",
    id: "",
  });
  const [selectModal, setSelectModal] = useState<ISelectModalTeam>({
    modal: "",
    isOpen: false,
  });
  const [usersTeamGroup, setUsersTeamGroup] = useState<UsersTeamGroupProps[]>(
    []
  );

  //List of teams where the user is the owner
  const [listTeamsOfOwner, setListTeamsOfOwner] =
    useState<ListTeamsOfOwnerProps[]>();

  const handleUpdateListTeam = useCallback(async () => {
    try {
      const response: AxiosResponse<TeamsListProps> = await api.get(
        "team-users/list-user-teams"
      );

      setListTeams(response.data.dataArray);
    } catch (err) {
      console.error(err, "ERROR when get teams list");
    }
  }, []);

  const handleCopyTemplateTeam = useCallback(
    async (userTemplateId: string, teamId: string) => {
      try {
        const responseCreateTemplateForTeam = await api.post(
          "team-templates/clone-my-template-to-my-team",
          {
            team_id: teamId,
            user_template_id: userTemplateId,
          }
        );
        toast.success("Template clonado para equipe com sucesso!");
      } catch (err) {
        // console.error(err, "ERROR when was trying create new project to team");
        toast.error("Ops! Algo deu errado.");
      }
    },
    []
  );

  const handleDeleteTemplateTeam = useCallback(
    async (teamTemplateId) => {
      try {
        setLoadingDeleteTemplate(true);
        const reponseDeleteTemplateTeam = await api.delete(
          `team-templates/${teamTemplateId}`
        );
        setLoadingDeleteTemplate(false);
        setForceUpdate(!forceUpdate);
        toast.success("Template excluido com sucesso!");
      } catch (err) {
        console.error(err, "ERROR when delete specific template of the team");
        setLoadingDeleteTemplate(false);
      }
    },
    [forceUpdate]
  );

  const handleCreateTeam = useCallback(async () => {
    try {
      setLoadingCreateTeam(true);
      const responseCreateTeam = await api.post("team", {
        name: insertNameTeam,
      });
      setLoadingCreateTeam(false);
      const { id } = responseCreateTeam.data as ResponseCreateTeamProps;

      //Created team action register function
      handleCreatedTeamAction({ team_id: id });

      setCreatedTeamId(id);
      setSelectModal({ modal: "Add Participant", isOpen: true });
      setForceUpdate(!forceUpdate);
    } catch (err) {
      setLoadingCreateTeam(false);
      // console.error(err, "error when create new team");
    }
  }, [insertNameTeam, handleCreatedTeamAction, forceUpdate]);

  const handleRemoveMember = useCallback(
    async (id_member: string, team_id) => {
      try {
        const responseApi = await api.delete(
          `/team-users/${team_id}/${id_member}`
        );

        if (responseApi.data.response !== "Error deleting user") {
          setUsersTeamGroup((prevState) => {
            let cloneState = [...prevState];
            const newArr = cloneState.filter((user) => user.id !== id_member);
            cloneState = newArr;
            return cloneState;
          });

          setSelectModal({ modal: "Add Participant", isOpen: true });
        }
        setSelectModal({ modal: "", isOpen: false });
        setForceUpdate(!forceUpdate);
      } catch (err) {
        // console.error(err.response.data, "error when remove member of team");
      }
    },
    [forceUpdate]
  );

  const handleTeamLeave = useCallback(async (id: string) => {
    try {
      await api.delete(`/team-users/leave-team/${id}`);
      setForceUpdate(!forceUpdate);
    } catch (error) {
      
    }
    setIdLeaveTeam("");
  }, []);

  const handleDeleteClauseTeam = useCallback(
    async (id) => {
      try {
        const deleteClauseTeam = await api.delete(`team-clauses/${id}`);
        toast.success("Texto excluido com sucesso!");
        setClausesListTeam((oldState) => {
          let cloneState = cloneDeep(oldState);
          const newState = cloneState.filter((clause) => clause.id !== id);
          return newState;
        });
        setForceUpdate(!forceUpdate);
      } catch (err) {
        console.error(err, "ERROR when trying delete clause specific team");
        toast.error("Erro ao excluir texto.");
      }
    },
    [forceUpdate]
  );

  useEffect(() => {
    (async () => {
      if (createdTeamId) {
        try {
          const responseIsOwner = await api.get(
            `team-users/is-owner/${createdTeamId}`
          );
          setIsOwnerStaff(responseIsOwner.data.is_owner);
        } catch (err) {
          // console.error(err, "error if thats user is owner of this team");
        }
      }
    })();
  }, [createdTeamId]);

  return (
    <TeamsContext.Provider
      value={{
        isSettings,
        setIsSettings,
        selectModal,
        setSelectModal,
        insertNameTeam,
        setInsertNameTeam,
        loadingCreateTeam,
        setLoadingCreateTeam,
        handleCreateTeam,
        listTeams,
        setListTeams,
        loadingListTeam,
        setLoadingListTeam,
        createdTeamId,
        setCreatedTeamId,
        nameOfTeam,
        setNameOfTeam,
        handleUpdateListTeam,
        isOwnerStaff,
        setIsOwnerStaff,
        handleCopyTemplateTeam,
        templatesOfTeams,
        setTemplatesOfTeams,
        handleDeleteTemplateTeam,
        loadingDeleteTemplate,
        setLoadingDeleteTemplate,
        usersTeamGroup,
        setUsersTeamGroup,
        handleRemoveMember,
        userForDelete,
        setUserForDelete,
        clausesListTeam,
        setClausesListTeam,
        loadingClause,
        setLoadingClause,
        clauseId,
        setClauseId,
        forceUpdate,
        setForceUpdate,
        handleTeamLeave,
        handleDeleteClauseTeam,
        idLeaveTeam,
        setIdLeaveTeam,
        listTeamsOfOwner,
        setListTeamsOfOwner,
      }}
    >
      {children}
    </TeamsContext.Provider>
  );
};

// creating hook

function useTeams(): TeamsContextDataProps {
  const context = useContext(TeamsContext);

  if (!context) {
    throw new Error("useTeams must be used with an TeamsHookProvider");
  }

  return context;
}

export { TeamsHookProvider, useTeams };
