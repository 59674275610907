import api from "../../../services/api";

interface UsersSharedGroupProps {
  user_template_id?: string;
  users?: [
    {
      id: string;
      name: string;
      email: string;
      avatar_url: string;
      permissions: string;
    }
  ];
}

interface ObjectPermissionProps {
  id: string;
  name: string;
  email: string;
  avatar_url: string;
  permissions: string;
  is_anonymous: boolean;
  current_user: boolean;
}

interface UsersSharedProps {
  id: string;
  name: string;
  email: string;
  avatar_url: string;
  permissions: "Owner" | "Editor" | string;
  is_anonymous: boolean;
  current_user: boolean;
}

interface UserAccessControlProps {
  access_control: boolean;
  user_list: UsersSharedProps[];
}

interface DefineActiveUserInDocumentProps {
  setLoadingDefault: React.Dispatch<React.SetStateAction<boolean>>;
  templateid: string;
  setDocumentUsersAccess: React.Dispatch<
    React.SetStateAction<UserAccessControlProps>
  >;
  setVerifyApi: React.Dispatch<React.SetStateAction<boolean>>;
}

interface ResponseAccessControlProps {
  access_control: boolean;
  user_list: ObjectPermissionProps[];
}

interface GetProfileProps {
  setActiveUser: React.Dispatch<React.SetStateAction<string>>;
}

interface GetUsersByDocumentProps {
  setUsersSharedGroup: React.Dispatch<
    React.SetStateAction<UsersSharedGroupProps>
  >;
  templateid: string;
}

interface AutoSavingProps {
  handleSaveTemplate: (isAutoSave?: Boolean) => Promise<void>;
  loadingTemplate: boolean;
  permissionDocument: boolean;
}

const getProfile = async ({ setActiveUser }: GetProfileProps) => {
  try {
    const responseProfile = await api.get("profile");
    setActiveUser(responseProfile.data.user.id as string);
  } catch (err) {
    console.error(err, "error when get profile");
  }
};

const getUsersByDocumentId = async ({
  setUsersSharedGroup,
  templateid
}: GetUsersByDocumentProps) => {
  // const delay = ms => new Promise(resolve => setTimeout(resolve, ms));
  // fetch group of users what can access this template
  try {
    const belongsSharedGroup = await api.get(
      `user-share-template/all-shared-template-users/${templateid}`
    );
    setUsersSharedGroup(belongsSharedGroup.data);
  } catch (err) {
    console.error(err, "error when try get belongsSharedGroup");
  }
};

const defineActiveUserInDocument = async ({
  setLoadingDefault,
  templateid,
  setDocumentUsersAccess,
  setVerifyApi
}: DefineActiveUserInDocumentProps) => {
  try {
    setLoadingDefault(true);
    const responseAccessControlUser = await api.post(
      "user-templates-access-control",
      {
        user_template_id: templateid
      }
    );

    setDocumentUsersAccess(
      responseAccessControlUser.data as ResponseAccessControlProps
    );
    setVerifyApi(true);
    setLoadingDefault(false);
  } catch (err) {
    setLoadingDefault(false);
    console.error(err, "ERROR when post user template access control1");
  }
};

const autoSaving = async ({
  handleSaveTemplate,
  loadingTemplate,
  permissionDocument
}: AutoSavingProps) => {
  //saving template when user open document
  //for generate there thumbnail to the project
  //just add one little delay to action
  //because we have ensure that first page is already
  if (!loadingTemplate && permissionDocument) {
    setTimeout(async () => {
      try {
        await handleSaveTemplate(true);
      } catch (err) {
        console.error(err, "error when are auto saving... ");
      }
    }, 1000);
  }
};

export {
  getProfile,
  getUsersByDocumentId,
  defineActiveUserInDocument,
  autoSaving
};

